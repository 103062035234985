import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[setSupportLanguageClass]',
})
export class SetSupportLanguageClassDirective implements OnInit {
  @Input('name') name: any;
  constructor(private renderer: Renderer2, private el: ElementRef) {}
  ngOnInit(): void {
    const dangerClass = 'btn-light-danger';
    const successClass = 'btn-light-success';
    if (this.name.length == '' || this.name == undefined) {
      this.renderer.addClass(this.el.nativeElement, dangerClass);
    } else {
      this.renderer.addClass(this.el.nativeElement, successClass);
    }
  }
}
