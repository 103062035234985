import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { IBreadCrumb } from './breadcrumb.model';

@Component({
  selector: 'app-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss'],
})
export class ContentContainerComponent implements AfterViewInit {
  @Input() pageTitle: string;
  @Input() cardContentEnable: boolean;
  @Input() dataIsFetched: boolean;
  @Input() showSpinner: boolean = false;
  @Input() /* Creating a breadcrumb for the current page. */
  /* Showing the breadcrumb for the current page. */
  showBreadCrumb: boolean = false;
  @Input() breadCrumbItems: IBreadCrumb[] = [];
  @Input() isCustomCard: boolean = false;
  @Input() subHeaderClass = '';
  /**
   * used to indicated we want to have a header without page title and actions button (pages such as product list has both title and actions button)
   * sample page that use this field is whatsapp page with this address /app-store/market/whats-app
   */
  @Input() headerContentIsDynamic = false;
  @ContentChildren('cardContainer') cardContainer: ElementRef;
  public headerIsEmpty = true;

  @ViewChild('headerContainer')
  public headerContainer: any;

  constructor() {}

  ngAfterViewInit(): void {
    this.headerIsEmpty = !this.headerContainer?.innerHTML?.trim();
  }
}
