<div class="w-100">
  <div
    class="alert alert-custom alert-primary fade show mb-5 py-2"
    role="alert"
  >
    <div class="alert-icon">
      <fa-icon [icon]="faInfo"></fa-icon>
    </div>
    <div
      class="alert-text"
      [innerHTML]="'ORDERS.SHOPIROLLER_COLLECT_ORDER_MESSAGE' | translate"
    ></div>
  </div>
</div>
