import { AnimatedLogoComponent } from './animated-logo.component';
import { BlockUIModule } from 'primeng/blockui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    AnimatedLogoComponent
  ],
  imports: [
    CommonModule, BlockUIModule
  ],
  exports:[AnimatedLogoComponent]
})
export class AnimatedLogoModule { }
