import { APP_INITIALIZER } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetListOfApps } from '../data-access/store/stores.actions';
import { USER_PROFILE_ROUTES_PATH } from '@pratik-islem/user-profile';
import { STORES_ROUTES_PATH } from '../routes/stores.routes';

export const TOP_5_PROJECT_LIST_APP_INITIALIZER_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [Store],
    useFactory: (store: Store) => {
      return () => {
        const ILLEGAL_ROUTES = [
          '/',
          STORES_ROUTES_PATH.ROOT,
          STORES_ROUTES_PATH.LIST,
          USER_PROFILE_ROUTES_PATH.ROOT,
          USER_PROFILE_ROUTES_PATH.ACCOUNT,
          USER_PROFILE_ROUTES_PATH.MANAGE_APPSUMO,
          USER_PROFILE_ROUTES_PATH.CHANGE_PASSWORD
        ]; // these routes are not dependent on project id
        if (ILLEGAL_ROUTES.includes(window.location.pathname)) return;
        return store.dispatch(
          GetListOfApps({
            payload: {
              appName: '',
              pageIndex: 1,
              perPage: 5
            }
          })
        );
      };
    }
  }
];
