import { APP_INITIALIZER } from '@angular/core';
import { AppConfigurationService } from './app-configuration.service';

export const APP_CONFIGURATION_APP_INITIALIZER_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [AppConfigurationService],
    useFactory: (appConfigurationService: AppConfigurationService) => {
      return (): void => {
        return appConfigurationService.load();
      };
    }
  }
];
