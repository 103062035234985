import { Component, Input, OnInit } from '@angular/core';
import { IStatusHistory, IStatusHistoryItem } from '../../data';

import { DateUtil } from '@pratik-islem/shared/utils';
import { faGenderless } from '@fortawesome/free-solid-svg-icons';
import { AlertModule } from '@pratik-islem/shared/ui';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { OrderStatusWithDescriptionTranslatorPipe } from '../../utils/pipes/order-status-with-description-translator.pipe';

@Component({
  selector: 'order-timeline',
  templateUrl: './order-timeline.component.html',
  styleUrls: ['./order-timeline.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AlertModule,
    DropdownModule,
    InputNumberModule,
    CalendarModule,
    FontAwesomeModule,
    OrderStatusWithDescriptionTranslatorPipe
  ]
})
export class OrderTimelineComponent implements OnInit {
  @Input()
  public statusHistory: any;
  public faGenderless = faGenderless;
  public orderStatusHistory: IStatusHistory[] = [];

  constructor() {}

  ngOnInit(): void {
    this.transformData();
  }

  transformData() {
    if (this.statusHistory) {
      let dates = Object.keys(this.statusHistory);
      const sortedDates = dates.sort(function (a, b) {
        var da = new Date(a).getTime();
        var db = new Date(b).getTime();
        return da < db ? -1 : da > db ? 1 : 0;
      });
      const allDays = sortedDates.map((x) => DateUtil.toISOString(x));
      let uniqueDays = allDays.filter((n, i) => allDays.indexOf(n) === i);
      uniqueDays.forEach((day) => {
        const records: IStatusHistoryItem[] = [];
        const allRecordsForOneDay = dates.filter(
          (x) => DateUtil.toISOString(x) == day
        );
        allRecordsForOneDay.forEach((record) => {
          const date = new Date(record);
          const statusHistoryItem: IStatusHistoryItem = {
            time: `${date.getHours()} : ${date.getMinutes()}`,
            status: this.statusHistory[record]
          };
          records.push(statusHistoryItem);
        });
        const statusHistory: IStatusHistory = {
          date: DateUtil.formatISODate(day),
          records
        };
        this.orderStatusHistory.push(statusHistory);
      });
    }
  }
}
