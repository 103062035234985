<app-alert
  [alertType]="'warning'"
  [message]="'ORDERS.CARGOR_OFFER_WARNING' | translate"
>
</app-alert>

<div class="flex flex-column">
  <div *ngIf="offerData?.list.length > 0" class="offers__container">
    <div
      class="flex align-items-center border rounded p-5 mb-2"
      *ngFor="let offer of offerData?.list; let i = index"
    >
      <p-radioButton
        name="selectedOffer"
        [value]="i"
        [(ngModel)]="selectedOfferType"
        inputId="'dynamic'"
        (onClick)="changeSelectedOffer(offer.id)"
      ></p-radioButton>
      <label class="ml-2 mb-0">
        {{ offer.providerCode }}
        <span class="badge badge-warning ml-2" *ngIf="offer.id == fastestId">
          {{ 'ORDERS.FASTEST' | translate }}</span
        >
        <span
          class="badge badge-success ml-2"
          *ngIf="offer.id == cheapeastId"
          >{{ 'ORDERS.CHEAPEST' | translate }}</span
        >
      </label>
      <label class="ml-2 mb-0 float-right">
        {{
          offerData?.fastest.amount
            | currency : 'TRY' : 'symbol-narrow' : '.2-2'
        }}</label
      >
    </div>
  </div>

  <div class="d-flex justify-content-end mt-5">
    <button
      type="button"
      class="btn btn-outline-secondary font-weight-bold"
      (click)="closeForm()"
    >
      {{ 'SHARED.CANCEL' | translate }}
    </button>
    <button
      (click)="acceptOffer()"
      [disabled]="selectedOfferId == ''"
      class="btn btn-primary font-weight-bold ml-3"
    >
      {{ 'SHARED.ACCEPT' | translate }}
    </button>
  </div>

  <app-animated-logo *ngIf="isLoading == true"></app-animated-logo>
</div>
