import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[randomSymbolColor]'
})
export class RandomSymbolColorDirective {

  constructor(
    private elRef : ElementRef,
    private renderer:Renderer2,
    ) { }

  ngOnInit(): void {
    const colors = ['symbol-light-primary','symbol-light-dark','symbol-light-danger','symbol-light-warning','symbol-light-success','symbol-light-info'];
    let randomNumber = Math.floor(Math.random() *
    colors.length);
    this.renderer.addClass(this.elRef.nativeElement,
    `${colors[randomNumber]}`);
 }

}
