import { GetUserInformationModel, UpdateUserProfileModel, UserProfileFullInformationModel, UserProfileModel } from '../../models/user-profile.model';
import { createAction, props } from '@ngrx/store';

/******************* Loading Current User Profile Information  ***********************/
export const loadUserProfileInformation = createAction('[UserProfile] Load User Profile Information');

export const loadUserProfileInformationSuccess = createAction('[UserProfile] Load User Profile Information Success', props<{ response: UserProfileModel }>());

export const loadUserProfileInformationFailure = createAction('[UserProfile] Load User Profile Information Failure', props<{ error: any }>());


/******************* Loading User Profile Full Information With Email ***********************/
export const loadUserProfileInformationWithEmail = createAction('[UserProfile] Load User Profile Full Information With Email', props<{ payload: GetUserInformationModel }>());

export const loadUserProfileInformationWithEmailSuccess = createAction('[UserProfile] Load User Profile Full Information Success With Email', props<{ response: UserProfileFullInformationModel }>());
  
export const loadUserProfileInformationWithEmailFailure = createAction('[UserProfile] Load User Profile Full Information Failure With Email', props<{ error: any }>());


/******************* Update User Profile Information  ***********************/
export const updateUserProfileInformation = createAction('[UserProfile] update User Profile Information');

export const updateUserProfileInformationSuccess = createAction('[UserProfile] update User Profile Information Success', props<{ response: UpdateUserProfileModel }>());

export const updateUserProfileInformationFailure = createAction('[UserProfile] update User Profile Information Failure', props<{ error: any }>());

