import { END_POINTS_URL } from '@pratik-islem/shared/data';
import { HttpClient } from '@angular/common/http';
import { IChangePassword } from '../../models/change-passowrd.model';
import { ICheckPassword } from '../../models/check-password.model';
import { ISendVerificationCode } from '../../models/send-verification-code.model';
import { IVerifyPhoneNumber } from '../../models/verify-phone-number.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  GetUserInformationModel,
  UpdateUserProfileModel,
  UserProfileFullInformationModel,
  UserProfileModel
} from '../../models/user-profile.model';
import { map } from 'rxjs/internal/operators/map';
import { IUpdateCostModel } from '../../models/add-cost.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor(private http: HttpClient) {}

  getCurrentUserProfile(): Observable<UserProfileModel> {
    const URL = `${END_POINTS_URL.V2_1.PROFILE_URL}/contact`;
    return this.http
      .get(URL)
      .pipe(map((response: any) => response.data[0] as UserProfileModel));
  }

  getUserInformation(
    payload: GetUserInformationModel
  ): Observable<UserProfileFullInformationModel> {
    const URL = `${END_POINTS_URL.V2_1.USER_URL}/getInformation/${payload.email}`;
    return this.http
      .get(URL)
      .pipe(
        map((response: any) => response.data as UserProfileFullInformationModel)
      );
  }

  changePassword(data: IChangePassword): Observable<any> {
    const URL = `${END_POINTS_URL.V2_1.AUTH_URL}/changePassword`;
    return this.http.post(URL, data);
  }

  sendVerificationCode(data: ISendVerificationCode): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.PROFILE_URL}/contact`;
    return this.http.post(url, data);
  }

  verifyPhoneNumber(data: IVerifyPhoneNumber): Observable<any> {
    const URL = `${END_POINTS_URL.V2_1.PROFILE_URL}/contact/${data.code}/${data.phoneNumber}`;
    return this.http.put(URL, {});
  }

  closeAccount(reason: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2_1.AUTH_URL}?deletionReason=${reason}`;
    return this.http.delete(URL);
  }

  checkUserPasswordValidity(data: ICheckPassword): Observable<any> {
    const URL = `${END_POINTS_URL.V2_1.AUTH_URL}/checkPassword`;
    return this.http.post(URL, data);
  }

  updateUserProfile(data: UpdateUserProfileModel): Observable<any> {
    const URL = `${END_POINTS_URL.V2_1.PROFILE_URL}`;
    return this.http.put(URL, data);
  }

  updateSegmentation(
    bankId: string,
    costId: string,
    dto: IUpdateCostModel
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/${bankId}/costs/${costId}`;
    return this.http.put(URL, dto);
  }

  getCostsList(): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/costs`;
    return this.http.get(URL).pipe(map((response: any) => response.data));
  }


  deleteCost(bankId: string, costId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/${bankId}/costs/${costId}`;
    return this.http.delete(URL);
  }

  
  getSegmentationsList(): Observable<any> {
    const URL = `${END_POINTS_URL.V2.SEGMENT_URL}`;
    return this.http.get(URL).pipe(map((response: any) => response.data));
  }

  addSegmentation(bankId: string, dto: any): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/${bankId}/costs`;
    return this.http.post(URL, dto);
  }


  deleteSegmentation(bankId: string, costId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/${bankId}/costs/${costId}`;
    return this.http.delete(URL);
  }

  getEnddayList(startDate : string,endDate : string,appName : string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.STATS_URL}/payments?appName=${appName}&date.min=${startDate}&date.max=${endDate}`;
    console.log(URL)
    return this.http.get(URL).pipe(map((response: any) => response.data));
  }
}
