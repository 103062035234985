export const orderPaymentStatusList = [
  { type: 'None', translationLabel: 'NONE', color: 'label-light-info' },
  {
    type: 'Pending',
    translationLabel: 'PENDING',
    color: 'label-light-primary'
  },
  {
    type: 'Transferred',
    translationLabel: 'TRANSFERRED',
    color: 'label-light-success'
  },
  { type: 'Failed', translationLabel: 'FAILED', color: 'label-light-dark' },
  {
    type: 'Canceled',
    translationLabel: 'CANCELED',
    color: 'label-light-warning'
  }
];
