export enum SaleChannelTypes {
  Web = 'Web',
  Mobile = 'Mobile',
  Shopilink = 'Shopilink',
  GoogleMerchant = 'GoogleMerchant',
  Magenty = 'Magenty',
  Collection = 'Collection'
}

export type SalesChannelTypes =
  | 'Web'
  | 'Mobile'
  | 'Shopilink'
  | 'GoogleMerchant'
  | 'Magenty'
  | 'Collection';
