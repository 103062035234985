import { Routes } from '@angular/router';
import { AdminRoutesGuard } from '../utils/guards/admin-routes.guard';

export const USER_PROFILE_ROUTES: Routes = [
  {
    path: '',
    loadComponent: async () =>
      (await import('../features/shell/shell.component')).ShellComponent,
    children: [
      {
        path: 'account',
        loadComponent: async () =>
          (
            await import(
              '../features/account-management/account-management.component'
            )
          ).AccountManagementComponent
      },
      {
        path: 'change-password',
        loadComponent: async () =>
          (
            await import(
              '../features/change-password/change-password.component'
            )
          ).ChangePasswordComponent
      },
      {
        path: 'installment',
        loadComponent: async () =>
          (
            await import(
              '../features/installment/installment.component'
            )
          ).InstallmentComponent
      },
      {
        canActivate: [AdminRoutesGuard],
        path: 'admin',
        children: [
          {
            path: 'banks',
            children: [
              {
                path: 'list',
                loadComponent: async () =>
                  (await import('../features/admin/banks/list/banks.component'))
                    .BanksComponent
              },
              {
                path: '**',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'segmentation',
            children: [
              {
                path: 'list',
                loadComponent: async () =>
                  (await import('../features/admin/segmentation/list/segmentation.component'))
                    .SegmentationComponent
              },
              {
                path: '**',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'endday',
            children: [
              {
                path: 'list',
                loadComponent: async () =>
                  (await import('../features/admin/endday/list/endday.component'))
                    .EnddayComponent
              },
              {
                path: '**',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'costs',
            children: [
              {
                path: 'list',
                loadComponent: async () =>
                  (await import('../features/admin/costs/list/costs.component'))
                    .CostsComponent
              },
              {
                path: '**',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'orders',
            children: [
              {
                path: 'order-details/:appId/:orderId',
                loadComponent: async () =>
                  (
                    await import(
                      '../features/admin/orders/order-detail/admin-order-detail.component'
                    )
                  ).AdminOrderDetailComponent
              },
              {
                path: 'list',
                loadComponent: async () =>
                  (
                    await import(
                      '../features/admin/orders/admin-orders.component'
                    )
                  ).AdminOrdersComponent
              },
              {
                path: '**',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'levels',
            children: [
              {
                path: 'list',
                loadComponent: async () =>
                  (
                    await import(
                      '../features/admin/levels/list/admin-levels.component'
                    )
                  ).AdminLevelsComponent
              },
              {
                path: '**',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'apps',
            children: [
              {
                path: 'list',
                loadComponent: async () =>
                  (
                    await import(
                      '../features/admin/apps/list/admin-apps.component'
                    )
                  ).AdminAppsComponent
              },
              {
                path: '**',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: '**',
            redirectTo: 'orders',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'account'
      }
    ]
  }
];

export const USER_PROFILE_ROUTES_PATH = {
  ROOT: '/user-profile',
  ACCOUNT: '/user-profile/account',
  MANAGE_APPSUMO: '/user-profile/manage-appsumo',
  CHANGE_PASSWORD: '/user-profile/change-password'
};
