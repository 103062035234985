import { WebHookFeatureState, webHookFeatureKey } from './web-hook.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const webHookState =
  createFeatureSelector<WebHookFeatureState>(webHookFeatureKey);

export const selectAppId = createSelector(webHookState, ({ appId }) => appId);

export const selectListOfWebHooks = createSelector(
  webHookState,
  ({ list }) => list
);

export const selectListOfWebHooksWithAppId = createSelector(
  webHookState,
  ({ appId, list }) => ({ list, appId })
);

export const selectListOfWebHooksLength = createSelector(
  webHookState,
  ({ list }) => list?.length
);

export const selectWebHookStateLoadingStatus = createSelector(
  webHookState,
  ({ status }) => status
);

export const selectDataFetchStatus = createSelector(
  webHookState,
  ({ status, list }) => status != 'pending' && list != null
);

export const selectShowSpinnerInWebhookPage = createSelector(
  webHookState,
  ({ status, list }) =>
    (status == 'pending' || status == 'loading') && list == null
);

export const selectShowWebHookEmptyContentSection = createSelector(
  webHookState,
  ({ status, list }) => status == 'success' && list?.length == 0
);

export const selectShowWebHookListSection = createSelector(
  webHookState,
  ({ status, list }) => {
    const listLength = list == null ? 0 : list?.length;
    return listLength > 0;
  }
);

export const selectWebhookTypes = createSelector(
  webHookState,
  ({ types }) => types
);

export const selectWebhookTypesWithAppId = createSelector(
  webHookState,
  ({ appId, types }) => ({ appId, types })
);

export const selectShowCreateNewHookDialog = createSelector(
  webHookState,
  ({ list }) => {
    if (list == null) return false;
    return list?.length < 10;
  }
);

export const selectWebhookActiveness = createSelector(
  webHookState,
  ({ isActive }) => isActive
);

export const selectWebhookActivenessWithAppId = createSelector(
  webHookState,
  ({ appId, isActive }) => ({ appId, isActive })
);
