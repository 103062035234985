import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { orderPaymentStatusList } from '../../data';

@Pipe({
  name: 'translateOrderPaymentStatus',
  standalone: true
})
export class TranslateOrderPaymentStatusPipe implements PipeTransform {

  private readonly orderPaymentStatusList = orderPaymentStatusList;
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    const orderPaymentStatusTranslation = this.orderPaymentStatusList.find(
      (x) => x.type == value
    )?.translationLabel;
    if (!!orderPaymentStatusTranslation) {
      return this.translateService.instant(
        `SHARED.${orderPaymentStatusTranslation}`
      );
    }
    return '';
  }
}
