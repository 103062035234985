export type PAYMENT_TYPES =
  | 'Online'
  | 'Online3DS'
  | 'PayPal'
  | 'PayAtDoor'
  | 'Transfer'
  | 'Stripe'
  | 'Stripe3DS'
  | 'PayTr'
  | 'BirlesikOdeme'
  | 'PayZee'
  | 'Iyzico'
  | 'Shopiroller';

export enum PAYMENT_TYPES_ENUM {
  'Online' = 'Online',
  'Online3DS' = 'Online3DS',
  'PayPal' = 'PayPal',
  'PayAtDoor' = 'PayAtDoor',
  'Transfer' = 'Transfer',
  'Stripe' = 'Stripe',
  'Stripe3DS' = 'Stripe3DS',
  'PayTr' = 'PayTr',
  'BirlesikOdeme' = 'BirlesikOdeme',
  'PayZee' = 'PayZee',
  'Iyzico' = 'Iyzico',
  'Shopiroller' = 'Shopiroller' // in backend this payment called shopiroller but in client-side we show it as shopipay
}
