export interface ISlidesModel {
  id: string;
  imageUrl: any;
  isActive: boolean;
  navigationLink: string;
  navigationType: string;
  newIndex: number;
  // orderIndex: number;
  // createDate: string;
  // updateDate: string;
}
export class SlidesModel implements ISlidesModel {
  constructor(
    public id: string,
    public imageUrl: any,
    public isActive: boolean,
    public navigationLink: string,
    public navigationType: string,
    public newIndex: number
  ) // public orderIndex: number,
  // public createDate: string,
  // public updateDate: string
  {}
}

export interface ISlideModel {
  navigationLink: string;
  navigationType: string;
  imageUrl: any;
}

export class SlideModel implements ISlideModel {
  constructor(
    public navigationLink: string,
    public navigationType: string,
    public imageUrl: any
  ) {}
}
