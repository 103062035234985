import { createAction, props } from '@ngrx/store';

import { UserModel } from '../../models/user.model';

/******************* Login User To Panel  ***********************/
export const loginUserPanel = createAction(
  '[Auth] Login User To Panel',
  props<{ payload: any }>()
);
export const loginUserPanelSuccess = createAction(
  '[Auth] Login User To Panel Success',
  props<{ user: UserModel }>()
);
export const loginUserPanelFailure = createAction(
  '[Auth] Login User To Panel Failure',
  props<{ error: any }>()
);

/******************* Logout User From Panel  ***********************/
export const LOGOUT_ACTION = '[Auth] Logout User From Panel'; //caution: this text used in meta reducer
export const logOutUserFromPanel = createAction(LOGOUT_ACTION);
