export class DateUtil {
  static readonly _monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  static getDaysOfCurrentWeek() {
    var week = new Array();
    const current = new Date();
    current.setDate(current.getDate() - current.getDay() + 1);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return week;
  }

  /**
   *
   * @param date gets a date in format like  9/14/2021
   * @returns a date in format like 14 september 2021
   */
  static formatDate(date: string): string {
    const lastIdx = date.lastIndexOf('/');
    const firstIdx = date.indexOf('/');
    const year = date.substring(lastIdx + 1);
    const day = date.substring(firstIdx + 1, firstIdx + 3);
    const month = parseInt(
      date.substring(0, date.substring(0, firstIdx).length)
    );

    return `${day} ${this._monthNames[month - 1]} ${year}`;
  }

  /**
   *
   * @param date gets a date in format like  2022-07-01T13:32:04.417Z
   * @returns a date in format like 2021/07/02
   */
  static toISOString(date: string): string {
    const newDate = new Date(date);
    return newDate.toISOString().substring(0, 10).replace(/-/g, '/');
  }

  /**
   *
   * @param date gets a date in format like  "2022/07/01"
   * @returns a date in format like 14 september 2021
   */

  static formatISODate(date: string): string {
    const year = date.substring(0, 4);
    const firstIdx = date.indexOf('/');
    const month = parseInt(date.substring(firstIdx + 1, firstIdx + 3));
    const lastIdx = date.lastIndexOf('/');
    const day = date.substring(lastIdx + 1);
    return `${day} ${this._monthNames[month - 1]} ${year}`;
  }

  static getCurrentUTCDate(): Date {
    return new Date(Date.now() + new Date().getTimezoneOffset() * 60 * 1000); // Convert to UTC timezone
  }

  static getUTCStartOfDay(): Date {
    const now = DateUtil.getCurrentUTCDate();
    return new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        0,
        0,
        0,
        0
      )
    );
  }

  static getUTCEndOfDay(): Date {
    const now = DateUtil.getCurrentUTCDate();
    return new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        23,
        59,
        59,
        999
      )
    );
  }

  static convertToUTCDate(year, month, day): Date {
    return new Date(Date.UTC(year, month - 1, day));
  }
}
