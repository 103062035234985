<div
  class="alert alert-custom fade show py-2 alert-{{ alertType }}"
  role="alert"
>
  <div class="alert-icon">
    <fa-icon
      [icon]="
        alertType == 'success' || alertType == 'primary' ? faInfo : faWarning
      "
    ></fa-icon>
  </div>

  <div class="alert-text" [innerHtml]="message"></div>
</div>
