<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{ 'ORDERS.PAYMENT_DETAILS' | translate }}
      </h3>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-4 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-1 font-size-lg">
          {{ 'ORDERS.ORDER_NUMBER' | translate }}
        </span>
        <span class="text-muted font-weight-bold">{{
          paymentDetail?.orderNumber
        }}</span>
      </div>
      <div class="col-4 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-1 font-size-lg">
          {{ 'ORDERS.ORDER_AMOUNT' | translate }}
        </span>
        <span class="text-muted font-weight-bold">{{
          paymentDetail?.orderAmount
        }}</span>
      </div>
      <div class="col-4 d-flex flex-column">
        <span
          class="text-dark font-weight-bold mb-1 font-size-lg"
          [title]="'ORDERS.ORDER_FEE_DESCRIPTION' | translate"
        >
          {{ 'ORDERS.ORDER_FEE' | translate }}
          <fa-icon
            [icon]="faInfo"
            class="text-primary"
            [title]="'ORDERS.ORDER_FEE_DESCRIPTION' | translate"
          ></fa-icon>
        </span>
        <span class="text-muted font-weight-bold">{{
          paymentDetail?.amount ?? '-'
        }}</span>
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <div class="col-4 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-1 font-size-lg">
          {{ 'ORDERS.COLLECTION_STATUS' | translate }}
        </span>
        <span class="text-muted font-weight-bold"
          >{{ orderInfo?.orderPaymentStatus | translateOrderPaymentStatus }}
        </span>
      </div>

      <div class="col-4 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-1 font-size-lg">
          {{ 'ORDERS.COMMISSION_RATE' | translate }}
        </span>
        <span class="text-muted font-weight-bold">{{
          paymentDetail?.commissionRate
        }}</span>
      </div>

      <div class="col-4 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-1 font-size-lg">
          {{ 'ORDERS.ORDER_INSTALLMENT' | translate }}
        </span>
        <span class="text-muted font-weight-bold">{{
          paymentDetail?.installment ?? '-'
        }}</span>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive pl-3 pr-3">
        <p-table
          [value]="paginatedTransactionList"
          [responsive]="true"
          styleClass="p-datatable-lg p-datatable-responsive"
        >
          <ng-template pTemplate="header">
            <div
              class="p-col-12 bg-gray-100 table-head-custom text-left text-uppercase"
            >
              <tr class="p-gird p-d-flex">
                <th class="p-col-2 px-5 d-flex">
                  {{ 'SHARED.AMOUNT' | translate | uppercase }}
                </th>
                <th class="p-col-2 px-5 d-flex">
                  {{ 'SHARED.CURRENCY' | translate | uppercase }}
                </th>

                <th class="p-col-3 px-5">
                  {{ 'SHARED.DATE' | translate | uppercase }}
                </th>
                <th class="p-col-5 px-5">
                  {{ 'SHARED.MESSAGE' | translate | uppercase }}
                </th>
              </tr>
            </div>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr class="p-gird p-d-flex font-size-lg">
              <td class="p-col-2 d-flex align-items-center">
                {{ item.amount }}
              </td>
              <td class="p-col-2 d-flex align-items-center">
                {{ item.currency }}
              </td>
              <td class="p-col-3 d-flex align-items-center">
                {{ item.date }}
              </td>
              <td class="p-col-5 d-flex align-items-center">
                {{ item.statusMessage }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr *ngIf="isLoading == false">
              <td colspan="7">
                {{ 'SHARED.NO_RECORDS_FOUND' | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="col-12 text-center mt-4" *ngIf="showLoadMoreButton == true">
          <button
            type="button"
            class="btn btn-outline-dark"
            (click)="paginateTransactions()"
          >
            {{ 'SHARED.LOAD_MORE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
