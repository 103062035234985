import { Routes } from '@angular/router';


export const ORDERS_ROUTES: Routes = [
  {
    path: 'list',
    loadComponent: async () =>
      (await import('../features/order-list/order-list.component'))
        .OrderListComponent
  },
  {
    path: 'order-detail/:orderId',
    loadComponent: async () =>
      (await import('../features/order-detail/order-detail.component'))
        .OrderDetailComponent
  },
  {
    path: 'refund/:orderId',
    loadComponent: async () =>
      (await import('../features/refund/refund.component')).RefundComponent
  },
  {
    path: 'collection-transactions',
    loadComponent: async () =>
      (
        await import(
          '../features/collection-transactions/collection-transactions.component'
        )
      ).CollectionTransactionsComponent
  },
  {
    path: 'link-operations',
    loadComponent: async () =>
      (await import('../features/link-operations/link-operations.component'))
        .LinkOperationsComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'list'
  }
];
