import { ISlideModel, ISlidesModel } from '../../models';

import { END_POINTS_URL, REST_REQUEST_RESULT } from '@pratik-islem/shared/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class SliderService {
  constructor(private http: HttpClient) {}

  public getSliders(
    appId: string,
    sliderType: 'Web' | 'Mobile'
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/sliders?type=${sliderType}`;
    return this.http.get(URL).pipe(map((result: any) => result.data));
  }

  public uploadSliderImage(appId: string, image: any): Observable<any> {
    const E_COMMEREC_IMAGE_URL = `${END_POINTS_URL.V1.OPERATION_APPS_URL}/${appId}/ecommerceImage`;
    return this.http.post(E_COMMEREC_IMAGE_URL, image).pipe(
      map((result: any) => {
        if (result.success === true) {
          return result.data;
        }
        return REST_REQUEST_RESULT.FAILED;
      })
    );
  }

  public addNewSlide(
    appId: string,
    slide: ISlideModel,
    sliderId: string
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/sliders/${sliderId}/slides`;
    return this.http.post(URL, slide).pipe(map((result: any) => result.data));
  }

  public addNewSlideWithoutId(appId: string, slide: any): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/sliders`;
    return this.http.post(URL, slide).pipe(
      map((result: any) => {
        if (result.success === true) {
          return REST_REQUEST_RESULT.SUCCESSFUL;
        }
        return REST_REQUEST_RESULT.FAILED;
      })
    );
  }

  public updateSlide(
    appId: string,
    slide: ISlidesModel,
    sliderId
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/sliders/${sliderId}/slides/${slide.id}`;
    return this.http.put(URL, slide).pipe(map((result: any) => result.data));
  }

  public deleteSlide(
    appId: string,
    sliderId: string,
    slideId: string
  ): Observable<REST_REQUEST_RESULT> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/sliders/${sliderId}/slides/${slideId}`;
    return this.http.delete(URL, { observe: 'response' }).pipe(
      map((result) => {
        if (
          result.status === 200 ||
          result.status === 201 ||
          result.status === 204
        ) {
          return REST_REQUEST_RESULT.SUCCESSFUL;
        }
        return REST_REQUEST_RESULT.FAILED;
      })
    );
  }
}
