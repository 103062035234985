import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { OrderStatusList } from '../../data/constants/order-status.constant';

@Directive({
  selector: '[appStatusColorPicker]',
  standalone: true
})
export class StatusColorPickerDirective implements OnInit {
  private orderStatusList = OrderStatusList;
  @Input('id') id: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    const statusColor =
      this.orderStatusList.find((x) => x.type == this.id)?.color ?? '';
    this.renderer.addClass(this.el.nativeElement, statusColor);
  }
}
