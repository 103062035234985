import { AppSettingService } from '@pratik-islem/core';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  UpdateUserProfileModel,
  UserProfileService,
  loadUserProfileInformation,
  selectUserProfileInformation
} from '@pratik-islem/user-profile';
import { AuthService } from './modules/auth';
import { AutoUnsubscribe } from '@pratik-islem/shared/decorators';
import { LOCAL_STORAGE_KEYS } from '@pratik-islem/shared/data';
import { LayoutService } from './shared/@metronic/core';
import { SplashScreenService } from './shared/@metronic/partials/layout/splash-screen/splash-screen.service';
import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService, EN_LOCALE, TR_LOCALE } from '@pratik-islem/i18n';
import { concatMap } from 'rxjs/internal/operators/concatMap';
import { of } from 'rxjs/internal/observable/of';

@AutoUnsubscribe()
@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  private _subs = new SubSink();

  constructor(
    private layout: LayoutService,
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private appSettingService: AppSettingService,
    private userProfileService: UserProfileService,
    private translateService: TranslateService,
    private authService: AuthService,
    private readonly _store: Store
  ) {
    this.appSettingService.initializeSettings();
    this.translationService.loadTranslations(EN_LOCALE, TR_LOCALE);
    this.setDefaultConfigOfApp();
  }

  ngOnInit(): void {
    let eventsDispatchedAfterRefresh = false;
    this._subs.sink = this.router.events.subscribe((event) => {
      const routerSubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.id === 1 && event.url === event.urlAfterRedirects) {
            // After refresh event will be executed
            if (
              !eventsDispatchedAfterRefresh &&
              this.authService.authenticated
            ) {
              eventsDispatchedAfterRefresh = true;
              this._dispatchCommandEvents();
            }
          }
          // hide splash screen
          this.splashScreenService.hide();

          // scroll to top on every route change
          window.scrollTo(0, 0);

          // to display back the body content
          setTimeout(() => {
            document.body.classList.add('page-loaded');
            this.layout.resetAsideMenuDisplayMode();
          }, 500);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private _dispatchCommandEvents(): void {
    this._store.dispatch(loadUserProfileInformation());
  }

  private setDefaultConfigOfApp(): void {
    const defaultLanguage = this._getDefaultLanguage();
    if (defaultLanguage.baseBrowserLanguage && this.authService.authenticated) {
      this._subs.sink = this._store
        .select(selectUserProfileInformation)
        .pipe(
          concatMap((userInfo) => {
            if (!userInfo) return of(null);
            const updatedUser: UpdateUserProfileModel = {
              language: defaultLanguage.language,
              fullname: userInfo.fullName
            };
            return this.userProfileService.updateUserProfile(updatedUser);
          })
        )
        .subscribe((result) => {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_LANGUAGE,
            defaultLanguage.language
          );
        });
    }
    this.translateService.setDefaultLang(defaultLanguage.language);
  }

  private _getDefaultLanguage(): {
    language: string;
    baseBrowserLanguage: boolean;
  } {
    const userLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE);
    if (userLanguage != null) {
      return {
        language: userLanguage,
        baseBrowserLanguage: false
      };
    } else {
      const browserLanguage =
        navigator.language == undefined
          ? 'tr'
          : navigator.language.toLowerCase().substring(0, 2);
      let browserLanguages =
        navigator.languages === undefined ? ['tr'] : navigator.languages;
      browserLanguages = browserLanguages.map((language) =>
        language.toLowerCase().substring(0, 2)
      );
      let defaultLanguage = browserLanguages.some((x) => x == 'tr')
        ? 'tr'
        : 'en';
      if (defaultLanguage != 'tr' && browserLanguage == 'tr') {
        defaultLanguage = 'tr';
      }
      return {
        language: defaultLanguage,
        baseBrowserLanguage: true
      };
    }
  }
}
