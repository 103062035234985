import { Routes } from '@angular/router';

export const WEBHOOK_ROUTES: Routes = [
  {
    path: 'list',
    loadComponent: async () =>
      (await import('../features/shell/shell.component')).ShellComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: async () =>
      (await import('../features/shell/shell.component')).ShellComponent
  }
];
