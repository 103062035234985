import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OrderNotePipe } from '../../utils';

@Component({
  selector: 'order-user-note',
  templateUrl: './user-note.component.html',
  styleUrls: ['./user-note.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, OrderNotePipe]
})
export class UserNoteComponent {
  @Input()
  orderInfo;
}
