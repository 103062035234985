import { Injectable } from '@angular/core';
import localeEN from '@angular/common/locales/en';
import localeTR from '@angular/common/locales/tr';
import { registerLocaleData } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalService {
  readonly #supportedLocales = ['en', 'tr'];
  readonly #defaultLocales = ['en', 'tr'];

  registerDefaultLocals(): void {
    registerLocaleData(localeEN);
    registerLocaleData(localeTR);
  }

  registerLocale(locale: string = 'en'): void {
    const requestedLocale = `locale${locale.toUpperCase()}`;
    if (!this.#defaultLocales.includes(locale)) {
      const localeFile = this.#supportedLocales.includes(locale)
        ? requestedLocale
        : localeEN;
      registerLocaleData(localeFile);
    }
  }
}
