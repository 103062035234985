<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{ 'ORDERS.CARGO' | translate }}</h3>
    </div>
  </div>
  <div class="card-body">
    <div class="d-flex flex-wrap align-items-center">
      <button
        class="btn btn-outline-info font-weight-bold float-right w-"
        (click)="showCargoDimentionDialog()"
      >
        {{ 'ORDERS.GET_CARGO_OFFER' | translate }}
      </button>
    </div>
  </div>
</div>

<p-toast position="bottom-center" [preventOpenDuplicates]="true"></p-toast>
