<div
  id="translation-section"
  *ngIf="languagesFetched == true"
  class="container d-flex justify-content-center"
>
  <div class="row w-100 py-2">
    <div
      [ngClass]="
        showLanguageListAndTagsInDifferentLine == true
          ? 'col-12 align-items-center d-flex'
          : 'col-12 col-lg-8 col-md-6 col-sm-6 align-items-center d-flex'
      "
    >
      <p-chip
        [tooltipPosition]="'top'"
        [pTooltip]="'SHARED.DEFAULT_LANG_CANT_BE_DELETED' | translate"
        [styleClass]="
          currentlySelectedLanguage.isO2 ==
          selectedAppLanguages.defaultLanguage.isO2
            ? 'active-chip btn-primary text-white'
            : 'text-dark'
        "
        (click)="
          handleLanguageSelectEvent(
            $event,
            selectedAppLanguages.defaultLanguage
          )
        "
        label="{{ selectedAppLanguages.defaultLanguage.isO2 }}-
        {{ selectedAppLanguages.defaultLanguage.displayName }}"
      >
      </p-chip>
      <p-chip
        [removable]="true"
        (onRemove)="removeSelectedLanguageForTranslation(lang.isO2)"
        (click)="handleLanguageSelectEvent($event, lang)"
        selectable="true"
        styleClass="p-mr-2 bg-primary"
        id="lang-chip-{{ idx }}"
        [styleClass]="
          currentlySelectedLanguage.isO2 == lang.isO2 ? 'active-chip' : ''
        "
        *ngFor="
          let lang of selectedLanguageForAddingTranslation;
          let idx = index
        "
        label="{{ lang.isO2 }}-{{ lang.displayName }}"
      >
      </p-chip>
    </div>
    <div
      [ngClass]="
        showLanguageListAndTagsInDifferentLine == true
          ? 'col-12'
          : 'col-12 col-lg-4 col-md-6 col-sm-6'
      "
    >
      <select
        [pTooltip]="'SHARED.ADD_NEW_TRANSLATE' | translate"
        [tooltipPosition]="'top'"
        class="form-control"
        *ngIf="languageForAddingTranslation.length > 0"
        #langsDropDown
        (change)="handleChangeLanguage($event)"
      >
        <option value="none" selected disabled>
          {{ 'SHARED.ADD_NEW_TRANSLATE' | translate }}
        </option>
        <option
          *ngFor="let lang of languageForAddingTranslation"
          value="{{ lang.isO2 }}"
        >
          {{ lang.displayName }}
        </option>
      </select>
    </div>
  </div>
</div>
