import { Subscription } from 'rxjs/internal/Subscription';

export function AutoUnsubscribe(field = 'subs'): any {
  return (target: any) => {
    const originalFactory = target.ɵfac;

    target.ɵfac = (...args: any) => {
      const component = originalFactory(...args);

      const originDestroy = target.prototype.ngOnDestroy;
      target.prototype.ngOnDestroy = function ngOnDestroy() {
        if (originDestroy) {
          originDestroy.apply(this, ...args);
        }
        (this[field] as Subscription)?.unsubscribe();
      };
      return component;
    };

    return target;
  };
}
