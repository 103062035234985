export const StoreSectorData = [
  {
    name: {
      tr: 'Otomotiv Yedek Parçaları',
      en: 'Automotive spare parts'
    }
  },
  {
    name: {
      tr: 'Çeyiz ve Züccaciye',
      en: 'Dowry & Glassware'
    }
  },
  {
    name: {
      tr: 'Müzik ve Müzik Aletleri',
      en: 'Music & Musical Instruments'
    }
  },
  {
    name: {
      tr: 'Tekstil',
      en: 'Textile'
    }
  },
  {
    name: {
      tr: 'Saat ve Optik',
      en: 'Clock & Optics'
    }
  },
  {
    name: {
      tr: 'Hırdavat ve Yapı Pazarı',
      en: 'Hardware & Construction Market'
    }
  },
  {
    name: {
      tr: 'Ünlüler ve Fenomenler',
      en: 'Celebrities and Phenomenons'
    }
  },
  {
    name: {
      tr: 'Ayakkabı',
      en: 'Shoe'
    }
  },
  {
    name: {
      tr: 'Konsept Tasarımlar',
      en: 'Concept Designs'
    }
  },
  {
    name: {
      tr: 'İç Çamaşırı',
      en: 'Underwear'
    }
  },
  {
    name: {
      tr: 'Yiyecek',
      en: 'Food'
    }
  },
  {
    name: {
      tr: 'Ev Tekstilleri',
      en: 'Home textiles'
    }
  },
  {
    name: {
      tr: 'Cep telefonu',
      en: 'Mobile phone'
    }
  },
  {
    name: {
      tr: 'Bilgisayar',
      en: 'Computer'
    }
  },
  {
    name: {
      tr: 'Avcılık & Kamp & Dış Mekan',
      en: 'Hunting & Camping & Outdoor'
    }
  },
  {
    name: {
      tr: 'Kitap Yayımcısı',
      en: 'Book & Publisher'
    }
  },
  {
    name: {
      tr: 'Ofis & Kırtasiye & Promosyon',
      en: 'Office & Stationery & Promotion'
    }
  },
  {
    name: {
      tr: 'Ev Dekorasyonu',
      en: 'Home decoration'
    }
  },
  {
    name: {
      tr: 'Mobilya',
      en: 'Furniture'
    }
  },
  {
    name: {
      tr: 'Beyaz Eşya',
      en: 'White Goods & Appliances'
    }
  },
  {
    name: {
      tr: 'Geniş Ürün Çeşitleri',
      en: 'Wide Variety of Products'
    }
  },
  {
    name: {
      tr: 'Isıtma / Soğutma',
      en: 'Heating Cooling'
    }
  },
  {
    name: {
      tr: 'Güvenlik Ürünleri',
      en: 'Security Products'
    }
  },
  {
    name: {
      tr: 'Takı',
      en: 'Jewelry & Jewelery'
    }
  },
  {
    name: {
      tr: 'Sağlık ve Medikal Ürünler',
      en: 'Health & Medical Products'
    }
  },
  {
    name: {
      tr: 'Motosiklet Ekipmanları',
      en: 'Motorcycle Equipment'
    }
  },
  {
    name: {
      tr: 'Elektrik ve Elektronik',
      en: 'Electric & Electronic'
    }
  },
  {
    name: {
      tr: 'Evcil Hayvan Dükkanı',
      en: 'Pet Shop'
    }
  },
  {
    name: {
      tr: 'Süpermarket / Market',
      en: 'Supermarket / Market'
    }
  },
  {
    name: {
      tr: 'Bebek ve Çocuklar',
      en: 'Baby & Kids'
    }
  },
  {
    name: {
      tr: 'Temizlik ve Paketleme',
      en: 'Cleaning & Packaging'
    }
  },
  {
    name: {
      tr: 'Spor ve Spor Gereçleri',
      en: 'Sports & Sports Equipment'
    }
  },
  {
    name: {
      tr: 'Kişisel Bakım ve Kozmetik',
      en: 'Personal Care & Cosmetics'
    }
  },
  {
    name: {
      tr: 'Oyuncaklar ve Hobiler',
      en: 'Toys & Hobbies'
    }
  },
  {
    name: {
      tr: 'Hediye ve Aksesuarlar',
      en: 'Gift & Accessories'
    }
  },
  {
    name: {
      tr: 'Dernek & Vakıf & Kurum',
      en: 'Association & Foundation & Institution'
    }
  },
  {
    name: {
      tr: 'Çanta',
      en: 'Bag'
    }
  },
  {
    name: {
      tr: 'Tarım ve Yerel Ürünler',
      en: 'Agriculture & Local Products'
    }
  }
];
