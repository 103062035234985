import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { SetTimelineRecordColorDirective } from './set-timeline-record-color.directive';
import { TimelineComponent } from './timeline.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TimelineComponent, SetTimelineRecordColorDirective],
  imports: [CommonModule, TranslateModule, FontAwesomeModule],
  exports: [TimelineComponent],
})
export class TimelineModule {}
