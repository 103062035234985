<form
  class="form"
  [formGroup]="cargoDimensionsForm"
  novalidate="novalidate"
  (ngSubmit)="submit()"
  id="add-webhook-form"
>
  <div class="form-group">
    <label
      >{{ 'SHARED.LENGTH' | translate }} ({{ 'SHARED.CM' | translate }})
      *</label
    >
    <p-inputNumber
      class="w-100 p-input-number p-inputwrapper-filled"
      formControlName="length"
      [min]="1"
    >
    </p-inputNumber>
    <ng-template
      [ngTemplateOutlet]="validationSection"
      [ngTemplateOutletContext]="{
        control: cargoDimensionsForm.controls['length'],
        validation: 'required',
        validationMessage: 'SHARED.FIELD_IS_REQUIRED',
        fieldName: 'SHARED.LENGTH' | translate
      }"
    ></ng-template>
  </div>

  <div class="form-group">
    <label
      >{{ 'SHARED.HEIGHT' | translate }} ({{ 'SHARED.CM' | translate }})
      *</label
    >
    <p-inputNumber
      class="w-100 p-input-number p-inputwrapper-filled"
      formControlName="height"
      [min]="1"
    >
    </p-inputNumber>
    <ng-template
      [ngTemplateOutlet]="validationSection"
      [ngTemplateOutletContext]="{
        control: cargoDimensionsForm.controls['height'],
        validation: 'required',
        validationMessage: 'SHARED.FIELD_IS_REQUIRED',
        fieldName: 'SHARED.HEIGHT' | translate
      }"
    ></ng-template>
  </div>

  <div class="form-group">
    <label
      >{{ 'SHARED.WIDTH' | translate }} ({{ 'SHARED.CM' | translate }}) *</label
    >
    <p-inputNumber
      class="w-100 p-input-number p-inputwrapper-filled"
      formControlName="width"
      [min]="1"
    >
    </p-inputNumber>
    <ng-template
      [ngTemplateOutlet]="validationSection"
      [ngTemplateOutletContext]="{
        control: cargoDimensionsForm.controls['width'],
        validation: 'required',
        validationMessage: 'SHARED.FIELD_IS_REQUIRED',
        fieldName: 'SHARED.WIDTH' | translate
      }"
    ></ng-template>
  </div>

  <div class="form-group">
    <label
      >{{ 'SHARED.WEIGHT' | translate }} ({{ 'SHARED.KG' | translate }})
      *</label
    >
    <p-inputNumber
      class="w-100 p-input-number p-inputwrapper-filled"
      formControlName="weight"
      [min]="1"
    >
    </p-inputNumber>
    <ng-template
      [ngTemplateOutlet]="validationSection"
      [ngTemplateOutletContext]="{
        control: cargoDimensionsForm.controls['weight'],
        validation: 'required',
        validationMessage: 'SHARED.FIELD_IS_REQUIRED',
        fieldName: 'SHARED.WEIGHT' | translate
      }"
    ></ng-template>
  </div>

  <div class="d-flex justify-content-end mt-5">
    <button
      pButton
      type="button"
      class="btn btn-outline-secondary font-weight-bold"
      (click)="closeForm()"
    >
      {{ 'SHARED.CANCEL' | translate }}
    </button>
    <button
      pButton
      type="submit"
      class="btn btn-primary font-weight-bold ml-3"
      [disabled]="cargoDimensionsForm.invalid"
    >
      {{ 'ORDERS.GET_AN_OFFER' | translate }}
    </button>
  </div>
</form>

<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>
<app-animated-logo *ngIf="isLoading == true"></app-animated-logo>

<ng-template
  #validationSection
  let-control="control"
  let-validation="validation"
  let-validationMessage="validationMessage"
  let-fieldName="fieldName"
>
  <ng-container
    *ngIf="
      control?.hasError(validation) &&
      ((control?.invalid && control?.touched) ||
        (control?.dirty && control?.error))
    "
  >
    <p class="text-danger mb-0" *ngIf="fieldName; else simpleValidationMessage">
      {{
        validationMessage
          | translate
            : {
                field: fieldName
              }
      }}
    </p>
    <ng-template #simpleValidationMessage>
      <p class="text-danger mb-0">
        {{ validationMessage | translate }}
      </p>
    </ng-template>
  </ng-container>
</ng-template>

<app-animated-logo *ngIf="isLoading == true"></app-animated-logo>
