import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Injectable } from '@angular/core';
import { UserModel } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: ReplaySubject<UserModel> = new ReplaySubject<UserModel>(1);

  constructor() {}

  set user(value: UserModel) {
    value = value || {
      email: localStorage.getItem('email')
    };
    this._user.next(value);
  }

  get user$(): Observable<UserModel> {
    return this._user.asObservable();
  }
}
