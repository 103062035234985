class ShippingPriceModel {
    constructor(
        public currency: string,
        public value: number
        ) { }
}

export class ShippingAndDeliveryModel {
    public fixPrice: ShippingPriceModel;
    public freeShippingPriceThreshold: ShippingPriceModel;
    public isFreeShippingActive: boolean
}