import * as dashboardActions from './dashboard.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import { DashboardPageModel } from '../../data/models';
import { Injectable } from '@angular/core';
import { ClientService } from '../services';
import { OrderService } from '@pratik-islem/store/orders';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { selectDashboardPageData } from './dashboard.selectors';

@Injectable({
  providedIn: 'root'
})
export class DashboardFeatureEffects {
  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _orderService: OrderService,
    private readonly _clientService: ClientService
  ) {}

  loadDashboardPageData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dashboardActions.LoadDashboardPageData),
      withLatestFrom(this._store.select(selectDashboardPageData)),
      switchMap((actions) => {
        const currentAppId = actions[0].payload.appId;
        const previousAppId = actions[1].appId;
        if (currentAppId == previousAppId) {
          const dashboardData: DashboardPageModel = {
            appId: previousAppId,
            lastOrders: actions[1].lastOrders,
            lastCustomers: actions[1].lastCustomers,
            todayOrdersData: actions[1].todayOrdersData ?? {
              awaitingOrdersCount: 0,
              totalOrders: 0,
              totalSalesAmount: 0
            }
          };
          return of(
            dashboardActions.LoadDashboardPageDataSuccess({
              response: dashboardData
            })
          );
        } else {
          const last10Orders$ = this._orderService.filterOrders(
            actions[0].payload.appId,
            '',
            1,
            10,
            'Descending',
            'CreationDate'
          );

          const lastCustomers$ = this._clientService.filterUsers({
            apiKey: environment.api_key,
            appKey: actions[0].payload.appKey,
            page: 1,
            limit: 10
          });

          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const endOfDay = new Date(today);
          endOfDay.setHours(23, 59, 59, 999);
          const startDate = today.toISOString();
          const endDate = endOfDay.toISOString();

          const filter: string[] = [
            `createDate.Min=${startDate}`,
            `createDate.Max=${endDate}`
          ];

          const lastOrders$ = this._orderService.filterOrders(
            actions[0].payload.appId,
            '',
            1,
            10000,
            'Descending',
            'CreationDate',
            'None',
            ...filter
          );

          const requests$: Observable<any>[] = [
            last10Orders$,
            lastCustomers$,
            lastOrders$
          ];

          return forkJoin(requests$).pipe(
            map((responses: any) => {
              const todayOrders = responses[2].data;
              let totalSalesAmount = 0;
              let awaitingOrdersCount = 0;
              if (todayOrders.length > 1) {
                todayOrders.reduce((prevOrder: any, currentOrder: any) => {
                  totalSalesAmount += currentOrder.totalPrice;
                }, 0);
                awaitingOrdersCount = todayOrders.filter(
                  (x: any) => x.isRead == false
                ).length;
              }
              const dashboardData: DashboardPageModel = {
                appId: currentAppId,
                lastOrders: responses[0].data,
                lastCustomers: responses[1].items,
                todayOrdersData: {
                  awaitingOrdersCount,
                  totalSalesAmount,
                  totalOrders: todayOrders.length
                }
              };
              return dashboardActions.LoadDashboardPageDataSuccess({
                response: dashboardData
              });
            }),
            catchError((error: any) => {
              return of(
                dashboardActions.LoadDashboardPageDataFailure({
                  error: error
                })
              );
            })
          );
        }
      })
    )
  );
}
