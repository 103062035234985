import * as authActions from './auth.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError } from 'rxjs/internal/operators/catchError';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { map } from 'rxjs/internal/operators/map';
import { AuthService } from '../services';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS } from '@pratik-islem/shared/data';

@Injectable({
  providedIn: 'root'
})
export class AuthFeatureEffects {
  public loginUser$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(authActions.loginUserPanel),
      switchMap((actions) => {
        return this._authService.signIn(actions.payload).pipe(
          map(
            (response) => {
              //todo: response must be modified
              const user: any = {
                email: localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL)
              };
              return authActions.loginUserPanelSuccess(user);
            },
            catchError(() => EMPTY)
          )
        );
      })
    );
  });

  constructor(
    private readonly _actions$: Actions,
    private readonly _authService: AuthService
  ) {}
}
