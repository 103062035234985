import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'order-shopiroller-alert',
  templateUrl: './shopiroller-alert.component.html',
  styleUrls: ['./shopiroller-alert.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FontAwesomeModule]
})
export class ShopirollerAlertComponent {
  public faInfo = faInfoCircle;

  constructor() {}
}
