export const VALIDATION_VALUES = {
  PASSWORD_MIN_LENGTH: 6,
  PASSWORD_MAX_LENGTH: 32,
  EMAIL_MAX_LENGTH: 256,
  FULL_NAME_MIN_LENGTH: 3,
  FULL_NAME_MAX_LENGTH: 100,
  APPLICATION_NAME_MIN_LENGTH: 3,
  APPLICATION_NAME_MAX_LENGTH: 25,
  PHONE_NUMBER_REG_X_PATTERN: '[- +()0-9]+',
  PHONE_NUMBER_MAX_LENGTH: 15,
  SHORT_TEXT_MIN_LENGTH: 2,
  SHORT_TEXT_MAX_LENGTH: 60,
  MIDDLE_TEXT_MIN_LENGTH: 2,
  MIDDLE_TEXT_MAX_LENGTH: 255,
  LONG_TEXT_MAX_LENGTH: 1000,
  ZIP_CODE_MAX_LENGTH: 12,
  CITY_MAX_LENGTH: 90,
  STREET_MAX_LENGTH: 100,
  ID_LENGTH: 24,
  WEB_URL_PATTERN: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
  MAX_NUMBER_OF_STOCK: Number.MAX_SAFE_INTEGER,
  MAX_ALLOWED_CONNECTED_APPS_TO_SALES_CHANNEL: 100,
  API_KEY_MIN_LENGTH: 3,
  API_KEY_MAX_LENGTH: 255,
  MERCHANT_ID_MIN_LENGTH: 3,
  MERCHANT_ID_MAX_LENGTH: 10,
  BIRLESIK_ODEME_PASSWORD_MIN_LENGTH: 3,
  BIRLESIK_ODEME_PASSWORD_MAX_LENGTH: 255,
  SUB_DOMAIN_MAX_LENGTH: 63,
  CUSTOM_DOMAIN_MAX_LENGTH: 200,
  COUPON_CODE_MAX_LENGTH: 64,
  COUPON_CODE_MIN_LENGTH: 2,
  VIDEO_URL_MAX_LENGTH: 1000,
  CVC_MIN_LENGTH: 3,
  CVC_MAX_LENGTH: 4,
  CARD_NUMBER_MAX_LENGTH: 16,
  IBAN_MINIMUM_LENGTH: 26,
  IBAN_MAXIMUM_LENGTH: 32,
  IDENTITY_NUMBER_MINIMUM_LENGTH: 7,
  IDENTITY_NUMBER_MAXIMUM_LENGTH: 12,
  TAX_NUMBER_MAXIMUM_LENGTH: 20,
  URL_MAX_LENGTH: 2083
};
