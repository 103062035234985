import { HIGHLIGHT_OPTIONS } from "ngx-highlightjs";

export const HIGHLIGHT_JS_APP_INITIALIZER_PROVIDER = [
    {
        provide: HIGHLIGHT_OPTIONS,
        useValue: {
            coreLibraryLoader: () => import('highlight.js/lib/core'),
            languages: {
                xml: () => import('highlight.js/lib/languages/xml'),
                typescript: () => import('highlight.js/lib/languages/typescript'),
                scss: () => import('highlight.js/lib/languages/scss'),
                json: () => import('highlight.js/lib/languages/json'),
            },
        },
    }
];
