import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTimeLineRecordColor]',
  standalone: true
})
export class TimeLineRecordColorDirective {

  private orderStatusList = [
    { label: 'WaitingPayment', color: 'text-dark' },
    { label: 'WaitingApproval', color: 'text-info' },
    { label: 'Approved', color: 'text-primary' },
    { label: 'WaitingForSupplying', color: 'text-warning' },
    { label: 'Preparing', color: 'text-primary' },
    { label: 'Shipped', color: 'text-success' },
    { label: 'Delivered', color: 'text-success' },
    { label: 'CancelRequested', color: 'text-danger' },
    { label: 'Canceled', color: 'text-danger' },
    { label: 'Refunded', color: 'text-dark' },
    { label: 'PaymentFailed', color: 'text-dark' },
  ];

  @Input('id') id: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {}
  ngOnInit(): void {
    const statusColor =
      this.orderStatusList.find((x) => x.label == this.id)?.color ?? '';
    this.renderer.addClass(this.el.nativeElement, statusColor);
  }
}
