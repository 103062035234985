export class ArrayUtil {

  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  static arraysAreEqual(a1, a2): boolean {
    return JSON.stringify(a1) == JSON.stringify(a2);
  }

  static extractUniqueElements(arr: any[]): any[] {
    return [...new Set(arr.map((o) => JSON.stringify(o)))].map((s) =>
      JSON.parse(s)
    );
  }
}
