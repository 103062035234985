import { ActionReducer, MetaReducer, State } from '@ngrx/store';
import { LOGOUT_ACTION } from 'src/app/modules/auth';
 
export function clearStateMetaReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    if (action.type === LOGOUT_ACTION) {
      state = {} as State<any>; // ==> Emptying state here
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearStateMetaReducer];
