import * as appActions from './stores.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AppListResultModel,
  GetAppListModel,
  SelectedAppDataModel
} from '../../models';
import { ApplicationsService, StoreService } from '../services';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import {
  selectCurrentAppWholeData,
  selectListOfApps,
  selectPrimitiveStoreData
} from './stores.selectors';
import { Injectable } from '@angular/core';
import { SalesChannelService } from '@pratik-islem/store/sales-channel';
import { Store } from '@ngrx/store';
import { StoreActionsMessageTypesList } from '@pratik-islem/core';
import { PrimitveStoreDataModel } from '../../models/store.model';
import {
  LanguageService,
  SharedService
} from '@pratik-islem/store/store-settings';

@Injectable({
  providedIn: 'root'
})
export class StoresFeatureEffects {
  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _appService: ApplicationsService,
    private readonly _saleChannelsService: SalesChannelService,
    private readonly _storeService: StoreService,
    private readonly _languageService: LanguageService,
    private readonly _sharedService: SharedService
  ) {}

  public getListOfApps$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.GetListOfApps),
      withLatestFrom(this._store.select(selectListOfApps)),
      switchMap((actions) => {
        return this._appService
          .appList(actions[0].payload.appName, actions[0].payload.pageIndex)
          .pipe(
            map(
              (response) =>
                appActions.GetListOfAppsSuccess({
                  response: response as AppListResultModel
                }),
              catchError(() => EMPTY)
            )
          );
      })
    )
  );

  public loadMoreApps$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.LoadMoreApps),
      switchMap((actions) => {
        return this._appService
          .appList(actions.payload.appName, actions.payload.pageIndex)
          .pipe(
            map(
              (response) =>
                appActions.LoadMoreAppsSuccess({
                  response: response as AppListResultModel
                }),
              catchError(() => EMPTY)
            )
          );
      })
    )
  );

  loadCurrentAppWholeData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.LoadCurrentAppData),
      withLatestFrom(this._store.select(selectCurrentAppWholeData)),
      switchMap((actions) => {
        const appId = actions[0].appId;
        const getAppData$ = this._appService.getSingleApplication(appId);
        const getListOfSupportedSalesChannels$ =
          this._saleChannelsService.getSupportedChannel(appId);
        const requests$: Observable<any>[] = [
          getAppData$,
          getListOfSupportedSalesChannels$
        ];

        return forkJoin(requests$).pipe(
          switchMap((responses: any) => {
            const appData: SelectedAppDataModel = {
              languageList: null,
              paymentInfo: null,
              appDetails: responses[0].data,
              supportedSalesChannelList: responses[1],
              webSettings: null
            };
            return of(
              appActions.LoadCurrentAppDataSuccess({
                response: appData
              })
            );
          }),
          catchError((error: any) => {
            return of(
              appActions.LoadCurrentAppDataFailure({
                error: error
              })
            );
          })
        );
      })
    )
  );

  loadPrimitiveStoreInformationEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.loadPrimitiveStoreInformation),
      withLatestFrom(this._store.select(selectPrimitiveStoreData)),
      switchMap((actions) => {
        const previousPrimitiveStoreData = actions[1];
        if (
          previousPrimitiveStoreData.currencyList.length > 0 &&
          previousPrimitiveStoreData.languageList.length > 0
        ) {
          return of(
            appActions.loadPrimitiveStoreInformationSuccess({
              response: previousPrimitiveStoreData
            })
          );
        }
        const languageList$ = this._languageService.getLanguageList();
        const currencyList$ = this._sharedService.getCurrenciesList();

        const primitiveStoreDataRequests$: Observable<any>[] = [
          languageList$,
          currencyList$
        ];
        return forkJoin(primitiveStoreDataRequests$).pipe(
          map((apiResponses: any) => {
            const storeInfo: PrimitveStoreDataModel = {
              languageList: apiResponses[0].data,
              currencyList: apiResponses[1]
            };
            return appActions.loadPrimitiveStoreInformationSuccess({
              response: storeInfo
            });
          }),
          catchError((error) =>
            of(
              appActions.loadPrimitiveStoreInformationFailure({
                error: error
              })
            )
          )
        );
      })
    )
  );

  public createStoreEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.createStore),
      switchMap((actions) => {
        return this._storeService.add(actions.payload).pipe(
          map((response) => {
            const getProjectListPayload: GetAppListModel = {
              appName: '',
              pageIndex: 1,
              perPage: 8
            };
            this._store.dispatch(
              appActions.GetListOfApps({ payload: getProjectListPayload })
            );
            return appActions.createStoreSuccess();
          }),
          catchError((error) =>
            of(
              appActions.createStoreFailure({
                payload: {
                  result: {
                    message: 'APPLICATIONS.STORE_CREATION_FAILED',
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            )
          )
        );
      })
    )
  );
}
