import { init, routingInstrumentation } from '@sentry/angular';

import { AppModule } from './app/app.module';
import { BrowserTracing } from '@sentry/tracing';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { featuresList } from '@pratik-islem/shared/data';

const LOGGING_SYSTEM = environment.features.find(
  (x) => x.name == featuresList.loggingSystem
);

if (LOGGING_SYSTEM?.isActive) {
  init({
    dsn: environment.sentry_config.dsn,
    environment: environment.name,
    attachStacktrace: true,
    integrations: [
      new BrowserTracing({
        tracingOrigins: environment.sentry_config.tracingOrigins,
        routingInstrumentation: routingInstrumentation
      })
    ],
    tracesSampleRate: 1.0
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    // eslint-disable-next-line no-undef
    console.error(err);
  });
