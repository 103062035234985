export enum CategoryLayoutDisplayTypesEnum {
  ImageAndText = 'ImageAndText',
  TextOnly = 'TextOnly',
  ImageOnly = 'ImageOnly',
}

export type CategoryLayoutDisplayTypes = 'ImageAndText' | 'TextOnly' | 'ImageOnly';

export interface ICategoryDisplayType {
  categoryDisplayType: string;
}
