import { APP_INITIALIZER } from "@angular/core";
import { LocalService } from "./data-access/services/local.service";

export const LOCALE_APP_INITIALIZER_PROVIDER = [
    {
        provide: APP_INITIALIZER,
        multi: true,
        deps: [LocalService],
        useFactory: (localService: LocalService) => {
            return () => {
                return localService.registerDefaultLocals();
            };
        },
    }
];
