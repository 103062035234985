import { ChipModule } from 'primeng/chip';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { TranslationSectionComponent } from './translation-section.component';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TranslationSectionComponent],
  imports: [
    CommonModule,
    ChipModule,
    MatTooltipModule,
    TranslateModule,
    TooltipModule
  ],
  exports: [TranslationSectionComponent]
})
export class TranslationSectionModule {}
