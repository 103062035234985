import { createAction, props } from '@ngrx/store';

import { allOrdersResponseModel } from '../../data/models';

/******************* Load All Orders Data  ***********************/
export const LoadAllOrders = createAction(
  '[Order] Load All Orders Data',
  props<{ appId: string }>()
);

export const LoadAllOrdersSuccess = createAction(
  '[Order] Load All Orders Data Success',
  props<{ response: allOrdersResponseModel }>()
);

export const LoadAllOrdersFailure = createAction(
  '[Order] Load All Orders Data  Failure',
  props<{ error: any }>()
);

/******************* Load Last Orders Data  ***********************/
export const LoadLastOrders = createAction(
  '[Order] Load Last Orders Data',
  props<{ appId: string }>()
);

export const LoadLastOrdersSuccess = createAction(
  '[Order] Load Last Orders Data Success',
  props<{ response: allOrdersResponseModel }>()
);

export const LoadLastOrdersFailure = createAction(
  '[Order] Load Last Orders Data  Failure',
  props<{ error: any }>()
);

/******************* Load Dealer Balance Data  ***********************/
export const LoadDealerBalanceData = createAction(
  '[Order] Load All Orders Data'
);

export const LoadDealerBalanceDataSuccess = createAction(
  '[Order] Load All Orders Data Success',
  props<{ response: any }>()
);

export const LoadDealerBalanceDataFailure = createAction(
  '[Order] Load All Orders Data  Failure',
  props<{ error: any }>()
);
