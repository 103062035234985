import { StoresFeatureKey, StoresFeatureState } from './stores.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const STORES_STATE =
  createFeatureSelector<StoresFeatureState>(StoresFeatureKey);

export const selectListOfApps = createSelector(STORES_STATE, ({ list }) => list);

export const selectTop5Apps = createSelector(STORES_STATE, ({ list }) => {
  if (list == null) return [];
  return list.data.length > 5 ? list.data.slice(0, 5) : list.data;
});

export const selectAppsCount = createSelector(
  STORES_STATE,
  ({ list }) => list?.meta.count
);

export const selectAppLoadingStatus = createSelector(
  STORES_STATE,
  ({ status }) => status
);

export const selectCurrentAppWholeData = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp
);

export const selectCurrentAppPaymentInfo = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.paymentInfo
);

export const selectCurrentAppDefaultCurrency = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.paymentInfo.defaultCurrency
);

export const selectCurrentAppLanguageList = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.languageList
);

export const selectCurrentAppSupportedLanguageList = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.languageList.supportedLanguages
);

export const selectCurrentAppDefaultLanguage = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.languageList.defaultLanguage
);

export const selectCurrentAppDetails = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.appDetails
);

export const selectSupportedSalesChannelOfCurrentApp = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.supportedSalesChannelList
);

export const selectWebSettingsOfCurrentApp = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.webSettings
);

export const selectAppGoogleAnalyticsData = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.webSettings?.gaId
);

export const selectAppGoogleAnalyticsActivnessStatus = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.webSettings?.gaId !== undefined
);

export const selectAppGoogleTagManagersData = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.webSettings.gtmId
);

export const selectAppGoogleTagManagersActivnessStatus = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.webSettings?.gtmId !== undefined
);

export const selectAppWebhookActivness = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.webSettings.webHook
);

export const selectAppGuestShoppingActivness = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.webSettings.guestShopping
);

export const selectCurrentAppIsAppSumoApp = createSelector(
  STORES_STATE,
  ({ currentApp }) => currentApp?.appDetails.isAppsumo
);

export const selectPrimitiveStoreData = createSelector(
  STORES_STATE,
  ({ primitiveData }) => primitiveData
);
