import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'orderNote',
  standalone: true
})
export class OrderNotePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, args?: any): string {
    const noNotesMsg = this.translateService.instant('ORDERS.NO_NOTES');
    if (value == undefined || value?.trim() == '') return noNotesMsg;
    else return value;
  }
}
