import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { END_POINTS_URL } from '@pratik-islem/shared/data';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private readonly _addressesAPI_URL = END_POINTS_URL.V1.ADDRESSES_URL;

  constructor(private http: HttpClient) {}

  getCountriesList(): Observable<any[]> {
    const URL = `${this._addressesAPI_URL}/countries`;
    return this.http.get<any[]>(URL);
  }

  getCityListOfCountry(countryId: string): Observable<any[]> {
    const URL = `${this._addressesAPI_URL}/states?countryId=${countryId}`;
    return this.http.get<any[]>(URL);
  }

  getRegionListOfState(stateId: string): Observable<any[]> {
    const URL = `${this._addressesAPI_URL}/cities?stateId=${stateId}`;
    return this.http.get<any[]>(URL);
  }
}
