export class NumberUtil {
  static isNumeric(value): boolean {
    return /^-?\d+$/.test(value);
  }

  static formatNumberWithCommas(x): string {
    try {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } catch (error) {
      return '0';
    }
  }
}
