<form
  class="form container pl-0 pr-0"
  [formGroup]="phoneNumberForm"
  novalidate="novalidate"
  *ngIf="phoneNumberForm"
  id="phone-number-form"
  #phoneNumberFormRef
>
  <div class="phone-code-container" (outsideClick)="hideMenu()" outsideClick>
    <div class="phone-code-input-container">
      <div
        class="d-flex justify-content-center rounded-lg-left phone-code-input-holder-container"
      >
        <div
          class="rounded-lg-left h-100 justify-content-center align-self-center phone-code-input-items-container"
          [ngClass]="{
            'menu-closed': phoneCodeDisabled,
            'menu-opened': !phoneCodeDisabled,
            'disabled-element': isDisplayMode
          }"
        >
          <span
            #countryFlagImage
            class="flag-icon phone-code-flag"
            [ngClass]="
              selectedCountryPhoneCodeData
                ? 'flag-icon-' + selectedCountryPhoneCodeData.value.isO2
                : 'flag-icon-tr'
            "
            (click)="showPhoneCode($event)"
          >
          </span>
          <button
            type="button"
            class="phone-code-button"
            (click)="showPhoneCode($event)"
          >
            <i class="pi pi-angle-down phone-code-button-icon"></i>
          </button>
        </div>
      </div>
      <div class="phone-code-input-item">
        <p-inputMask
          inputId="phone-number-input"
          [mask]="phoneCodeMask"
          [placeholder]="phoneCodePlaceHolder"
          name="phoneNumber"
          formControlName="phoneNumber"
          [autoClear]="false"
          styleClass="
                form-control
                rounded-lg-right
              "
          [ngClass]="{
            'is-invalid': !phoneNumberIsValid
          }"
        >
        </p-inputMask>
      </div>
    </div>
    <div class="phone-code-list-container">
      <p-listbox
        formControlName="phoneNumberCode"
        [options]="countriesPhoneCodeData"
        [multiple]="false"
        optionLabel="label"
        [filter]="true"
        filterBy="value"
        (onClick)="phoneCodeClicked($event)"
        (onChange)="phoneCodeChange($event)"
        [listStyleClass]="'phone-code-list-box'"
        [ngClass]="phoneCodeDisabled ? 'hide-menu' : 'over-menu'"
      >
        <ng-template let-country pTemplate="item">
          <div>
            <div>
              <span
                class="flag-icon flag-icon-{{
                  country.value.isO2
                }} phone-code-list-box-item"
                (click)="showPhoneCode($event)"
              >
              </span>

              {{ country.label }}
            </div>
          </div>
        </ng-template>
      </p-listbox>
    </div>
  </div>
</form>

<ng-container
  [ngTemplateOutlet]="formError"
  [ngTemplateOutletContext]="{
    validation: 'wrongNumber',
    message: 'SHARED.VALIDATION.PHONE_IS_INVALID',
    control: phoneNumberForm.controls['phoneNumber']
  }"
></ng-container>

<ng-template
  #formError
  let-control="control"
  let-message="message"
  let-validation="validation"
>
  <ng-container
    *ngIf="
      control.hasError(validation) &&
      (control.dirty || control.touched) &&
      showValidationMessage == true
    "
  >
    <small class="p-error mt-3 d-block">{{ message | translate }}</small>
  </ng-container>
</ng-template>
