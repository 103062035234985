import { Routes } from '@angular/router';
import { LoginComponent } from '../features/login/login.component';

export const AUTH_ROUTES: Routes = [
  {
    path: '',
    loadComponent: async () =>
      (await import('../ui/template/template.component')).AuthTemplateComponent,
    children: [
      {
        path: 'login',
        loadComponent: async () =>
          (await import('../features/login/login.component')).LoginComponent,
        data: { returnUrl: window.location.pathname }
      },
      {
        path: 'registration/:email',
        loadComponent: async () =>
          (await import('../features/registration/registration.component'))
            .RegistrationComponent
      },
      {
        path: 'registration',
        loadComponent: async () =>
          (await import('../features/registration/registration.component'))
            .RegistrationComponent
      },
      {
        path: 'forgot-password',
        loadComponent: async () =>
          (
            await import(
              '../features/forgot-password/forgot-password.component'
            )
          ).ForgotPasswordComponent
      },
      {
        path: 'logout',
        loadComponent: async () =>
          (await import('../features/logout/logout.component')).LogoutComponent
      },
      {
        path: 'confirm-email/:token/:email',
        loadComponent: async () =>
          (
            await import(
              '../features/email-confirmation/email-confirmation.component'
            )
          ).EmailConfirmationComponent
      },
      {
        path: 'reset-password/:token/:email',
        loadComponent: async () =>
          (await import('../features/reset-password/reset-password.component'))
            .ResetPasswordComponent
      },
      // {
      //   path: '',
      //   redirectTo: 'login',
      //   pathMatch: 'full'
      // },
      { path: '**', redirectTo: 'login', pathMatch: 'full' }
    ]
  }
];
