import { Routes } from '@angular/router';

export const DASHBOARD_ROUTES: Routes = [
  {
    path: 'shell',
    loadComponent: async () =>
      (await import('../features/shell/shell.component')).ShellComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'shell'
  }
];
