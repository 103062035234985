import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CargoDimensionsComponent } from '../cargo-dimensions/cargo-dimensions.component';
import { CargoOffersComponent } from '../cargo-offers/cargo-offers.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import {
  GENERAL_VALUES,
  DIALOG_RESULT,
  DIALOG_STYLE,
  DialogResultModel
} from '@pratik-islem/shared/data';

@Component({
  selector: 'order-get-cargo-offer',
  templateUrl: './get-cargo-offer.component.html',
  styleUrls: ['./get-cargo-offer.component.scss'],
  providers: [DialogService],
  standalone: true,
  imports: [CommonModule, TranslateModule, ToastModule]
})
export class GetCargoOfferComponent implements OnInit {
  @Input()
  orderInfo: any;

  constructor(
    private _router: Router,
    private _dialogService: DialogService,
    private _messageService: MessageService,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  showCargoDimentionDialog(): void {
    this._dialogService
      .open(CargoDimensionsComponent, {
        header: this._translateService.instant('ORDERS.CARGO_DIMENSION'),
        data: this.orderInfo,
        styleClass: DIALOG_STYLE.SMALL_DIALOG
      })
      .onClose.subscribe(
        (cargoDimensionsOperationResult: DialogResultModel) => {
          if (
            cargoDimensionsOperationResult &&
            cargoDimensionsOperationResult.resultType ==
              DIALOG_RESULT.ACTION_HAPPENED
          ) {
            this._dialogService
              .open(CargoOffersComponent, {
                header: this._translateService.instant('ORDERS.CARGO_OFFERS'),
                data: {
                  orderId: this.orderInfo.id,
                  offers: cargoDimensionsOperationResult.result.offers
                },
                styleClass: DIALOG_STYLE.SMALL_DIALOG
              })
              .onClose.subscribe(
                (getOfferOperationResult: DialogResultModel) => {
                  if (
                    getOfferOperationResult &&
                    getOfferOperationResult.resultType ==
                      DIALOG_RESULT.ACTION_HAPPENED
                  ) {
                    this._messageService.add({
                      severity: 'success',
                      summary: this._translateService.instant(
                        'SHARED.MESSAGES.SUCCESS'
                      ),
                      detail: this._translateService.instant(
                        'ORDERS.OFFER_ACCEPTED_SUCCESSFULLY'
                      )
                    });
                    timer(GENERAL_VALUES.REDIRECTION_DELAY).subscribe((_) => {
                      this._router
                        .navigateByUrl('/orders')
                        .then((_) =>
                          this._router.navigateByUrl(
                            `/store/orders/order-detail/${this.orderInfo.id}`
                          )
                        );
                    });
                  }
                }
              );
          }
        }
      );
  }
}
