import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/internal/operators/map';
import { Injectable } from '@angular/core';

import { StoreActionsMessageTypesList } from './store-actions-message.model';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  addNewLanguageFailure,
  addNewLanguageSuccess,
  changeDefaultLanguageOfAppFailure,
  changeDefaultLanguageOfAppSuccess,
  createStoreFailure,
  deleteAppLanguageFailure,
  deleteAppLanguageSuccess
} from '@pratik-islem/stores';
import {
  changeActivityOfSaleChannelFailure,
  changeActivityOfSaleChannelSuccess,
  updateSaleChannelFailure,
  updateSaleChannelSuccess
} from '@pratik-islem/store/sales-channel';

@Injectable({
  providedIn: 'root'
})
export class StoreActionsResultMessageEffect {
  private readonly _actionsForShowingMessage = [
    addNewLanguageFailure,
    addNewLanguageSuccess,
    deleteAppLanguageSuccess,
    deleteAppLanguageFailure,
    changeDefaultLanguageOfAppSuccess,
    changeDefaultLanguageOfAppFailure,
    changeActivityOfSaleChannelSuccess,
    changeActivityOfSaleChannelFailure,
    updateSaleChannelSuccess,
    updateSaleChannelFailure,
    createStoreFailure
  ];

  /**
   * Result actions are those actions that manage the result of an action, let's suppose we have an action called DeleteWebhook,
   * the possible results would be DeleteWebHookSuccess and DeleteWebHookFailure and when these actions are dispatched somewhere
   * in the project we are supposed to react to them, show a message to the user,
   * etc. with this effect we are going to listen to these types of actions and show a message to users
   */
  public listenToAllResultActions$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(...this._actionsForShowingMessage),
        map(({ payload }) => {
          if (payload.result.message == '') {
            return;
          }
          this.messageService.add({
            severity:
              payload.result.messageType == StoreActionsMessageTypesList.Success
                ? 'success'
                : 'error',
            summary:
              payload.result.messageType == StoreActionsMessageTypesList.Success
                ? this._translateService.instant('SHARED.MESSAGES.SUCCESS')
                : this._translateService.instant('SHARED.MESSAGES.ERROR'),
            detail: this._translateService.instant(payload.result.message)
          });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    public messageService: MessageService,
    private _actions$: Actions,
    private _translateService: TranslateService
  ) {}
}
