<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{ 'ORDERS.TIMELINE' | translate }}
      </h3>
    </div>
  </div>
  <div class="card-body">
    <div class="timeline timeline-6" *ngFor="let status of orderStatusHistory">
      <p class="timeline-header">
        {{ status.date }}
      </p>
      <div
        class="timeline-item align-items-start"
        *ngFor="let record of status.records"
      >
        <div
          class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
        >
          {{ record.time }}
        </div>
        <div class="timeline-badge">
          <fa-icon
            [icon]="faGenderless"
            appTimeLineRecordColor
            [id]="record.status"
            size="2x"
          ></fa-icon>
        </div>
        <div
          class="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
        >
          {{ record.status | orderStatusWithDescriptionTranslator }}
        </div>
      </div>
    </div>
  </div>
</div>
