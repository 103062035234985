/* eslint-disable no-unused-vars */
export interface IUploadImageModel {
  absolutePath: string;
  absoluteUri: string;
  id?: string;
}
export class UploadImageModel implements IUploadImageModel {
  constructor(
    public absolutePath: string,
    public absoluteUri: string,
    public id?: string
  ) {}
}

export class UploadedImage {
  constructor(public url: string, public id?: string) {}
}
