<div class="row" id="empty-content">
  <div class="col-12">
    <div class="gutter-b">
      <div class="d-flex flex-column align-items-center">
        <img src="../../../../assets/media/bg/page-not-found.svg" />
        <p data-cy="empty-content-component-text" class="text-center text-muted">{{ message }}</p>
      </div>
    </div>
  </div>
</div>
