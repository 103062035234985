import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import {
  selectCurrentAppDefaultLanguage,
  selectCurrentAppSupportedLanguageList
} from '@pratik-islem/stores';

import { AppSettingService } from '@pratik-islem/core';
import { AutoUnsubscribe } from '@pratik-islem/shared/decorators';
import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { CommonModule } from '@angular/common';
import { LanguageService } from '@pratik-islem/store/store-settings';

@AutoUnsubscribe()
@Component({
  selector: 'i18n-languages-drop-down',
  templateUrl: './languages-drop-down.component.html',
  styleUrls: ['./languages-drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule]
})
export class LanguagesDropDownComponent implements OnInit {
  public currentAppLangs: any[] = [];
  public defaultLangIso2 = '';
  private subs = new SubSink();
  private appId = '';
  @Output() languageSelectionChanged = new EventEmitter<any>();
  @Output() langsListFetched = new EventEmitter<any>();

  constructor(
    private store: Store,
    private LanguageService: LanguageService,
    private appSettingService: AppSettingService,
    private cdr: ChangeDetectorRef
  ) {
    this.appId = this.appSettingService.getCurrentAppId();
  }

  ngOnInit(): void {
    const defaultApp$ = this.store.select(selectCurrentAppDefaultLanguage);
    const languageListOfCurrentApp$ = this.store.select(
      selectCurrentAppSupportedLanguageList
    );

    this.subs.sink = forkJoin([
      defaultApp$,
      languageListOfCurrentApp$
    ]).subscribe((result) => {
      this.defaultLangIso2 = result[0]?.isO2;
      this.currentAppLangs = result[1];
      this.langsListFetched.emit(result[1].data);
      this.cdr.detectChanges();
    });
  }

  public handleLanguageSelectionChange(event: any) {
    const selectedLanguage = this.currentAppLangs.find(
      (x) => x.isO2 == event.target.value
    );
    this.languageSelectionChanged.emit(selectedLanguage);
  }
}
