import * as Sentry from '@sentry/angular';

import { APP_INITIALIZER, ErrorHandler } from "@angular/core";

import { Router } from '@angular/router';

export const SENTRY_ERROR_APP_INITIALIZER_PROVIDER = [
    {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
            showDialog: false,
        }),
    }
];

export const SENTRY_TRACE_SERVICE_APP_INITIALIZER_PROVIDER = [
    {
        provide: Sentry.TraceService,
        deps: [Router],
    }
];

export const SENTRY_APP_INITIALIZER_PROVIDER = [
    {
        provide: APP_INITIALIZER,
        useFactory: () => () => { },
        deps: [Sentry.TraceService],
        multi: true,
    }
];

