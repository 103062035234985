import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { OrderService } from '../../data-access';
import { OrderStatusList } from '../../data';
import { BaseComponent } from '@pratik-islem/shared/ui';
import { SubSink } from 'subsink';
import { ObjectUtil } from '@pratik-islem/shared/utils';
import { finalize } from 'rxjs/internal/operators/finalize';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropdownModule } from 'primeng/dropdown';
@Component({
  selector: 'order-status-tracking-form',
  templateUrl: './order-status-tracking-form.component.html',
  styleUrls: ['./order-status-tracking-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    DropdownModule
  ]
})
export class OrderStatusTrackingFormComponent
  extends BaseComponent
  implements OnInit
{
  @Input()
  public orderInfo: any;
  public manageStatusTrackingForm!: FormGroup;
  public orderStatusList = OrderStatusList;
  public selectedStatus: any;
  public faInfo = faInfoCircle;

  @Output()
  public loadingStatusChange = new EventEmitter<boolean>();

  private subs = new SubSink();

  constructor(
    public override injector: Injector,
    private fb: FormBuilder,
    private orderService: OrderService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._buildForm();
    this._setValueOfFormElements();
  }

  public get formIsValid(): boolean {
    const selectedStatus =
      this.manageStatusTrackingForm.controls['orderStatus'].value;
    const shippingTrackingCode =
      this.manageStatusTrackingForm.controls[
        'shippingTrackingCode'
      ].value?.trim();
    const shippingTrackingCompany =
      this.manageStatusTrackingForm.controls[
        'shippingTrackingCompany'
      ].value?.trim();

    if (
      (selectedStatus?.type == 'Shipped' ||
        selectedStatus?.type == 'Completed') &&
      (!shippingTrackingCode || !shippingTrackingCompany)
    ) {
      return false;
    }
    return true;
  }

  public get shippingInfoAdded(): boolean {
    return (
      this.manageStatusTrackingForm.controls[
        'shippingTrackingCode'
      ].value?.trim().length > 0 &&
      this.manageStatusTrackingForm.controls[
        'shippingTrackingCompany'
      ].value?.trim().length > 0
    );
  }

  public handleOrderStatusChange(event: any): void {
    this.selectedStatus = event.value;
    this.manageStatusTrackingForm.controls['orderStatus'].setValue(event.value);
    this.manageStatusTrackingForm.controls['orderStatus'].markAsDirty();
  }

  public submit(completeOrder = false): void {
    if (this.manageStatusTrackingForm.pristine && !this.formIsValid) {
      return;
    }
    if (completeOrder) {
      const completedStatus = this.orderStatusList.find(
        (x) => x.type == 'Completed'
      );
      this.selectedStatus = completedStatus;
      this.manageStatusTrackingForm.controls['orderStatus'].setValue(
        completedStatus
      );
    }

    const updateOrderData = {
      id: this.orderInfo.id,
      shippingTrackingCode:
        this._manageStatusTrackingFormControls.shippingTrackingCode.value,
      shippingTrackingCompany:
        this._manageStatusTrackingFormControls.shippingTrackingCompany.value,
      newOrderStatus:
        this._manageStatusTrackingFormControls.orderStatus.value.type,
      doesMailSend: true
    };

    this.loadingStatusChange.emit(true);
    this.subs.sink = this.orderService
      .updateOrder(this.appId, updateOrderData)
      .pipe(finalize(() => this.loadingStatusChange.emit(false)))
      .subscribe(
        (result) => {
          this.showSuccessMessage('SHARED.MESSAGES.FULFILLED_SUCCESSFULLY');
        },
        (error) => {
          let errMsg = this.translate('SHARED.MESSAGES.REQUEST_FAILED');
          if (
            error?.error?.key == 'cantSetStatus' ||
            error?.error?.key == 'OrderCantBeChange'
          ) {
            errMsg = this.translate('ORDERS.CANT_CHANGE_ORDER_STATUS');
          }
          this.showErrorMessage(errMsg);
        }
      );
  }

  private _buildForm(): void {
    this.manageStatusTrackingForm = this.fb.group({
      orderStatus: null,
      sendEmail: false,
      shippingTrackingCode: [''],
      shippingTrackingCompany: ['']
    });
  }

  private _setValueOfFormElements(): void {
    this._manageStatusTrackingFormControls.shippingTrackingCompany.setValue(
      this.orderInfo.shippingTrackingCompany
    );
    this._manageStatusTrackingFormControls.shippingTrackingCode.setValue(
      this.orderInfo.shippingTrackingCode
    );
    const latestFieldOfStatusHistory = ObjectUtil.getLatestFieldOfObject(
      this.orderInfo.statusHistory
    );
    const previouslySelectedOrderType =
      this.orderInfo.statusHistory[latestFieldOfStatusHistory];
    const previouslySelectedOrder = this.orderStatusList.find(
      (x) => x.type == previouslySelectedOrderType
    );
    this._manageStatusTrackingFormControls.orderStatus.setValue(
      previouslySelectedOrder
    );
  }

  private _makeFormAsPristine(): void {
    (<any>Object)
      .values(this._manageStatusTrackingFormControls)
      .forEach((control: any) => {
        control.markAsPristine();
      });
  }

  private get _manageStatusTrackingFormControls(): any {
    return this.manageStatusTrackingForm.controls;
  }
}
