<app-animated-logo *ngIf="isLoading == true"></app-animated-logo>
<p-fileUpload
  #imageUploader
  [multiple]="isMultipleImageUploader"
  [maxFileSize]="4000000"
  [chooseLabel]="'SHARED.FILE_UPLOADER.CHOOSE_LABEL' | translate"
  [cancelLabel]="'SHARED.FILE_UPLOADER.CANCEL_LABEL' | translate"
  [uploadLabel]="'SHARED.FILE_UPLOADER.UPLOAD_LABEL' | translate"
  invalidFileTypeMessageSummary=""
  [invalidFileTypeMessageDetail]="'SHARED.FILE_UPLOADER.VALID_TYPE' | translate"
  invalidFileSizeMessageSummary=""
  [invalidFileSizeMessageDetail]="'SHARED.FILE_UPLOADER.MAX_SIZE' | translate"
  [accept]="validImageTypes"
  [customUpload]="true"
  (uploadHandler)="uploadImage($event)"
  (onClear)="handleClearEvent($event)"
  (onRemove)="handleRemoveEvent($event)"
  (onSelect)="handleSelectEvent($event)"
  mode="advanced"
  [showCancelButton]="showCancelButton"
  [showUploadButton]="showUploadButton"
  [auto]="isAutoUpload"
  id="image-uploader"
>
  <ng-template let-file pTemplate="file">
    <div
      class="p-fileupload-row"
      [ngClass]="
        isMultipleImageUploader == false && uploadedFiles.length > 0
          ? 'shadow'
          : ''
      "
      *ngIf="
        isMultipleImageUploader == false ||
        (uploadedFiles.length > 0 &&
          currentFiles.length > 0 &&
          currentFiles[0].name != uploadedFiles[0].name)
      "
    >
      <div>
        <img
          [src]="sanitize(file.objectURL.changingThisBreaksApplicationSecurity)"
          [width]="imageWidth"
          [height]="imageHeight"
        />
      </div>
      <div>{{ file.name }}</div>
      <div>{{ (file.size / 1024).toPrecision(4) }} KB</div>
      <div>
        <button
          type="button"
          icon="pi pi-times"
          class="p-button p-component p-button-icon-only"
          [id]="getImageId(file)"
          (click)="removeImage($event, file)"
        >
          <span
            class="p-button-icon pi pi-times"
            aria-hidden="true"
            [id]="getImageId(file)"
            (click)="removeImage($event, file)"
          ></span
          ><span aria-hidden="true" class="p-button-label delete-icon"
            >&nbsp;</span
          >
        </button>
      </div>
    </div>
    <div *ngIf="isMultipleImageUploader == true" class="p-fileupload-row">
      <div>
        <img
          [src]="sanitize(file.objectURL.changingThisBreaksApplicationSecurity)"
          [width]="imageWidth"
          [height]="imageHeight"
        />
      </div>
      <div>{{ file.name }}</div>
      <div>{{ (file.size / 1024).toPrecision(4) }} KB</div>
      <div>
        <button
          type="button"
          icon="pi pi-times"
          class="p-button p-component p-button-icon-only"
          [id]="getImageId(file)"
          (click)="removeImage($event, file)"
        >
          <span
            class="p-button-icon pi pi-times"
            aria-hidden="true"
            [id]="getImageId(file)"
            (click)="removeImage($event, file)"
          ></span
          ><span aria-hidden="true" class="p-button-label delete-icon"
            >&nbsp;</span
          >
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content" let-files>
    <ng-container *ngIf="files.length == 0">
      <div
        class="file-uploader__content"
        *ngIf="
          previouslyUploadedImageURL?.length == 0 &&
          previouslyUploadedImagesURL?.length == 0 &&
          uploadedFiles?.length == 0
        "
      >
        <img src="../../../../../assets/media/images/file-uploader.svg" />
        <p class="max-file__size">
          {{ 'SHARED.FILE_UPLOADER.MAX_SIZE' | translate }}
          <br />
          <span *ngIf="isMultipleImageUploader == true">
            {{ 'SHARED.FILE_UPLOADER.FEATURE_IMAGE' | translate }}</span
          >
        </p>
      </div>
      <ng-container
        *ngIf="
          isMultipleImageUploader == false &&
          previouslyUploadedImageURL.length > 0
        "
      >
        <div>
          <img [src]="previouslyUploadedImageURL" class="preview__img" />
          <button
            type="button"
            icon="pi pi-times"
            class="p-button p-component p-button-icon-only delete_preview__img_btn"
            (click)="deleteUploadedImage('')"
          >
            <span class="p-button-icon pi pi-times" aria-hidden="true"></span
            ><span aria-hidden="true" class="p-button-label delete-icon"
              >&nbsp;</span
            >
          </button>
        </div>
      </ng-container>
    </ng-container>

    <ng-container
      *ngIf="
        isMultipleImageUploader == true &&
        previouslyUploadedImagesURL.length > 0
      "
    >
      <div class="row previous-images-container">
        <ng-container *ngFor="let imageURL of previouslyUploadedImagesURL">
          <div
            class="d-flex justify-content-between align-items-center m-3 mb-3 p-fileupload-row margin-previous-items"
            [ngClass]="
              previouslyFeaturedImageURL.length > 0 &&
              previouslyFeaturedImageURL == imageURL
                ? 'shadow'
                : ''
            "
            id="{{ imageURL }}"
          >
            <div>
              <img [src]="imageURL" class="preview__img" />
            </div>
            <div>
              <button
                (click)="deleteUploadedImage(imageURL)"
                type="button"
                icon="pi pi-times"
                class="p-button p-component p-button-icon-only delete_previous_image"
              >
                <span class="p-button-icon pi pi-times"> </span
                ><span class="p-button-label delete-icon">&nbsp;</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
</p-fileUpload>
