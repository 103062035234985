import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UpdateUserProfileModel,
  UserProfileService,
  loadUserProfileInformation,
  selectUserProfileInformation
} from '@pratik-islem/user-profile';
import { AppSettingService } from '@pratik-islem/core';
import { LayoutService } from 'src/app/shared/@metronic/core';
import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';
import { concatMap } from 'rxjs/internal/operators/concatMap';
import { of } from 'rxjs/internal/observable/of';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { LOCAL_STORAGE_KEYS } from '@pratik-islem/shared/data';

@Component({
  selector: 'app-stores',
  template: '<router-outlet></router-outlet>',
  standalone: true,
  imports: [CommonModule, RouterOutlet]
})
export class StoresShellComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  constructor(
    private layout: LayoutService,
    private appSettingService: AppSettingService,
    private userProfileService: UserProfileService,
    private translateService: TranslateService,
    private readonly _store: Store
  ) {
    this.appSettingService.setSettingsPartially('showAsideMenu', false);
    this.layout.setAsideMenuDisplayMode(false);
    this._store.dispatch(loadUserProfileInformation());
  }

  ngOnInit(): void {
    this.toggleNormalBgColorHeader(true);
    const defaultLanguage = this.getDefaultLanguage();
    if (defaultLanguage.baseBrowserLanguage) {
      this.subs.sink = this._store
        .select(selectUserProfileInformation)
        .pipe(
          concatMap((userInfo) => {
            if (!userInfo) return of(null);
            const updatedUser: UpdateUserProfileModel = {
              language: defaultLanguage.language,
              fullname: userInfo.fullName
            };
            return this.userProfileService.updateUserProfile(updatedUser);
          })
        )
        .subscribe((result) => {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_LANGUAGE,
            defaultLanguage.language
          );
        });
    }
    this.translateService.setDefaultLang(defaultLanguage.language);
  }

  ngOnDestroy(): void {
    this.toggleNormalBgColorHeader(false);
  }

  getDefaultLanguage(): { language: string; baseBrowserLanguage: boolean } {
    const userLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE);
    if (userLanguage != null) {
      return {
        language: userLanguage,
        baseBrowserLanguage: false
      };
    } else {
      const browserLanguage =
        navigator.language == undefined
          ? 'tr'
          : navigator.language.toLowerCase().substring(0, 2);
      let browserLanguages =
        navigator.languages === undefined ? ['tr'] : navigator.languages;
      browserLanguages = browserLanguages.map((language) =>
        language.toLowerCase().substring(0, 2)
      );
      let defaultLanguage = browserLanguages.some((x) => x == 'tr')
        ? 'tr'
        : 'en';
      if (defaultLanguage != 'tr' && browserLanguage == 'tr') {
        defaultLanguage = 'tr';
      }
      return {
        language: defaultLanguage,
        baseBrowserLanguage: true
      };
    }
  }

  toggleNormalBgColorHeader(shouldSetToWhite = false) {
    const kt_header = document.getElementById('kt_header');
    if (kt_header && shouldSetToWhite) {
      kt_header.classList.remove('normal-bg-kt-header');
    } else if (kt_header && !shouldSetToWhite) {
      kt_header.classList.add('normal-bg-kt-header');
    }
  }
}
