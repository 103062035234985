import { Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  template: ''
})
export abstract class SmartComponent implements OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  private readonly subClassNgOnDestroy: Function;

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor() {
    this.subClassNgOnDestroy = this.ngOnDestroy;
    this.ngOnDestroy = (): void => {
      this.subClassNgOnDestroy();
      this.unsunscribe();
    };
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnDestroy(): void {}

  protected untilComponentDestroy(): any {
    return takeUntil(this.unsubscribe$);
  }

  private unsunscribe(): void {
    if (this.unsubscribe$.isStopped) {
      return;
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
