import { IntegrationStepType } from '../../data/enums/integration-step-type.enum';

export class IntegrationStepResponseDto {
  type: IntegrationStepType;

  name: string;

  description: string;

  logo: string;

  url: string;

  isCompleted: boolean;
}
