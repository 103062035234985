import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input('text') text: string;
  @Input('isBigSymbol') isBigSymbol: boolean = false;

  formattedLabel = '';

  constructor() {}

  ngOnInit(): void {
    this.formattedLabel = this.text?.substr(0, 2).toUpperCase();
  }
}
