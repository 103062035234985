import * as orderActions from './order.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import { selectAllOrdersData, selectLastOrdersData } from './order.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderService } from '../services';
import { allOrdersResponseModel, lastOrdersResponseModel } from '../../data/models';

@Injectable({
  providedIn: 'root'
})
export class OrderFeatureEffects {
  LoadLastOrdersData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(orderActions.LoadLastOrders),
      withLatestFrom(this._store.select(selectLastOrdersData)),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const previousAppId = actions[1].appId;
        if (currentAppId == previousAppId) {
          // returns the previously fetched data
          const allOrders: allOrdersResponseModel = {
            appId: previousAppId,
            OrdersData: actions[1].lastOrders ?? {
              data: [],
              meta: {
                itemsCount: 0,
                queryCount: 0,
                totalCount: 0
              }
            }
          };
          return of(
            orderActions.LoadLastOrdersSuccess({
              response: allOrders
            })
          );
        } else {
          const allOrders$ = this._orderService.filterOrders(
            actions[0].appId,
            '',
            1,
            10,
            'Descending',
            'CreationDate'
          );

          return allOrders$.pipe(
            map(
              (response: any) => {
                const allOrdersData: lastOrdersResponseModel = {
                  appId: currentAppId,
                  OrdersData: response.data
                };
                return orderActions.LoadLastOrdersSuccess({
                  response: allOrdersData
                });
              },
              catchError(() => EMPTY)
            )
          );
        }
      })
    )
  );

  LoadAllOrdersData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(orderActions.LoadAllOrders),
      withLatestFrom(this._store.select(selectAllOrdersData)),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const previousAppId = actions[1].appId;
        if (currentAppId == previousAppId) {
          // returns the previously fetched data
          const allOrders: allOrdersResponseModel = {
            appId: previousAppId,
            OrdersData: actions[1].allOrders ?? {
              data: [],
              meta: {
                itemsCount: 0,
                queryCount: 0,
                totalCount: 0
              }
            }
          };
          return of(
            orderActions.LoadAllOrdersSuccess({
              response: allOrders
            })
          );
        } else {
          const allOrders$ = this._orderService.filterOrders(
            actions[0].appId,
            '',
            1,
            10,
            'Descending',
            'CreationDate'
          );

          return allOrders$.pipe(
            map(
              (response: any) => {
                const allOrdersData: allOrdersResponseModel = {
                  appId: currentAppId,
                  OrdersData: response.data
                };
                return orderActions.LoadAllOrdersSuccess({
                  response: allOrdersData
                });
              },
              catchError(() => EMPTY)
            )
          );
        }
      })
    )
  );

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _orderService: OrderService
  ) {}
}
