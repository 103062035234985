export type StoreActionsMessageTypes = 'Success' | 'Error';
export enum StoreActionsMessageTypesList {
  'Success',
  'Error'
}

export interface StoreActionResultModel {
  message: string;
  messageType: StoreActionsMessageTypesList;
}
