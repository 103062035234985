import { Pipe, PipeTransform } from '@angular/core';

import { environment } from 'src/environments/environment';

@Pipe({
  name: 'slugLink',
  standalone: true
})
export class SlugLinkPipe implements PipeTransform {
  transform(slug: string): string {
    const pratik_islem_base_url = environment.pratik_islem_base_url;
    return slug.startsWith(`${pratik_islem_base_url}/`)
      ? slug
      : pratik_islem_base_url + '/' + slug;
  }
}
