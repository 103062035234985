export interface UserProfileModel {
  email: string;
  fullName: string;
  language?: string;
  phoneNumber?: string;
}

export interface UpdateUserProfileModel {
  fullname: string;
  language: string;
}

export interface GetUserInformationModel{
  email:string
}

export interface UserProfileFullInformationModel {
  accessFailedCount: number;
  concurrencyStamp: string;
  createdAt: string;
  editedAt: string;
  email: string;
  emailConfirmed: boolean;
  id: string;
  isDeleted: boolean;
  lastChangePasswordDate: string;
  lockoutEnabled: boolean;
  lockoutEnd: string;
  normalizedEmail: string;
  normalizedUserName: string;
  passwordHash: string;
  phoneNumber?:string;
  phoneNumberConfirmed: boolean;
  securityStamp: string;
  twoFactorEnabled: boolean;
  userApiKey: string;
  userName: string;
}
