import { RouterModule, Routes, UrlSegment } from '@angular/router';

import { AuthGuard } from '../modules/auth/utils/guards/auth.guard';
import { NgModule } from '@angular/core';

export const APP_ROUTES: Routes = [
  {
    matcher: (url): any => {
      // eslint-disable-next-line no-undef
      const pureURL = window.location.href;
      const currentURL = new URL(pureURL);
      const token = currentURL.searchParams.get('token');
      const email = currentURL.searchParams.get('email');
      if (
        token &&
        email &&
        (pureURL.indexOf('change-password?token=') != -1 ||
          pureURL.indexOf('auth/reset-password') != -1)
      ) {
        return {
          consumed: url,
          posParams: {
            token: new UrlSegment(token, {}),
            email: new UrlSegment(email, {})
          }
        };
      }

      return null;
    },
    redirectTo: 'auth/reset-password/:token/:email'
  },
  {
    matcher: (url): any => {
      // eslint-disable-next-line no-undef
      const pureURL = window.location.href;
      const currentURL = new URL(pureURL);
      const token = currentURL.searchParams.get('token');
      const email = currentURL.searchParams.get('email');
      if (
        token &&
        email &&
        (pureURL.indexOf('auth/confirm-email') != -1 ||
          pureURL.indexOf('?token=') != -1)
      ) {
        return {
          consumed: url,
          posParams: {
            token: new UrlSegment(token, {}),
            email: new UrlSegment(email, {})
          }
        };
      }

      return null;
    },
    redirectTo: 'auth/confirm-email/:token/:email'
  },
  {
    matcher: (url): any => {
      // eslint-disable-next-line no-undef
      const pureURL = window.location.href;
      const currentURL = new URL(pureURL);
      const pathName = currentURL.pathname;
      const email = currentURL.searchParams.get('value');
      if (pathName == '/invitations-confirm' && email) {
        return {
          consumed: url,
          posParams: {
            email: new UrlSegment(email, {})
          }
        };
      }

      return null;
    },
    redirectTo: 'auth/registration/:email'
  },
  {
    path: 'auth',
    loadChildren: (): any =>
      import('../modules/auth/routes/auth.routes').then((m) => m.AUTH_ROUTES)
  },
  {
    path: 'error',
    loadChildren: (): any =>
      import('@pratik-islem/misc/error').then((m) => m.ErrorsModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: (): any =>
      import('../shared/_layout/layout.module').then((m) => m.LayoutModule)
  },
  { path: '**', redirectTo: 'error/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
