import { StoresFeatureKey, StoresFeatureReducer } from '@pratik-islem/stores';
import {
  dashboardFeatureKey,
  dashboardFeatureReducer
} from '@pratik-islem/store/dashboard';
import {
  orderFeatureKey,
  orderFeatureReducer
} from '@pratik-islem/store/orders';
import {
  salesChannelFeatureKey,
  salesChannelFeatureReducer
} from '@pratik-islem/store/sales-channel';
import {
  userProfileFeatureKey,
  userProfileFeatureReducer
} from '@pratik-islem/user-profile';
import { webHookFeatureKey, webHookFeatureReducer } from '@pratik-islem/store/webhook';

export const APP_REDUCERS = {
  [userProfileFeatureKey]: userProfileFeatureReducer,
  [StoresFeatureKey]: StoresFeatureReducer,
  [dashboardFeatureKey]: dashboardFeatureReducer,
  [salesChannelFeatureKey]: salesChannelFeatureReducer,
  [orderFeatureKey]: orderFeatureReducer,
  [webHookFeatureKey]: webHookFeatureReducer,
};
