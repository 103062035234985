import { AccessTokenInterceptor } from "../interceptors/access-token.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

export const ACCESS_TOKEN_INTERCEPTOR_APP_INITIALIZER_PROVIDER = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AccessTokenInterceptor,
        multi: true,
    }
];
