import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from '@pratik-islem/shared/ui';

@Component({
  selector: 'order-ui-cargo-information',
  templateUrl: './cargo-information.component.html',
  styleUrls: ['./cargo-information.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, AlertModule, CurrencyPipe]
})
export class CargoInformationComponent {
  @Input()
  cargoPrice;

  @Input()
  barcode;

  @Input()
  shippingTrackingUrl;

  @Input()
  defaultLocale;

  @Input()
  currency;
}
