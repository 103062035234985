import { Routes } from '@angular/router';

export const SHOPILINK_SALE_CHANNEL_ROUTES: Routes = [
  {
    path: 'index',
    loadComponent: async () =>
      (await import('../features/shopilink-index/shopilink-index.component'))
        .ShopilinkIndexComponent
  },
  {
    path: 'edit/:shopilinkId',
    loadComponent: async () =>
      (
        await import(
          '../features/shopilink-management/shopilink-management.component'
        )
      ).ShopilinkManagementComponent
  },
  {
    path: 'add',
    loadComponent: async () =>
      (
        await import(
          '../features/shopilink-management/shopilink-management.component'
        )
      ).ShopilinkManagementComponent
  },
  {
    path: 'setting',
    loadComponent: async () =>
      (await import('../features/shopilink-setting/shopilink-setting.component'))
        .ShopilinkSettingComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: async () =>
      (await import('../features/shopilink-index/shopilink-index.component'))
        .ShopilinkIndexComponent
  }
];
