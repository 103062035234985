import { CommonEnvironment } from './environment.common';
import developmentFeaturesConfiguration from '../assets/configuration/feature-flag-configuration.development.json';

/*
if it was needed to add/modify any specific field or configuration to/in dev environment,
we must add it into devConfigurations object like this: 
const devConfigurations: Partial<typeof CommonEnvironment> = {
    newFieldForDevEnvironment: false,
    existingField: true,
};
*/
const devConfigurations: Partial<typeof CommonEnvironment> = {};
export const environment = {
  ...CommonEnvironment,
  ...devConfigurations,
  ...developmentFeaturesConfiguration
};
