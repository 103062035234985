import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LOCAL_STORAGE_KEYS } from '@pratik-islem/shared/data';

@Injectable({ providedIn: 'root' })
export class AdminRoutesGuard implements CanActivate {
  constructor(private readonly _store: Store, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const jwtToken =
      localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) ?? '';
    const loggedInUserData = JSON.parse(atob(jwtToken.split('.')[1]));
    return Object.prototype.hasOwnProperty.call(
      loggedInUserData,
      'Permission:EcommerceOrders:All:All'
    );
  }
}
