import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CountryCode, parsePhoneNumberWithError } from 'libphonenumber-js';

export function PhoneNumberValidator(
  regionCode: string | undefined = undefined
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let validNumber = false;
    try {
      if (!regionCode || !control?.value) {
        return null;
      } else if (regionCode && control?.value) {
        const countryCode: CountryCode | undefined =
          (regionCode.toUpperCase() as CountryCode) || undefined;
        const phoneNumber = parsePhoneNumberWithError(
          control.value,
          countryCode
        );
        validNumber = phoneNumber.isValid();
      }
    } catch (e) {}
    return validNumber ? null : { wrongNumber: { value: control.value } };
  };
}

export function checkPhoneNumberValidity(
  number: string,
  regionCode: string
): boolean {
  try {
    if (number && regionCode) {
      const countryCode: CountryCode | undefined =
        (regionCode.toUpperCase() as CountryCode) || undefined;
      const phoneNumber = parsePhoneNumberWithError(number, countryCode);
      return phoneNumber.isValid();
    }
    return false;
  } catch (e) {
    return false;
  }
}
