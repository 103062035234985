export interface ILanguageModel {
    displayName: string;
    isO2: string;
}

export class LanguageModel {
    constructor(
        defaultLanguage: ILanguageModel,
        supportedLanguages: ILanguageModel[]
    ) { }
}
