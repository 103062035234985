import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RandomSymbolColorDirective } from './random-symbol-color.directive';

@NgModule({
  declarations: [RandomSymbolColorDirective],
  imports: [CommonModule],
  exports: [RandomSymbolColorDirective],
})
export class RandomSymbolColorDirectiveModule {}
