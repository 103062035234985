import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { SubSink } from 'subsink';
import { timer } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'order-product-list-of-order',
  templateUrl: './product-list-of-order.component.html',
  styleUrls: ['./product-list-of-order.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, CurrencyPipe]
})
export class ProductListOfOrderComponent implements OnInit {
  @Input()
  public orderInfo: any;

  @Input()
  public defaultLocale: string;

  @Output()
  public loadingStatusChange = new EventEmitter<boolean>();

  public productList: any[] = [];
  public paginatedProductList: any[] = [];
  public showLoadMoreButton = false;

  private currentPage = 0;
  private pageSize = 2;
  private pageCounts = 0;
  private subs = new SubSink();

  constructor(private changeDetection: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.paginateProducts();
  }

  public paginateProducts(): void {
    if (this.orderInfo.productList.length > 0) {
      this.loadingStatusChange.emit(true);
      this.subs.sink = timer(200).subscribe((_) => {
        this.pageCounts = this.orderInfo.productList.length / this.pageSize;
        if (this.pageCounts === 0) {
          this.showLoadMoreButton = false;
        } else {
          const startIndex = this.currentPage * this.pageSize;
          const slicedProducts = this.orderInfo.productList.slice(
            startIndex,
            startIndex + this.pageSize
          );
          if (slicedProducts.length > 0) {
            if (this.currentPage === 0) {
              this.paginatedProductList = slicedProducts;
            } else {
              this.paginatedProductList.push(...slicedProducts);
            }
            this.currentPage++;
          }
          if (
            this.paginatedProductList.length < this.orderInfo.productList.length
          ) {
            this.showLoadMoreButton = true;
          } else {
            this.showLoadMoreButton = false;
          }
        }
        this.loadingStatusChange.emit(false);
        this.changeDetection.detectChanges();
      });
    }
  }
}
