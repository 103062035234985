export const OrderStatusList = [
  {
    type: 'WaitingPayment',
    translationLabel: 'WAITING_PAYMENT',
    color: 'label-light-dark'
  },
  {
    type: 'WaitingApproval',
    translationLabel: 'WAITING_APPROVAL',
    color: 'label-light-info'
  },
  {
    type: 'Approved',
    translationLabel: 'APPROVED',
    color: 'label-light-primary'
  },
  {
    type: 'WaitingForSupplying',
    translationLabel: 'WAITING_FOR_SUPPLYING',
    color: 'label-light-warning'
  },
  {
    type: 'Preparing',
    translationLabel: 'PREPARING',
    color: 'label-outline-primary'
  },
  {
    type: 'Shipped',
    translationLabel: 'SHIPPED',
    color: 'label-outline-success'
  },
  {
    type: 'Delivered',
    translationLabel: 'DELIVERED',
    color: 'label-light-success'
  },
  {
    type: 'CancelRequested',
    translationLabel: 'CANCEL_REQUESTED',
    color: 'label-outline-danger'
  },
  {
    type: 'Canceled',
    translationLabel: 'CANCELED',
    color: 'label-light-danger'
  },
  {
    type: 'Refunded',
    translationLabel: 'REFUNDED',
    color: 'label-light-dark'
  },
  {
    type: 'PaymentFailed',
    translationLabel: 'PAYMENT_FAILED',
    color: 'label-light-dark'
  },
  {
    type: 'Completed',
    translationLabel: 'COMPLETED',
    color: 'label-light-success'
  }
];

export const OrderStatusTitleList = [
  'WaitingPayment',
  'WaitingApproval',
  'Approved',
  'WaitingForSupplying',
  'Preparing',
  'Shipped',
  'Delivered',
  'CancelRequested',
  'Canceled',
  'Refunded',
  'PaymentFailed',
  'Completed'
];
