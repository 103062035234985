import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  salesChannelFeatureKey,
  salesChannelFeatureState
} from './sales-channel.reducer';

export const salesChannelState =
  createFeatureSelector<salesChannelFeatureState>(salesChannelFeatureKey);

export const selectSalesChannelLoadingStatus = createSelector(
  salesChannelState,
  ({ status }) => status
);

export const selectShopilinkSaleChannelData = createSelector(
  salesChannelState,
  ({ shopilink }) => shopilink
);

// this selector only returns the shopilink channel data not other associated channels
export const selectShopilinkData = createSelector(
  salesChannelState,
  ({ shopilink }) => {
    const shopilinkChannels = Object.assign([], shopilink);
    const shopilinkChannelData = shopilinkChannels.findIndex(
      (x: any) => x.platformType == 'None'
    );
    return shopilinkChannelData == undefined ? null : shopilinkChannelData;
  }
);

export const selectShopilinkSaleChannelDataWithAppId = createSelector(
  salesChannelState,
  ({ shopilink, appId }) => {
    return {
      shopilink,
      appId
    };
  }
);

export const selectEasyCollectionSaleChannelData = createSelector(
  salesChannelState,
  ({ easyCollection }) => {
    return easyCollection;
  }
);

export const selectEasyCollectionSaleChannelDataWithAppId = createSelector(
  salesChannelState,
  ({ easyCollection, appId }) => {
    return {
      easyCollection,
      appId
    };
  }
);
