// Turkey
export const TR_LOCALE = {
  lang: 'tr',
  data: {
    TRANSLATOR: {
      SELECT: 'Dilinizi seçiniz'
    },
    TEMPLATE: {
      WELCOME: 'Hoş geldin',
      PROFILE: 'Profil',
      SETTINGS: 'Ayarlar',
      ACCOUNT: 'Hesap',
      LOGOUT: 'Çıkış Yap',
      HELP_CENTER: 'Yardım Merkezi',
      GIVE_FEEDBACK: 'Geri Bildirim Yapın',
      SUPPORT_REQUEST: 'Destek Talebi',
      ALL_PROJECT: 'Tüm Projeler',
      ALL_STORES: 'Tüm Mağazalar',
      WHATS_NEW: 'Yeni Neler Var?'
    },
    MENU: {
      DASHBOARD: 'Pano',
      ORDERS: 'Siparişler',
      CATALOG: {
        TITLE: 'Katalog',
        PRODUCTS: 'Ürünler',
        CATEGORIES: 'Kategoriler',
        BRANDS: 'Markalar',
        SHOWCASE: 'Vitrin',
        VARIANT: 'Varyant'
      },
      CLIENTS: 'Müşteriler',
      STATISTIC: 'İstatistik',
      SALES_CHANNELS: {
        ADD_NEW_SALES_CHANNEL: 'Yeni Satış Kanalı Ekle',
        TITLE: 'Satış Kanalları',
        WEBSITE: 'Web Sitesi',
        MOBILE: 'Mobil uygulama',
        FACEBOOK_INSTAGRAM: 'Facebook ve Instagram'
      },
      SETTINGS: 'Ayarlar',
      GENERAL_SETTINGS: 'Genel Ayarlar',
      PROJECT: 'Proje',
      CONFIGURATION: {
        TITLE: 'Yapılandırma',
        GENERAL_SETTINGS: {
          STORE_INFORMATION: 'Mağaza Bilgileri',
          TITLE: 'Genel Ayarlar',
          PROJECT_INFORMATION: 'Proje Bilgileri',
          INVOICES_PACKAGES: 'Faturalar ve Paketler',
          STAFF_ACCOUNT: 'Personel Hesapları'
        },
        STORE_SETTINGS: {
          TITLE: 'Mağaza Ayarları',
          LANGUAGE_LOCALIZATION: 'Dil ve Yerelleştirme',
          STORE_INFORMATION: 'Mağaza Bilgileri',
          PAYMENT: 'Ödeme',
          LEGAL_PAGES: 'Yasal Sayfalar',
          SHIPPING_AND_DELIVERY: 'Sevkiyat ve Teslimat',
          EMAIL_NOTIFICATION: 'E-posta Bildirimi',
          SLIDE_MANAGEMENT: 'Slayt Yönetimi',
          STOCK_LOCATIONS: 'Stok Konumları',
          TAX_SETTINGS: 'Vergi Ayarları',
          CUSTOMER_GROUPS: 'Müşteri Grupları',
          REGION_LOCALIZATION: 'Bölgeler ve Yerelleştirme',
          LANGUAGE: 'Dil'
        }
      },
      APPS: 'Uygulamalar',
      MY_APPS: 'Benim uygulamalarım',
      APP_STORE: 'Uygulama mağazası'
    },
    AUTH: {
      GENERAL: {
        OR: 'Veya',
        SUBMIT_BUTTON: 'Gönder',
        NO_ACCOUNT: 'Hesabınız yok mu?',
        SIGNUP_BUTTON: 'Kaydol',
        FORGOT_BUTTON: 'Şifremi Unuttum',
        BACK_BUTTON: 'Geri',
        PRIVACY: 'Gizlilik',
        LEGAL: 'Yasal',
        CONTACT: 'İletişim',
        CREATE_AN_ACCOUNT: 'Hesap Oluştur',
        CREATE_NEW_ACCOUNT: 'Yeni Hesap Oluştur',
        MOTTO: 'Mağazanızı Açın ve <br/>Satışa Başlayın',
        MOTTO_DESCRIPTION:
          'E-ticaretin gücünü, karmaşıklıktan kurtulmak ve sonuç almak isteyen işletme sahiplerine ve yeni nesil girişimcilere getirmek için buradayız.',
        AND: 've',
        TERMS_OF_CONDITION: 'Koşullar',
        PRIVACY_POLICY: 'Gizlilik Politikası',
        PRIVACY_STATEMENT:
          'Kaydolarak Pratik İşlem koşullarını kabul etmiş olursunuz.',
        WHOOPS: 'Oops',
        GO_TO_LOGIN: 'Giriş sayfasına git'
      },
      LOGIN: {
        TITLE: 'Giriş Hesabı',
        BUTTON: 'Oturum Aç',
        WELCOME_BACK: 'Tekrar Hoş Geldiniz!',
        NEED_SHOPIROLLER_ACCOUNT: 'Pratik İşlem hesabına mı ihtiyacınız var?',
        SIGN_IN_WTIH_GOOGLE: 'Google ile oturum açın',
        FAILED: 'Giriş başarısız',
        UserNotFound: 'Kullanıcı adınız veya şifreniz yanlış.',
        WrongPassword: 'Kullanıcı adınız veya şifreniz yanlış.',
        UserLockedOut:
          'Hesabınız kilitlendi, birkaç dakika bekleyin ve tekrar deneyin.',
        NotActivatedEmail: 'E-postanız henüz onaylanmadı',
        verifyYourPhoneNumber: 'Telefon Numaranızı Doğrulayın',
        pleaseCheckYourPhone: 'Lütfen telefonunuzu kontrol edin',
        weSentCodeToYourPhone:
          'Bu e-postaya telefon numarası kaydetmek için bir kod gönderdik',
        didntGetCode: 'Kodu almadınız mı?',
        clickToResend: 'Yeniden göndermek için tıklayın.',
        youCanSendCode: 'Kodu daha sonra gönderebilirsiniz',
        invalidVerificationCode: 'Onay kodu geçerli değil!'
      },
      FORGOT: {
        TITLE: 'Şifrenizi mi Unuttunuz?',
        DESC: 'Şifrenizi sıfırlamak için e-posta adresinizi girin',
        EMAIL_SENT:
          'Az önce bir sıfırlama e-postası gönderdik, lütfen posta kutunuzu kontrol edin.',
        SUCCESS: 'Hesabınız başarıyla sıfırlandı.',
        PAGE_HEADER: 'Şifrenizi Sıfırlayın',
        PAGE_SUB_HEADER:
          "Korkmayın. Pratik İşlem'da oturum açmak için kullandığınız e-postayı bize bildirin, şifrenizi geri almanıza yardımcı olalım.",
        RESET_PASSWORD_PAGE_HEADER:
          'Sayın kullanıcı lütfen hesabınız için yeni bir şifre (en az 6 karakter uzunluğunda) girin.',
        RESET_BUTTON: 'Parola Sıfırlama E-postası Gönder',
        BACK_BUTTON: 'Giriş yapmaya geri dön',
        RESET_PASSWORD_EMAIL_SENT_MESSAGE_HEADER: 'Harika! ',
        RESET_PASSWORD_EMAIL_SENT_MESSAGE: 'Şifreyi değiştirdiniz',
        REDIRECT_TO: 'Giriş yapmak için yönlendirileceksiniz ',
        RESET_PASSWORD: 'Şifreyi sıfırla',
        RESET_PASSWORD_INVALID_TOKEN:
          'etonunuzun süresi doldu, lütfen <a href="/auth/forgot-password">şifremi unuttum</a> sayfasına gidin ve bu prosedürü bir kez daha başlatın'
      },
      CHANGE_PASSWORD: {
        TITLE: 'Şifreyi Değiştir'
      },
      REGISTER: {
        verifyPhoneNumber: 'Telefon Numarasını Doğrula',
        TITLE: 'Şimdi Kaydolun',
        DESC: 'Hesabınızı oluşturmak için bilgilerinizi girin',
        SUCCESS: 'Hesabınız başarıyla kaydedildi.',
        ALREADY_HAVE_AN_ACCOUNT: 'Zaten bir hesabınız var mı?',
        LOGIN: 'Giriş yapmak',
        SIGN_UP_WITH_GOOGLE: "Google'a kaydolun",
        FAILED: 'Kayıt Başarısız'
      },
      CONFIRM_EMAIL: {
        OPS: 'Hata!',
        RESEND_MSG:
          'Aktivasyon jetonunuzun süresi dolmuş görünüyor, size yeni bir e-posta gönderdik, lütfen posta kutunuzu kontrol edin ve bir kez daha deneyin.'
      },
      SUCCESSFUL_PROCESS: {
        REDIRECT_TO_LOGIN: 'Giriş yapmak için yönlendirileceksiniz',
        SECONDS: 'saniye',
        SUCCESSFUl_ACTIVATION_EMAIL_RESEND_MESSAGE:
          'Size yeni bir onay e-postası gönderdik, e-postanızı onaylamak için posta kutunuzu kontrol etmeniz gerekiyor.',
        SUCCESSFUl_REGISTRATION_MESSAGE:
          'Kaydınız başarıyla tamamlandı, e-postanızı onaylamak için posta kutunuzu kontrol etmeniz gerekiyor.',
        SUCCESSFUl_REGISTRATION_MESSAGE_HEADER: 'Tebrikler!',
        SUCCESSFUl_RESET_PASSWORD_MESSAGE: 'Şifreyi değiştirdiniz',
        SUCCESSFUl_RESET_PASSWORD_MESSAGE_HEADER: 'Harika! ',
        SUCCESSFUl_CONFIRM_EMAIL_MESSAGE: 'E-postanız başarıyla doğrulandı.'
      },
      INPUT: {
        PHONE_NUMBER: 'Telefon numarası',
        EMAIL: 'E-posta',
        EMAIL_ADDRESS: 'E-posta Adresi',
        FULLNAME: 'Ad Soyad',
        PASSWORD: 'Şifre',
        CONFIRM_PASSWORD: 'Şifreyi Onayla',
        USERNAME: 'Kullanıcı adı',
        RE_TYPE_PASSWORD: 'Şifreyi Yeniden Yazın'
      },
      VALIDATION: {
        RESEND_ACTIVATION_EMAIL:
          'Aktivasyon e-postanızı henüz almadıysanız, lütfen tıklayın',
        RESEND_LINK: 'yeniden gönderme bağlantısı',
        INVALID: '{{name}} geçerli değil',
        REQUIRED: '{{name}} gerekli',
        MIN_LENGTH: '{{name}} minimum uzunluk {{min}}',
        AGREEMENT_REQUIRED: 'Şartlar ve koşulların kabul edilmesi gereklidir',
        NOT_FOUND: 'İstenen {{name}} bulunamadı',
        INVALID_LOGIN: 'Giriş bilgisi yanlış',
        REQUIRED_FIELD: 'Gerekli alan',
        MIN_LENGTH_FIELD: 'Minimum alan uzunluğu:',
        MAX_LENGTH_FIELD: 'Maksimum alan uzunluğu:',
        INVALID_FIELD: 'Alan geçerli değil',
        System: 'Uygulamada bilinmeyen bir hata oluştu',
        systemException: 'Uygulamada bilinmeyen bir hata oluştu',
        UserNotFound: 'Kullanıcı Bulunamadı!',
        EntityNotFound: 'Varlık Bulunamadı',
        UserDoesNotHavePassword: 'Kullanıcının Parolası Yok',
        NoPassword: 'Parola boş',
        UserLockedOut: 'Kullanıcı Kilitlendi',
        BadRequest: 'Sunucuya gönderilen veriler yanlış',
        DuplicateUserName: 'Kullanıcı adı zaten var',
        WrongPassword: 'Şifre yanlış',
        PASSOWRD_CONFIRM_PASSWORD_NOT_MATCH:
          "Şifre' ve 'Şifreyi Onayla' eşleşmedi.",
        undefined: 'Uygulamada bilinmeyen bir hata oluştu',
        EMAIL_IS_INVALID: 'E-posta geçersiz',
        EMAIL_IS_REQUIRED: 'E-posta gerekli',
        EMAIL_MIN_LENGTH: 'E-posta en az 3 karakter içermelidir.',
        EMAIL_MAX_LENGTH: 'E-posta en fazla 256 karakter içermelidir.',
        PASSWORD_IS_REQUIRED: 'Parola gerekli',
        PASSWORD_MIN_LENGTH: 'Parola en az 6 karakter içermelidir.',
        PASSWORD_MAX_LENGTH: 'Parola en fazla 32 karakter içermelidir.',
        CPASSWORD_IS_REQUIRED: 'Şifreyi onaylayın gerekli',
        CPASSWORD_MIN_LENGTH: 'Şifreyi onayla en az 6 karakter içermelidir.',
        CPASSWORD_MAX_LENGTH:
          'Şifreyi onayla en fazla 32 karakter içermelidir.',
        FULLNAME_MIN_LENGTH: 'Ad Soyad en az 3 karakter içermelidir.',
        FULLNAME_MAX_LENGTH: 'Ad Soyad en fazla 100 karakter içermelidir.',
        FULL_NAME_IS_REQUIRED: 'Ad Soyad gerekli',
        LimitExceeded:
          'Bir süreliğine daha fazla şifre sıfırlama isteği göndermenize izin verilmiyor'
      }
    },
    LAYOUT_BUILDER: {
      HEADER_TEXT:
        'Plan oluşturucu, setinize yardımcı olmak ve tercih ettiğiniz proje düzeni özelliklerini yapılandırmak ve gerçek zamanlı olarak önizlemek içindir. Yapılandırılan düzen seçenekleri, siz onları değiştirene veya sıfırlayana kadar kaydedilir. Düzen oluşturucuyu kullanmak için, düzen seçeneklerini seçin ve değişiklikleri önizlemek için Önizleme düğmesini tıklayın.'
    },
    APPLICATIONS: {
      DEMO_STORE: 'Demo Mağaza',
      DEMO_STORE_DESCRIPTION:
        'Mağazayı demo veriler ile oluştur. Nasıl olduğunu görmek istiyorum.',
      EMPTY_STORE: 'Boş Mağaza',
      EMPTY_STORE_DESCRIPTION:
        'Mağazaya demo veri yükleme. Herşeyi baştan inşaedeceğim.',
      CHANGE_STORE_SALE_CHANNEL:
        'If you want, you can then change it from web sales channel settings.',
      STORE_COUNT: 'Mağaza sayısı',
      STORE_CREATION_FAILED:
        'Üzgünüz, mağaza oluşturma işleminiz başarısız oldu!',
      DOMAIN_IS_NOT_AVAILABLE:
        'Mağaza alan adınız mevcut değil veya muhtemelen kara listede, bu sorunu çözmek için lütfen mağazanızın adını değiştirin.',
      WE_ARE_CREATING_YOUR_STORE: 'Mağazanızı oluşturuyoruz',
      YOUR_STORE_DATA_IS_ADDING: 'Mağazanızın birincil verileri ekleniyor',
      WE_ARE_CREATING_GENERAL_PREFERENCES: 'Genel tercihler oluşturuyoruz',
      THE_PROCESS_OF_CREATING_STORE_IS_COMPLETED:
        'Mağazanızı oluşturma işlemi tamamlandı',
      YOUR_SALES_CHANNEL_ARE_GETTING_READY: 'Satış kanallarınız hazırlanıyor',
      THIS_WOULD_BE_YOUR_INITIAL_URL:
        "Bu, ilk URL'niz olacaktır, mağazayı oluşturduktan sonra onu istediğiniz adresle değiştirebileceksiniz",
      CREATE_NEW_STORE: 'Yeni Mağaza Oluştur',
      CREATE_STORE: 'Mağaza Oluştur',
      STORE_NAME: 'Mağaza Adı',
      STORE_SUB_DOMAIN: 'Mağaza Alt Alanı',
      STORE_DEFAULT_CURRENCY: 'Varsayılan para birimini sakla',
      STORE_DEFAULT_LANGUAGE: 'Varsayılan dili sakla',
      NEED_A_DEMO: 'Bir demoya ihtiyacınız var',
      STORE_SECTOR: 'Mağaza Sektörü',
      QUICK_ACTIONS: 'Hızlı işlemler',
      CREATE_NEW_PROJECT: 'Yeni Proje Oluştur',
      CREATE_PROJECT: 'Proje Oluştur',
      PROJECT_NAME: 'Proje adı',
      PROJECT_COUNT: 'Proje sayısı',
      GOOD_MORNING: 'Günaydın',
      GOOD_AFTERNOON: 'İyi günler',
      GOOD_EVENING: 'İyi akşamlar',
      GREETING_MESSAGE:
        'Burada olduğun için mutluyuz. Hadi projelerine göz atalım.',
      NO_APPLICATION_COMPONENT_MESSAGE:
        'Aramıza katılmana çok sevindik. <br/>Hadi <b>yeni bir proje</b> oluştur. ',
      LAST_ACTIVITY: 'Son etkinlik',
      SUCCESSFUL_EDIT: 'Proje başarıyla düzenlendi.',
      SUCCESSFUL_DELETE: 'Proje başarıyla silindi.',
      NO_SEARCH_RESULT_COMPONENT_MESSAGE:
        'Hay aksi! Hiçbir şey görünmüyor. Arama kriterlerinizi değiştirerek tekrar deneyebilirsiniz.',
      NO_RECORDS_FOUND: 'Kayıt bulunamadı.',
      DELETE_PROJECT: 'Projeyi Sil',
      DASHBOARD: 'Pano',
      PRODUCTS: 'Ürünler',
      ORDERS: 'Siparişler',
      STATISTICS: 'İstatistik',
      DELETE_APPLICATION_WARNING:
        'Onayınızdan sonra projeniz silinecektir. Projenizi sildikten sonra',
      LOSING_CONTENTS_WARNING:
        'Projenize ait ürünler, kategoriler vb. tüm içerikleri de silinecek ve bunlara erişiminizi tamamen kaybedeceksiniz.',
      IRREVERSIBLE_WARNING:
        "Proje silme işlemi (yanlışlıkla yapsanız bile) geri alınamaz. Projenizin silinmesini onaylıyorsanız lütfen Pratik İşlem şifrenizi giriniz ve 'Sil' butonuna tıklayınız..."
    },
    SHARED: {
      PLEASE_WAIT: 'Lütfen bekleyin',
      VERIFY: 'Doğrulamak',
      YES_IM_SURE: 'Evet, eminim',
      UNSPECIFIED: 'Belirtilmemiş',
      UNKNOWN: 'Bilinmeyen',
      REJECTED: 'Reddedilmiş',
      STORE: 'Mağaza',
      PROJECT_KEY: 'Proje Anahtarı',
      SURNAME: 'Soyadı',
      MANAGE: 'Yönet',
      GOOGLE: 'Google',
      SUGGEST: 'Tavsiye etmek',
      AMOUNT: 'Miktar',
      OFFER: 'Teklif',
      ACCEPT: 'Kabul Et',
      KG: 'kg',
      CM: 'cm',
      LENGTH: 'Uzunluk',
      HEIGHT: 'Yükseklik',
      WIDTH: 'Genişlik',
      WEIGHT: 'Ağırlık',
      DISTRICT: 'Semt',
      CITY: 'Şehir',
      ZIP_CODE: 'Posta kodu',
      REQUEST: 'Rica etmek',
      VALUE: 'Değer',
      METHOD: 'Yöntem',
      TYPE: 'Tip',
      TRIGGER: 'Tetiklemek',
      URL: 'Url',
      APPLICATION: 'Başvuru',
      COLLECTION: 'Tahsilat',
      CURRENT_STATUS: 'Şu anki durum',
      SHOPIPAY: 'Shopipay',
      MESSAGE: 'İleti',
      SHOPIROLLER: 'Pratik İşlem',
      ADDRESS: 'Adres',
      CONFIRMED: 'Onaylandı',
      AWAITING_FOR_APPROVAL: 'Onay Bekliyor',
      PHONE_NUMBER: 'Telefon numarası',
      VIDEO: 'Video',
      PHOTO: 'Fotoğraf',
      PUBLISH: 'Yayınla',
      UNPUBLISH: 'Yayından Kaldır',
      SECURITY_CODE: 'Güvenlik Kodu',
      EXPIRATION_DATE: 'Son kullanma tarihi',
      CVC: 'CVC',
      BRAND: 'Marka',
      DETAIL: 'Detay',
      AMOUNT_PAID: 'Ödenen miktar',
      DATE: 'Tarih',
      CUSTOMER: 'Müşteri',
      TRANSACTIONS: 'İşlemler',
      CREDIT_CARD: 'Kredi kartı',
      CARDHOLDER_NAME: 'Kart Sahibinin Adı',
      NAME: 'Name',
      PENDING: 'Beklemede',
      TRANSFERRED: 'Aktarıldı',
      CANCELED: 'İptal edildi',
      SUCCEEDED: 'Başarılı',
      FAILED: 'Başarısız',
      PER: 'Başına',
      PER_MONTH: '/ ay',
      PER_YEAR: '/ yıl',
      MONTHLY: 'Aylık',
      YEARLY: 'Yıllık',
      CURRENCY: 'Para birimi',
      REGION: 'Bölge',
      HOURS: 'Saat',
      WEBSITE: 'İnternet sitesi',
      WHATSAPP: 'WhatsApp',
      SUPPORT: 'Destek',
      PREVIOUS: 'Önceki',
      NEXT: 'Sonraki',
      NONE: 'Hiçbiri',
      INTEGRATE: 'entegrasyon',
      COMING_SOON: 'Yakında',
      HERE: 'Buraya',
      ATTACH: 'Ekle',
      CODE: 'kod',
      CONFIGURATION: 'Yapılandırma',
      UPGRADE: 'Yükseltme',
      DOWNGRADE: 'Düşürme',
      PACKAGE: 'Paket',
      BUSINESS: 'İşletme',
      EMAIL: 'E-posta',
      TITLE: 'Başlık',
      DESCRIPTION: 'Tanım',
      DOMAIN: 'Alan adı',
      SUBDOMAIN: 'Alt alan adı',
      LOGO: 'Logo',
      FAVICON: 'FavIcon',
      SEARCH: 'Arama',
      TRANSFER: 'Aktar',
      PAY_AT_DOOR: 'kapıda ödeme',
      PAYPAL: 'paypal',
      ONLINE: 'online',
      ONLINE_3DS: 'Online 3DS',
      STRIPE: 'Stripe',
      VISIBLE: 'Görünür',
      DISABLE: 'Devre dışı bırakmak',
      ENABLE: 'Devre Dışı Bırak',
      WEB: 'network',
      MOBILE: 'mobil',
      PRODUCT: 'Ürün',
      SERVICE: 'Hizmet',
      QTY: 'Qty',
      TOTAL: 'Toplam',
      LINK: 'Bağlantı',
      COPY: 'Kopyala',
      PREVIEW: 'Ön İzleme',
      SETTINGS: 'Ayarlar',
      SERVICES: 'Hizmetler',
      TRANSLATE: 'Çevirmek',
      OR: 'VEYA',
      CUSTOMIZE: 'Özelleştirmek',
      IOS: 'IOS',
      ANDROID: 'Android',
      PLATFORM: 'platformu',
      SAVE_CHANGES: 'Değişiklikleri Kaydet',
      NON_CONNECTED: 'Bağlantısız',
      CONNECTED: 'bağlı',
      PASSWORD: 'Parola',
      PROJECT: 'proje',
      ADD_NEW_TRANSLATE: 'Yeni çeviri ekle',
      ADDED_FILE: 'Eklenen Dosya',
      REPLACE_FILE: 'Dosyayı değiştir',
      ADD_FILE: 'Dosya ekle',
      CHANGE_FILE: 'Dosyayı değiştir',
      UPLOAD_CONTINUE: 'Yükle ve devam et',
      MORE_ACTIONS: 'Diğer Eylemler',
      IMPORT: 'İçe Aktar',
      EXPORT: 'Dışa Aktar',
      MADE_WITH: '© 2024 Pratikpos',
      CHOOSE_AN_ITEM: 'Bir öğe seçin',
      WELCOME: 'Hoş geldin',
      YOUR_PASSWORD: 'Şifreniz',
      REASON: 'Neden',
      RESEND: 'Yeniden Gönder',
      SEC: 'Sn',
      CONTINUE: 'Devam',
      CHANGE: 'Değiştir',
      LANGUAGE: 'Dil',
      E_MAIL: 'E-posta',
      PHONE: 'Telefon',
      ENGLISH: 'İngilizce',
      TURKISH: 'Türkçe',
      FULL_NAME: 'Ad Soyad',
      ACCOUNT: 'Hesap',
      FIELD_IS_EMPTY: '{{field}} boş',
      DONT: 'Yapma',
      THIS_ACTION_CANT_BE_UNDONE: 'Bu işlem geri alınamaz.',
      DEFAULT: 'Varsayılan',
      TIMELINE: 'Zaman Çizelgesi',
      APPLY: 'Uygula',
      TODAY: 'Bugün',
      YESTERDAY: 'Dün',
      LAST_7_DAYS: 'Son 7 Gün',
      LAST_30_DAYS: 'Son 30 Gün',
      THIS_MONTH: 'Bu Ay',
      LAST_MONTH: 'Geçen Ay',
      CUSTOM_RANGE: 'Özel Aralık',
      YEAR: 'Yıl',
      MONTH: 'Ay',
      WEEK: 'Hafta',
      DAY: 'Gün',
      HOUR: 'Saat',
      MINUTE: 'Dakika',
      SECOND: 'Saniye',
      YES: 'Evet',
      NO: 'Hayır',
      REMOVE: 'Kaldırmak',
      FINISH: 'Sona ermek',
      LEARN_MORE: 'Daha fazla bilgi',
      MORE_INFO: 'Daha fazla bilgi',
      GO_BACK: 'Geri dön',
      SELECT_DATE_TIME: 'Tarih ve saati seçin',
      MIN: 'Min',
      MAX: 'Maks',
      JUST_NOW: 'Hemen Şimdi',
      AGO: ' önce',
      AGO_PLURAL: ' önce',
      PRICE: 'Fiyat',
      MIN_PRICE: 'Min fiyat',
      MAX_PRICE: 'Maks fiyat',
      CANCEL: 'İptal',
      SAVE: 'Kaydet',
      CLOSE: 'Kapat',
      EDIT: 'Düzenle',
      DELETE: 'SİL',
      CONFIRM_DIALOG_TEXT: 'Bu işlemi tamamlamak istediğinizden emin misiniz?',
      VERSION: 'Sürüm',
      PRIVACY_POLICY: 'Gizlilik Politikası',
      TERMS_OF_CONDITION: 'Koşul Şartları',
      TERMS_OF_USE: 'Kullanım Koşulları',
      LOAD_MORE: 'Daha Fazla Yükle',
      ACTIONS: 'Eylemler',
      SORT_BY: 'Sıralama Ölçütü',
      DEFAULT_LANG_CANT_BE_DELETED: 'Bu varsayılan dildir ve silinemez.',
      LANGUAGE_SUPPORTED: 'Dil destekleniyor',
      LANGUAGE_NOT_SUPPORTED: 'Dil desteklenmiyor',
      QUEUE: 'Sıra',
      CATEGORY: 'Kategori',
      PRODUCT_TOTAL: 'Ürün Toplamı',
      SUB_CATEGORY_TOTAL: 'Alt Kategori Toplamı',
      STATUS: 'Durum',
      ACTIVE: 'Aktif',
      PASSIVE: 'Pasif',
      CHOOSE: 'Seç',
      FIELD_IS_REQUIRED: '{{field}} gerekli',
      FIELD_IS_INVALID: '{{field}} geçersiz',
      DEFAULT_LANGUAGE_NOT_SET: '{{field}} için varsayılan dil ayarlanmadı.',
      FIELD_MIN_MAX_BOUNDARY_VALIDATION:
        '{{field}} en az {{minLength}} ve en fazla {{maxLength}} karaktere sahip olmalıdır.',
      FIELD_IS_INVALID_FOR_ONE_OF_LANGUAGES:
        "'{{field}}' seçilen dillerden biri için geçerli değil.",
      NO_RECORDS_FOUND: 'Kayıt bulunamadı.',
      ORDER_INDEX_UPDATED: 'Sipariş dizini başarıyla güncellendi.',
      FILTER: 'Filtre',
      START_DATE: 'Başlangıç ​​tarihi',
      END_DATE: 'Bitiş tarihi',
      DATE_ERROR: 'Başlangıç ​​tarihi bitiş tarihinden büyük olamaz.',
      CLEAR_ALL_FILTER: 'Tüm Filtreyi Temizle',
      APPLY_FILTER: 'Filtre Uygula',
      APPLY_FILTER_ERROR: 'Henüz herhangi bir değişiklik yapmadınız.',
      CREATED_OLDEST: 'Oluşturuldu (önce en eski)',
      CREATED_NEWEST: 'Oluşturuldu (önce en yenisi)',
      FILE_UPLOADER: {
        DELETE_TITLE: 'Resim silinecek',
        CHOOSE_LABEL: 'Seç',
        UPLOAD_LABEL: 'Yükle',
        CANCEL_LABEL: 'İptal',
        DROP_MSG: 'Resim dosyanızı buraya bırakın veya yüklemek için tıklayın.',
        MAX_SIZE: 'Maksimum dosya boyutu 4 MB olmalıdır.',
        VALID_TYPE: 'Görüntü için geçerli tür PNG, JPG, JPEG',
        IMAGE_NOT_UPLOADED: 'Resim yüklenmedi.',
        FEATURE_IMAGE:
          'Öne çıkan görseli seçmek için istediğiniz görsele tıklayın.',
        FEATURE_IMAGE_ERROR:
          'Görüntüleri yüklemeli ve ardından öne çıkan görselinizi seçmelisiniz.',
        FEATURE_IMAGE_TOOLTIP: 'Bu, ürünün ana görselidir.',
        MAXIMUM_COUNT_EXCEEDED:
          'Maksimum dosya sayısı aşıldı, limit en fazla {{limit}}'
      },
      VALIDATION: {
        ENTITY_NOT_FOUND: 'Varlık bulunamadı',
        EMAIL_IS_INVALID: 'E-posta geçersiz',
        EMAIL_IS_REQUIRED: 'E-posta gerekli',
        EMAIL_MIN_LENGTH: 'E-posta en az 3 karakter içermelidir.',
        EMAIL_MAX_LENGTH: 'E-posta en fazla 360 karakter içermelidir.',
        EMAIL_DECLARED_BEFORE: 'E-postalar daha önce bildirilmişti.',
        PHONE_NUMBER_IS_INVALID: 'Telefon numarası geçersiz',
        PHONE_IS_INVALID: 'Telefon geçersiz',
        MIN_LENGTH: 'En az 2 karakter olmalıdır.',
        MAX_LENGTH: 'En fazla 255 karakter içermelidir.',
        FIELD_REQUIRED: 'Bu alan zorunludur.',
        WEB_URL_IS_INVALID: "Web URL'i geçersiz.",
        PAGE_URL_IS_INVALID: "Sayfa URL'i geçersiz.",
        IMAGE_NOT_UPLOADED: 'Görsel yüklenmedi',
        IMAGE_MAX_SIZE: 'Maksimum dosya boyutu 4 MB olmalıdır.',
        IMAGE_VALID_TYPE: 'Görüntü için geçerli tür PNG, JPG, JPEG',
        OUT_OF_RANG_2_60: '{{field}} 2 - 60 arasında olmalıdır.',
        MIN_LENGTH_ERROR: 'En az {{minlength}} karakter olmalıdır.',
        MAX_LENGTH_ERROR: 'En fazla {{maxlength}} karakter içermelidir.',
        FIELD_MIN_LENGTH_ERROR:
          '{{field}} en az {{minlength}} sembolüne sahip olmalıdır',
        FIELD_MAX_LENGTH_ERROR:
          '{{field}} maksimum {{maxlength}} sembol içermelidir',
        ID_LENGTH: 'Kimlik alanı uzunluğu 24 karakterdir.',
        NO_EMPTY_STRING: 'Bu alan boş bırakmak için uygun değil!',
        NO_SPACE: 'Boş bırakılamaz.',
        MAX_LENGTH_EXCEEDED: 'Bu alanın maksimum uzunluğunu aştınız'
      },
      MESSAGES: {
        FULFILLED_SUCCESSFULLY: 'Talep başarıyla yerine getirildi.',
        REQUEST_FAILED: 'İstek başarısız',
        FETCH_FAILED:
          'Geçerli sayfa için veri getirilemedi, lütfen sayfayı yenileyin',
        NOT_FOUNT: 'Varlık bulunamadı',
        NO_SEARCH_RESULT:
          'Hay aksi! Hiçbir şey görünmüyor. Filtre terimlerini değiştirmeyi deneyin.',
        SUCCESS: 'Başarı',
        INFO: 'Bilgi',
        WARN: 'Uyarı',
        ERROR: 'Hata',
        FETCHING_FAILED: '{{field}} getirilemedi.',
        SUCCESSFUL_ADD: '{{case}} ekleme başarıyla tamamlandı',
        SUCCESSFUL_EDIT: '{{case}} başarıyla tamamlandı',
        SUCCESSFUL_DELETE: '{{case}} silme işlemi başarıyla tamamlandı',
        FAILED_ADD: 'Maalesef {{case}} eklenemedi!',
        FAILED_EDIT: 'Maalesef {{case}} düzenlemesi başarısız oldu!',
        FAILED_EDIT_OPERATION: 'Maalesef düzenleme işlemi başarısız oldu!',
        FAILED_DELETION: 'Maalesef {{case}} silme işlemi başarısız oldu!',
        CONFIRM_ACTION: 'Bu eylemi gerçekleştirmek istediğinizden emin misiniz?'
      }
    },
    ORDERS: {
      COLLECTION_TRANSACTIONS: 'Tahsilat işlemleri',
      LINK_OPERATIONS: 'Bağlantı işlemleri',
      MAX_VALUE_CAN_BE: 'En fazla geri ödeme yapabilirsiniz',
      ENTER_REFUND_PRICE: 'Geri ödeme değerini buraya girin',
      CHOOSE_REFUND_TYPE: 'Geri ödeme türünü seçin',
      REFUND_CUSTOM_PRICE: 'Özel fiyat',
      SUCCESSFUL_CANCEL_ORDER: 'Sipariş başarıyla iptal edildi.',
      ORDER_CANT_BE_CANCELED:
        'Hata! Bu siparişi iptal edemezsin. Yalnızca mevcut günün siparişlerini iptal etmene izin verilir',
      CANCEL_ORDER: 'Siparişi İptal Et',
      CARGOR_OFFER_WARNING:
        'Fiyatlara vergiler dahil değildir. Siparişiniz teslim edildiğinde vergiler dahil edilip tahsilatınızdan düşülecektir',
      OFFER_ACCEPTED_SUCCESSFULLY: 'Tebrikler, başarıyla teklif edildiniz',
      ACCEPT_OFFER_FAILED: 'Maalesef teklifiniz kabul edilmedi',
      GET_AN_OFFER: 'Teklif Al',
      SHIPMENT_PRICE: 'Kargo Tutarı',
      ORDER_PRICE_AMOUNT: 'Sipariş Tutarı',
      CARGO_OFFERS: 'kargo teklifleri',
      FASTEST: 'en hızlı',
      CHEAPEST: 'En ucuz',
      CARGO_DIMENSION: 'Kargo Ölçüsü',
      CARGO: 'Kargo',
      GET_CARGO_OFFER: 'Kargo Teklifi Al',
      NO_PAYMENT_DATA: 'Bu sipariş için ödeme verisi yok!',
      ORDERS: 'Siparişler',
      ORDER_LIST: 'Sipariş Listesi',
      ORDER_DETAIL: 'Sipariş Detayı',
      REFUND: 'Geri Ödeme',
      ORDER: 'Sipariş',
      DATE: 'Tarih',
      CUSTOMER: 'Müşteri',
      PAYMENT_TYPE: 'Ödeme Türü',
      AMOUNT_PAID: 'Ödenen Miktar',
      ORDER_SEARCH: 'Arama...',
      ORDER_NUMBER: 'Sipariş Numarası',
      ORDER_AMOUNT: 'Toplam tutar',
      PAYMENT_SUCCESSFUL: 'Ödeme başarılı',
      ORDER_FEE: 'Kazanç',
      ORDER_INSTALLMENT: 'Taksit',
      ORDER_CAN_SEND_TRANSFER: 'Transfer gönderebilir',
      COLLECTION_STATUS: 'Transfer Durumu',
      ORDER_FEE_DESCRIPTION:
        'Bu sipariş için tahsil edebileceğiniz tutar. Ek olarak iade veya iptal işlemlerinde tüm komisyon oranları iade edilir',
      COMMISSION_RATE: 'Komisyon oranı',
      COMMISSION_AMOUNT: 'Komisyon Tutarı',
      DATE_OLDEST_FIRST: 'Tarih (önce en eski)',
      DATE_NEWEST_FIRST: 'Tarih (önce en yeni)',
      PRICE_ASCENDING: 'Fiyat Artan',
      PRICE_DESCENDING: 'Fiyat Azalan',
      ORDER_DATE: 'Sipariş Tarihi',
      NO_ORDER_MESSAGE:
        'Siparişlerinizi Yönetin! Siparişleri gönderin, ödemeleri ve siparişlerin durumunu kontrol edin.',
      STATUS_TRACKING: 'Durum ve Takip',
      NOTIFICATION: 'Bildirimler',
      ORDER_STATUS: 'Sipariş Durumu',
      NOTIFY_USER_OF_CHANGES: 'Değişiklikleri kullanıcıya e-posta ile bildirin',
      SHIPPING_TRACKING_CODE: 'Kargo Takip Kodu',
      SHIPPING_COMPANY: 'Kargo Şirketi',
      PAYMENT_INFORMATION: 'Ödeme Bilgileri',
      PAYMENT_METHOD: 'Ödeme Metodu',
      PAYMENT_DETAILS: 'Ödeme detayları',
      CARGO_INFORMATION: 'kargo detayları',
      CARGO_TRACKING_LINK: 'Kargo Takip Linki',
      CURRENT_AMOUNT_OF_CARGO: 'Cari Kargo Tutarı',
      CARGO_BARCODE: 'Kargo Barkodu',
      DECLARE_ORDER_CODE_TOCARGO_PERSONEL:
        'Lütfen Kargo personeline, Pazaryeri entegrasyon ekranında bulunan Geliver Bilişim A.Ş sözleşmesi üzerinden işlem yapacağınızı belirttikten sonra sipariş kodunu söyleyiniz',
      SUB_TOTAL: 'Ara Toplam',
      SHIPMENT: 'Kargo',
      TOTAL: 'Total',
      MAKE_A_REFUND: 'Geri Ödeme Yap',
      NOTES: 'Not',
      NO_NOTES: 'Müşteri notu yok.',
      PRODUCTS: 'Ürünler',
      TIMELINE: 'Zaman Tüneli',
      TIMELINE_DESCRIPTION: 'Sipariş verildi',
      BILLING_INFORMATION: 'Fatura Bilgileri',
      DELIVERY_INFORMATION: 'Teslimat Bilgileri',
      CLIENT: 'Müşteri',
      STOCK_CODE: 'Stok Kodu',
      REFUND_REASON: 'Geri Ödeme Nedeni',
      REASON_FOR_RETURN: 'İade Nedeni',
      DESCRIPTION: 'Açıklama (Opsiyonel)',
      CAUTION: 'Bunu yalnızca siz ve diğer personel görebilir.',
      SUMMARY: 'Özet',
      REFUND_TOTAL: 'Geri Ödeme Toplamı',
      BUYER_REQUEST: 'Alıcı Talebi',
      DOUBLE_PAYMENT: 'Çift Ödeme',
      FRAUD: 'Dolandırıcılık',
      OTHER: 'Diğer',
      ALREADY_REFUNDED: 'Ürünlerden biri zaten geri ödeme yaptı.',
      REFUND_WARNING: 'İade etmek istediğiniz ürünleri seçmelisiniz.',
      TOTAL_AMOUNT: 'Toplam Tutar',
      WAITING_PAYMENT: 'Ödeme bekleniyor',
      WAITING_APPROVAL: 'Onay bekleniyor',
      APPROVED: 'Onaylandı',
      WAITING_FOR_SUPPLYING: 'Tedarik aşamasında',
      PREPARING: 'Hazırlanıyor',
      SHIPPED: 'Kargolandı',
      DELIVERED: 'Teslim Edildi',
      CANCEL_REQUESTED: 'İptal Talebi',
      CANCELED: ' İptal Edildi',
      REFUNDED: 'Geri ödendi',
      PAYMENT_FAILED: 'Ödeme başarısız',
      COMPLETED: 'Tamamlandı',
      COMPLETE_ORDER: 'Siparişi Tamamla',
      CANT_CHANGE_ORDER_STATUS:
        'Siparişin durumu iade edildiyse, tamamlandıysa veya iptal edildiyse sipariş durumu değiştirilemez',
      CANT_CHANGE_ORDER_STATUS_WARNING:
        'Siparişin durumunu iade edildi, tamamlandı veya iptal edildi olarak değiştirirseniz sipariş durumu değişmez',
      SHOPIROLLER_COLLECT_ORDER_MESSAGE:
        "Siparişiniz, tamamlandı olarak işaretlenmedikçe tahsilat için işleme konulamaz. Siparişlerinizi her Çarşamba günü saat 20:00'ye kadar tamamlandı olarak işaretleyin, bu noktada siparişin ödemesi hesabınıza yatırılacaktır. Lütfen unutmayın, siparişlerinizi tamamlamak için kargo takip numarasını ve kargo şirketi detaylarını sağlamanız gereklidir",
      NO_SHOPIPAY_MESSAGE: 'Tahsilat kaydı yok',
      SHOPIPAY_PAGE_MESSAGE:
        "Bu sayfa Shopipay ile ödeme aldığınız siparişler için detaylı bilgi içerir. Her hafta Çarşamba günü saat 20:00'da Tamamlandı olarak işaretlenen siparişleriniz Iban adresinize gönderilir. ",
      TOTAL_AMOUNT_TO_BE_CHARGE: 'Tahsil edilecek toplam tutar',
      ORDER_PAYMENT_STATUS: 'Sipariş Ödeme Durumu',
      TOTAL_PRICE: 'Toplam fiyat',
      COMPLETED_ORDER_STATUS_DESCRIPTION: 'Sipariş tamamlandı',
      PAYMENT_FAILED_ORDER_STATUS_DESCRIPTION: 'Sipariş iade edildi',
      REFUNDED_ORDER_STATUS_DESCRIPTION: 'Sipariş iade edildi',
      CANCELED_ORDER_STATUS_DESCRIPTION: 'Sipariş iptal edildi',
      CANCEL_REQUESTED_ORDER_STATUS_DESCRIPTION:
        'Sipariş için iptal talebi gönderildi',
      DELIVERED_ORDER_STATUS_DESCRIPTION: 'Sipariş sevk edildi',
      SHIPPED_ORDER_STATUS_DESCRIPTION: 'Sipariş sevk edildi',
      PREPARING_ORDER_STATUS_DESCRIPTION: 'Sipariş hazırlanıyor',
      WAITING_FOR_SUPPLYING_ORDER_STATUS_DESCRIPTION: 'Sipariş onaylandı',
      APPROVED_ORDER_STATUS_DESCRIPTION: 'Sipariş onaylandı',
      WAITING_APPROVAL_ORDER_STATUS_DESCRIPTION: 'Sipariş onay bekliyor',
      WAITING_PAYMENT_ORDER_STATUS_DESCRIPTION: 'Sipariş ödeme için bekliyor'
    },
    STATISTICS: {
      FEES: 'Ücretler',
      CHARGES: 'Ücretler',
      CANCELS: 'İptaller',
      REFUNDS: 'İadeler',
      TOTAL_COUNT: 'Toplam Sayı',
      TOTAL_PRICE: 'Toplam Fiyat',
      BALANCE: 'Bakiye',
      TRANSFER_STATUS: 'Aktarım Durumu',
      TRANSFER_DATE: 'Transfer Tarihi',
      NUMBER_OF_TRANSACTIONS: 'İşlem Sayısı',
      REVENUE_WATERFALL: 'Gelir Şelalesi',
      ORDERS_STATISTICS: 'Sipariş İstatistikleri',
      STATISTICS: 'İstatistik',
      RECENT_ORDERS: 'Son Siparişler',
      ORDERS_COUNT_REPORT: '{{items}}+ adetten fazla yeni sipariş',
      TOTAL_ORDER: 'Toplam Sipariş',
      ORDERS_COUNT: 'Sipariş Sayısı',
      ORDERS_TOTAL_PRICE: 'Siparişlerin toplam tutarı',
      ORDERS_TOTAL_COUNT: 'Siparişlerin toplam sayısı',
      MOST_ORDERED_PRODUCTS: 'En Çok Sipariş Edilen Ürünler',
      PAYMENT_METHODS: 'Ödeme metodları',
      DATE_RANGE_ERROR: 'Bir tarih aralığı seçmelisiniz.',
      DATE_RANGE_END_DATE_ERROR:
        'Tarih aralığı için bir bitiş tarihi seçmelisiniz.'
    },
    GENERAL_SETTINGS: {
      STORE_NAME: 'Mağaza adı',
      STORE_INFORMATION: 'Mağaza Bilgileri',
      STORE_SETTINGS: 'Mağaza Ayarları',
      DELETE_STORE: 'Mağazayı Sil',
      TITLE: 'Genel Ayarlar',
      PROJECT_SETTINGS_TITLE: 'Proje Ayarlar',
      PROJECT_NAME: 'Proje Adı',
      ADVANCED_INFORMATION: 'Gelişmiş Bilgi',
      VALUE: 'Değer',
      COPY: 'Kopyala',
      PACKAGE_NAME: 'Paket Adı',
      DELETE_PROJECT: 'Projeyi Sil',
      DELETE_DESCRIPTION:
        'Projeniz kalıcı olarak silinecek. Bu işlem geri alınamaz.',
      DELETE_STORE_DESCRIPTION:
        'Mağaza kalıcı olarak silinecek. Bu işlem geri alınamaz.',
      DELETE_STORE_PRIMARY_DATA:
        'Oluşturma sürecinde mağazanıza eklenen birincil verileri silin',
      DELETE_DEMO_DATA: 'Demo Verilerini Sil',
      DEMO_DATA_DELETED: 'Mağaza demo verileriniz başarıyla silindi',
      LANGUAGE_REGION: 'Dil ve Bölge',
      LANGUAGE_LOCALIZATION: 'Dil ve Yerelleştirme',
      ALL_LANGUAGE: 'Bütün Diller',
      ADD_LANGUAGE: 'Dil Ekle',
      ADD_LANGUAGES: 'Dil Ekle',
      EDIT_LANGUAGE: 'Dil Düzenle',
      CODE: 'Kod',
      LANGUAGE: 'Dil',
      APP_ID: 'APP ID',
      APP_KEY: 'APP Key',
      CHANGE_DEFAULT_LANGUAGE:
        'Bu dili mevcut projenin varsayılan dili olarak ayarla.',
      SELECT_LANGUAGE: 'Dil Seçin',
      DEFAULT_LANGUAGE_DELETE_ERROR:
        'Bir projenin varsayılan dilini silemezsiniz',
      SEARCH_FOR_ADD: 'Yeni dil eklemek için ara',
      TYPE_PROJECT_NAME:
        'Lütfen silinmesini onaylamak için projenin adını yazın.',
      PERMANENT_DELETE_PROJECT: 'Projeyi kalıcı olarak silecek.',
      ALONG_WITH_EVERTING:
        ', tüm içerikleri ile birlikte kalıcı olarak silinecektir.',
      PROJECT_NAME_IS_INVALID:
        'Proje adı için girilen değer orijinal adla aynı değil.',
      COPIED_TO_CLIPBOARD: '✔ panoya kopyalandı.',
      CHANGE_DEFAULT_LANGUAGE_ERROR:
        'İçeriğiniz varken varsayılan dili değiştiremezsiniz.',
      SUPPORT_ACCESS_PERMISSION_TITLE:
        'Projenize shopiroller destek ekibi tarafından erişilebilir olacak ve bazı işlemler yapma şansına sahip olacaklar.',
      SUPPORT_ACCESS_PERMISSION: 'Erişim Ver',
      CANCEL_ACCESS_PERMISSION: 'İzni İptal Et',
      ALLOW_SUPPORT_TEAM_ACCESS_PERMISSION_TITLE:
        'Pratik İşlem destek ekibine izin vermediniz, projenize erişmelerini istiyorsanız lütfen tıklayınız.',
      DONT_ALLOW_SUPPORT_TEAM_ACCESS_PERMISSION_TITLE:
        'Zaten erişim izni verdiniz, iptal etmek istiyorsanız lütfen tıklayın',
      PERMISSION_GRANTED: 'Pratik İşlem destek ekibine izin başarıyla verildi',
      PERMISSION_ABOLISHED:
        'Pratik İşlem destek ekibi için izin iptal edilmiştir.',
      PERMISSION_STATUS_CHANGE_FAILED:
        'Maalesef, bilinmeyen bir nedenden dolayı erişim izni değiştirilemedi'
    },
    USER_PROFILE: {
      LEVEL_ASSIGNED: 'Seçilen seviye uygulamaya başarıyla atandı',
      FIND_LEVEL: 'Bir seviye bulun ve uygulamaya atayın',
      ASSIGN_LEVEL: 'Seviye ata',
      APP_NAME: 'Uygulama ismi',
      APPS: 'Üye İşyerleri',
      LEVEL: 'Seviye',
      NEW_LEVEL: 'Yeni Seviye',
      EDIT_LEVEL: 'Seviyeyi Düzenle',
      ADD_NEW_LEVEL: 'Yeni Seviye Ekle',
      IS_CUSTOM: 'Özel mi',
      NO_LEVEL: 'Seviye yok',
      LEVELS: 'Kademeler',
      VALUE_DAY: 'Değer Günü',
      COST: 'maliyeti',
      CARD_BANK: 'Kart Bankası',
      CARD_FAMILY: 'Kart Ailesi',
      INSTALLMENT: 'Taksit',
      RATE: 'Oran',
      CARD_TYPE: 'Kart Türü',
      VALUE: 'Değer',
      DELETE_COST: 'Maliyeti Sil',
      ADD_NEW_COST: 'Yeni Maliyet Ekle',
      EDIT_COST: 'Maliyeti Düzenle',
      NEW_COST: 'Yeni Maliyet',
      COSTS: 'Maliyetler',
      DELETE_BANK: 'Bankayı Sil',
      BANK: 'Banka',
      EDIT_BANK: 'Bankayı Düzenle',
      NEW_BANK: 'Yeni Banka',
      BANK_CODE: 'Banka Kodu',
      BANK_NAME: 'Banka Adı',
      MERCHANT_ID: 'Satıcı Kimliği',
      MERCHANT_USER: 'Satıcı Kullanıcı',
      MERCHANT_PASSWORD: 'Satıcı Şifresi',
      MERCHANT_STORE_KEY: 'Satıcı Mağaza Anahtarı',
      ADD_NEW_BANK: 'Yeni Banka Ekle',
      BANKS: 'Bankalar',
      MY_ACCOUNT: 'Hesabım',
      ACCOUNT_INFORMATION: 'Hesap Bilgileri',
      PASSWORD_CHANGE: 'Şifre Değiştir',
      CLOSE_ACCOUNT: 'Hesabı Kapat',
      ACCOUNT_CLOSURE: 'Hesap Kapatma',
      PREFERRED_LANGUAGE: 'Tercih edilen dil',
      PREFERRED_LANGUAGE_ALERT:
        "Pratik İşlem'da oturum açtığınızda, göreceğiniz dil bu olacaktır. Müşterilerinizin online mağazanızda gördüğü dili etkilemez.",
      EMAIL_CHANGE_FORBIDDEN: 'E-posta adresinizi değiştiremezsiniz.',
      CURRENT_PASSWORD: 'Mevcut Şifre',
      NEW_PASSWORD: 'Yeni Şifre',
      CONFIRM_NEW_PASSWORD: 'Yeni Şifre (Tekrar)',
      CHANGE_PASSWORD: 'Şifre Değiştir',
      PASSWORD_TIPS: 'Güvenli şifre ipuçları',
      PASSWORD_MIN_LENGTH:
        'Şifreniz en az 8 karakter olmalıdır. Rakamların, harflerin ve özel karakterlerin kombinasyonu en iyisidir.',
      REUSE_WARNING:
        "Pratik İşlem'da daha önce kullandığınız şifreyi tekrar kullanmayınız.",
      GENERAL_WARNING:
        'Adınızı, e-posta adresinizi, cep telefonu numaranızı veya kolayca elde edilebilecek diğer kişisel bilgilerinizi kullanmayın.',
      SAME_PASSWORD_WARNING:
        'Birden fazla çevrimiçi hesap için aynı parolayı kullanmayın.',
      UPDATE_PASSWORD_WARNING: 'Şifrenizi her üç ayda bir güncellemelisiniz.',
      CLOSE_ACCOUNT_SORRY_MESSAGE:
        'Pratik İşlem hesabınızı kapatmak istediğiniz için üzgünüz. Geri bildiriminizi alabilmemiz için lütfen hesabınızı kapatma nedeninizi belirtin.',
      CLOSE_MY_ACCOUNT: 'Hesabımı Kapat',
      ACCOUNT_IRREVERSIBLE_WARNING:
        "Hesap kapatma işlemi geri alınamaz, yanlışlıkla hesabınızı kapatsanız bile geri alınamaz. Hesabınızın kapatılmasını onaylıyorsanız lütfen Pratik İşlem şifrenizi girin ve 'HESABIMI KAPAT' butonuna tıklayın.",
      LOSE_ACCESS_TO_PROJECT_WARNING:
        'Hesabınızı kapattıktan sonra, tüm {{projectCount}} projelerinizin aboneliği iptal edilecek ve bunlara erişiminizi tamamen kaybedeceksiniz.',
      CLOSE_ACCOUNT_AFTER_APPROVAL_WARNING:
        'Onayınızdan sonra Pratik İşlem hesabınız kapatılacaktır.',
      OTHER: 'Diğer (Belirtiniz)',
      I_STOPPED_SELLING_ONLINE: 'Online Satışı bıraktım.',
      I_WILL_USE_DIFFERENT_SOLUTION:
        "Pratik İşlem'dan farklı bir çözüm kullanacağım.",
      I_WILL_USE_DIFFERENT_ACCOUNT:
        'Farklı bir Pratik İşlem hesabı kullanacağım.',
      SPECIFY_REASON: 'Hesap kapatma nedenini belirtin.',
      MOBILE_VERIFICATION: 'Mobil Doğrulama',
      YOUR_PHONE_NUMBER: 'Telefon numaranız',
      VERIFICATION_CODE: 'Doğrulama kodu',
      ENTER_VERIFICATION_CODE:
        'Telefonunuza gönderilen doğrulama kodunu giriniz.',
      VERIFY: 'Doğrulayın',
      CONFIRM_PASSWORD_DOES_NOT_MATCH_PASSWORD:
        'Parolayı onaylayın ve parola eşleşmiyor',
      PHONE_MASK_IS_INVALID:
        'Telefon geçerli değil, lütfen tüm numaraları doldurun',
      CLOSE_ACCOUNT_SUCCEEDED:
        'Hesabınız başarıyla kapatıldı, birkaç saniye sonra giriş sayfasına yönlendirileceksiniz.',
      SELECT_ONE_OF_REASON_ERROR: 'Nedenlerden birini seçmelisiniz.',
      PASSWORD_MIS_MATCH: 'Mevcut şifre yanlış. Lütfen tekrar kontrol edin!',
      CHECK_PASSWORD_ERROR:
        'İsteğiniz başarısız oldu, lütfen doğru şifreyi girdiğinizden emin olun.',
      VERIFICATION_CODE_SENT: 'Telefon numaranıza gönderilen doğrulama kodu',
      WRONG_VERIFICATION_CODE_ERROR:
        'Doğrulama başarısız oldu, lütfen doğru kodu girdiğinizden emin olun.',
        ACCOUNT_NUMBER:'Hesap Numarası',
      SEGMENTATION:'Segmentasyon',
      ADD_NEW_SEGMENTATION:'Yeni Segmentasyon Ekle',
      GROUP_ID:'Grup Id',
      MCC_GROUP:'MCC Grup',
      MCC:'MCC',
      DESCRIPTION:'Açıklama',
      INSTALLMENT_INLAND:'Bireysel Yurtiçi Taksit',
      INSTALLMENT_ABROAD:'Bireysel Yurtdışı Taksit',
      INSTALLMENT_INLAND_COMMERCIAL:'Ticari Yurtiçi Taksit',
      INSTALLMENT_ABROAD_COMMERCIAL:'Ticari Yurtdışı Taksit',
      NEW_SEGMENTATION:'Yeni Segmentasyon',
      EDIT_SEGMENTATION:'Segmentasyonu Düzenle',
      END_DAY:'Gün Sonu',
      MEMBER_BUSINESS_ID:'Üye İş Yeri Id',
      TERMINAL_NO:'Terminal No',
      END_OF_DAY_DATE:'Gün Sonu Tarihi',
      NUMBER_OF_TRANSACTIONS:'İşlem Sayısı',
      TRANSACTIONS_AMOUNT:'İşlem Tutarı',
      TRANSACTIONS_CURRENCY:'İşlem Döviz Cinsi',
      COMMISSION_AMOUNT:'Komisyon Tutarı',
      SERVICE_FEE:'Hizmet Bedeli',
      AMOUNT_TO_ARRIVE_AT_THE_BANK:'Bankaya Gelmesi Gereken Tutar',
      BSMV_AMOUNT:'BSMV Tutarı',
      BANK_COMMISSION:'Banka Komisyonu',
      STARTING_DATE:'Başlangıç Tarihi',
      END_DATE:'Bitiş Tarihi',
      MEMBER_BUSINESS:'Üye İşyeri',
      TRANSFER_DESCRIPTION:'Transfer Açıklaması',
      DATETİME:'Tarih',
      TRANSFER_AMOUNT:'Transfer Tutarı',
      INSTALLMENT_COMMISSION:'Taksit Komisyon'
    },
    DASHBOARD: {
      DASHBOARD: 'Pano',
      COMPLETED: 'Tamamlandı',
      INTEGRATION:
        'İlk satışınız için hazır olun. Başlamak için kurulumu tamamlayın.',
      ORDER_AWAITING_CONFIRMATION: 'Onay Bekleyen Sipariş',
      TOTAL_ORDER: 'Toplam Sipariş',
      TOTAL_SALES_AMOUNT: 'Toplam Satış Tutarı',
      LATEST_CUSTOMERS: 'Son Müşteriler',
      LAST_ORDERS: 'Son Siparişler',
      TODAY_ORDERS: 'Siparişler/Bugün',
      ANALYSIS_REPORT_NOT_FOUND: 'Analiz Raporu Bulunamadı'
    },
    SALES_CHANNEL: {
      GET_PAID_BY_COLLECTION: 'Tahsilat ile ödeme alın',
      EASY_COLLECTION_LANDING_PAGE_DEFINITION2_TITLE: 'Size Özel!',
      EASY_COLLECTION_LANDING_PAGE_DEFINITION2_DESCRIPTION:
        'Pratik İşlem Pratik Tahsilat ile ödeme sayfanızda işletmenizin logosu, ticari unvanı ve iletişim bilgilerine yer verebilir, markanızı yansıtır, satış kaybı riskini de ortadan kaldırırsınız.',
      EASY_COLLECTION_LANDING_PAGE_DEFINITION1_TITLE:
        'Anında Ödeme Almaya Başlayın!',
      EASY_COLLECTION_LANDING_PAGE_DEFINITION1_DESCRIPTION:
        'POS cihazına ya da entegrasyona ihtiyaç duymadan kolayca sitenize ekleyebileceğiz güvenli altyapısıyla hemen bir Pratik İşlem Pratik Tahsilat çözümü sahibi olun, markanıza özel internet sayfanızdan tahsilata başlayın.',
      EASY_COLLECTION_LANDING_PAGE_TITLE: 'Tahsilat ile ödemeyi etkinleştirme',
      REMOVE_EASY_COLLECTION:
        "'{{assetType}}' öğesini silmek istediğinizden emin misiniz? Bu işlem geri alınamaz!",
      EASY_COLLECTION_ANYWHERE_ANYTIME_PAYMENT_OPPORTUNITY_DESCRIPTION:
        'Resmi tatiller, hafta sonları veya hafta içi ne olursa olsun, Pratik Tahsilat ile müşteriler günün her saatinde kolayca koleksiyon toplayabilir. Müşterinizin internet erişimi olduğu sürece, istediği zaman ödeme yapabilir.',
      EASY_COLLECTION_COMPATIBLE_WITH_ALL_DEBIT_AND_CREDIT_CARDS_DESCRIPTION:
        'Pratik Tahsilat, tüm banka ve kredi kartlarıyla uyumludur. Bu sayede müşterileriniz taksit seçeneklerinden veya karta özel avantajlardan da faydalanabilir.',
      EASY_COLLECTION_MAIL_ORDER_DESCRIPTION:
        'Posta siparişi formları geçmişte kaldı. Pratik Tahsilat üzerinden oluşturacağınız güvenli ödeme linkleri ile tüm e-tahsilat işlemlerinizi kolaylıkla gerçekleştirebilirsiniz.',
      EASY_COLLECTION_GENERAL_SETTINGS_DESCRIPTION:
        'Pratik Tahsilat ve ödeme ekranlarınız için genel ayarları yapılandırın.',
      EASY_COLLECTION_LOGO_FAVICON_DESCRIPTION:
        "Pratik Tahsilat sayfalarınızda görünmesini istediğiniz favicon'unuzu ve logonuzu girin. Böylece müşterilerinizi kurumsal ödeme ekranı ile karşılayabilirsiniz.",
      PERSONALIZE_EASY_COLLECTION_PAGE:
        'Pratik Tahsilat sayfalarınızı benzersiz markanızı yansıtacak şekilde kişiselleştirin.',
      PUBLIC_LINK: 'Genel Bağlantı',
      SELL_ON_YOUR_WEBPAGES: 'Web Sayfalarınızda Satış Yapın',
      EASY_COLLECTION: 'Pratik Tahsilat',
      EASY_COLLECTION_DESCRIPTION:
        '7/24 aktif tahsilat kanalı oluşturma. Ödemelerinizi müşterilerinizin kredi kartlarından hızlı ve güvenli bir şekilde tahsil edin.',
      SELL_ANYWHERE: 'Her Yerde Satış',
      SELL_ON_WEBSITE: 'Web Sitesinde Satış',
      SELL_ON_WEBSITE_DESCRIPTION:
        'Müşterileri dönüştüren ve satış yapan profesyonel, %100 mobil uyumlu bir online mağaza oluşturun.',
      MANAGE_WEBSITE: 'Web Sitesini Yönet',
      MANAGE_SHOPILINK: "Pratik Link'i yönet",
      MANAGE_MOBILE: 'Yönet',
      LEARN_MORE: 'Daha fazla bilgi edin',
      GET_PAID_WITH_LINK: 'Link ile ödeme alın',
      GET_PAID_WITH_LINK_DESCRIPTION:
        'Pratik Link bağlantılarınızı tüm dijital kanallarda paylaşın ve güvenli bir şekilde ödeme almaya başlayın.',
      SELL_ON_MOBILE_APP: 'Mobil Uygulamada Satış',
      SELL_ON_MOBILE_APP_DESCRIPTION:
        'Mağazanız veya markanız için güzel bir iOS ve Android uygulamasını hızla tasarlayın ve başlatın. Satışlarınızı artırın, elde tutma oranınızı artırın ve müşterilerinizin sevdiği bir marka oluşturun...',
      SELL_ON_SOCIAL_MEDIA: 'Sosyal Medyada Satış Yapın',
      SELL_ON_SOCIAL_MEDIA_DESCRIPTION:
        "Mağazanızı Facebook'a bağlayarak ürünlerinizi doğrudan Facebook işletme sayfanız üzerinden sergileyebilir ve satabilir ve milyarlarca potansiyel müşteriye ulaşabilirsiniz.",
      SELL_ON_ONLINE_MARKET_PLACES:
        'Pazaryerlerinde ve farklı kanallarda satış yapın',
      SELL_ON_ONLINE_MARKET_PLACES_DESCRIPTION:
        'Daha fazla müşteri çekin ve popüler pazaryerlerinde, karşılaştırma sitelerinde satış yapın.<br/>Mağazanızı muhasebe yazılımı ve e-fatura entegrasyonu ile güçlendirin',
      WEBSITE: 'İnternet sitesi',
      WEBSITE_DESCRIPTION:
        'Online mağazanız kuruldu ve kullanıma hazır! Sadece işletme bilgilerinizi doldurun ve hemen satışa başlayın.',
      SHOPILINK: 'Pratik Link',
      SHOPILINK_DESCRIPTION:
        'Bir bağlantı oluşturun, istediğiniz zaman ve istediğiniz yerde satış yapın.',
      ANDROID_IOS: 'Android ve iOS',
      ANDROID_IOS_DESCRIPTION:
        'Mağazanızı müşterilerin akıllı telefonlarına indirebilecekleri bir mobil uygulamaya dönüştürün. Tamamen native, yüksek performanslı.',
      ANDROID_IOS_DESIGN:
        'Markanızı ve kimliğinizi yastımak için mobil uygulamanızı kişiselleştirin.',
      ANDROID_IOS_GENERAL:
        'Mobil uygulamanız için genel ayarları yapılandırın.',
      SDK_INTEGRATION: 'SDK Entegrasyonu',
      SDK_INTEGRATION_DESCRIPTION:
        'Pratik İşlem, geliştiricilerin her türden e-ticaret deneyimi oluşturmak için güvendikleri alışveriş sepeti çözümüdür. Dakikalar içinde herhangi bir mobil uygulamaya özel e-ticaret eklemek için kullanabilirsiniz.',
      FACEBOOK_SHOP: 'Facebook Mağazası',
      FACEBOOK_SHOP_DESCRIPTION:
        'Facebook Marketplace, öğeleri keşfetmek, satın almak ve satmak için uygun bir yer. İnsanlar aradıklarını bulabilirler.',
      INSTAGRAM_SHOPPING: 'Instagram Alışverişi',
      INSTAGRAM_SHOPPING_DESCRIPTION:
        "Ürünlerinizi Instagram'da sergileyin ve alışveriş yapılabilir Instagram gönderileriyle hedef kitlenizi alıcılara dönüştürün.",
      GOOGLE_SHOPPING: 'Google Alışveriş',
      GOOGLE_SHOPPING_DESCRIPTION:
        "Google'ın Alışveriş sekmesi ve Google Alışveriş Reklamları ile ürünlerinizi Google'da göstermenin ve reklamını yapmanın hızlı ve kolay bir yolu.",
      AMAZON: 'Amazon',
      AMAZON_DESCRIPTION:
        "Web'deki en popüler pazarlardan biri olan Amazon'da ürünlerinizi listeleyin ve daha fazla müşteri edinin.",
      HEPSIBURADA: 'Hepsiburada',
      HEPSIBURADA_DESCRIPTION:
        'Hepsiburada mağazasına toplu ve kolay ürün girişi yapabilir, siparişlerinizi anında kontrol edebilir, anında fatura kesebilirsiniz.',
      TRENDYOL: 'Trendyol',
      TRENDYOL_DESCRIPTION:
        'Trendyol mağazasına toplu ve kolay ürün girişi yapabilir, siparişlerinizi anında kontrol edebilir ve anında fatura kesebilirsiniz.',
      POWERED_BY_MOBIROLLER: 'Mobiroller tarafından desteklenmektedir',
      MOBILE_SDK: 'Mobil SDK',
      COMING_SOON: 'Yakında',
      SELL_ON_MOBILE_APP_DESCRIPTION_IN_MOBILE_EMPTY_PAGE:
        'Mağazanızı müşterilerin akıllı telefonlarına indirebilecekleri bir mobil uygulamaya dönüştürün.',
      ACTIVATING_SHOPPING_APP: 'Alışveriş Uygulamasını Etkinleştirme',
      HIGH_TECH_MOBILE_APPS: 'Yüksek Teknoloji Mobil Uygulamaları',
      CHANGING_DEMANDS:
        'Değişen talepler veya güncellemeler artık bir sorun değil. İşinize odaklanın ve mobil uygulamanız çevik kalmak için otomatik olarak çalışmaya devam edecektir.',
      IOS_AND_ANDROID_COMPATIBLE: 'iOS ve Android uyumlu',
      IOS_AND_ANDROID_OPERATING_SYSTEM:
        "iOS ve Android işletim sistemleri, tüm mobil cihazların %97'sini kapsar.<br /><br />Mağazanız için iOS ve Android uygulamaları oluşturabilirsiniz. Tam olarak istediğiniz gibi görünmesini ve çalışmasını sağlayabilirsiniz. En iyi yanı, kodlama gerektirmemesidir.",
      ENGAGE_YOUR_AUDIENCE:
        'Push Bildirimleri ile hedef kitlenizin ilgisini çekin',
      COMMUNICATE_IN_REALTIME:
        'Anında iletme bildirimleri ile gerçek zamanlı iletişim kurun, terk edilen sepetleri kurtarmak, promosyonlar yürütmek veya sadece yeni satışlar oluşturmak için doğrudan bir alışverişçinin telefonuna giden basit mesajlar.',
      MOBILE_SALES_CHANNEL: 'Mobil Satış Kanalı',
      ACTIVATING_PAYMENT_BY_LINK: 'Bağlantı ile Ödemeyi Etkinleştirme',
      FORGOT_THE_OLD_WAYS: 'Eski Yolları Unutun!',
      MAIL_ORDER_DESCRIPTION:
        'Posta siparişi formları geçmişte kaldı. Pratik Link üzerinden oluşturacağınız güvenli ödeme linkleri ile tüm e-tahsilat işlemlerinizi kolaylıkla gerçekleştirebilirsiniz.',
      COMPATIBLE_WITH_ALL_DEBIT_AND_CREDIT_CARDS:
        'Tüm Banka ve Kredi Kartları ile Uyumlu',
      COMPATIBLE_WITH_ALL_DEBIT_AND_CREDIT_CARDS_DESCRIPTION:
        'Pratik Link, tüm banka ve kredi kartlarıyla uyumludur. Bu sayede müşterileriniz taksit seçeneklerinden veya karta özel avantajlardan da faydalanabilir.',
      ANYWHERE_ANYTIME_PAYMENT_OPPORTUNITY:
        'Her Yerde ve Her Zaman Ödeme İmkanı',
      ANYWHERE_ANYTIME_PAYMENT_OPPORTUNITY_DESCRIPTION:
        'Resmi tatiller, hafta sonları veya hafta içi ne olursa olsun, Pratik Link ile müşteriler günün her saatinde kolayca koleksiyon toplayabilir. Müşterinizin internet erişimi olduğu sürece, istediği zaman ödeme yapabilir.',
      SHOPPING_APP: 'Alışveriş Uygulaması',
      YOUR_CONNECTED_APP: 'Bağlı Uygulamalarınız',
      YOUR_CONNECTED_APP_DESCRIPTION:
        'Mağazanız için iOS ve Android uygulamaları oluşturabilirsiniz. Tam olarak istediğiniz gibi görünmesini ve çalışmasını sağlayabilirsiniz. En iyi yanı, kodlama gerektirmemesidir.',
      LEARN_MORE_ABOUT_HOW_TO:
        'Nasıl yapılacağı hakkında daha fazla bilgi edinin',
      CONNECT_A_NEW_APP: 'Yeni Bir Uygulama Bağlayın',
      ADD_FIRST_APP:
        'Oh, hiç uygulama eklemediniz.<br />İlk uygulamanızı ekleyin.',
      CONNECT_NEW_APP: 'Yeni Bir Uygulama Bağlayın',
      DESIGN_AND_CONTENT: 'Tasarım ve İçerik',
      PERSONALIZE_SHOPILINK_PAGE:
        'Markanızı ve kimliğinizi yastımak için Pratik Link sayfanızı kişiselleştirin.',
      SLIDER_DESCRIPTION:
        'Uygulamanızda kullanmak için çeşitli kaydırıcılar oluşturabilirsiniz. Oluşturduğunuz kaydırıcıları bir kategoriye, ürüne veya herhangi bir web adresine yönlendirebilirsiniz.',
      MANAGE_SLIDER: 'Kaydırıcıyı Yönet',
      CATEGORY_LAYOUT: 'Kategori Düzeni',
      CHOOSE_LAYOUT_FOR_CATEGORIES:
        'Ana sayfada kategoriler için düzeni seçebilirsiniz.',
      GENERAL_SETTINGS: 'Genel Ayarlar',
      GENERAL_SETTINGS_DESCRIPTION:
        'Pratik Link ve ödeme ekranlarınız için genel ayarları yapılandırın.',
      ACTIVE_PASSIVE_DESCRIPTION:
        'Link ödeme özelliğini istediğimiz zaman aktif/pasif hale getirebiliriz.',
      SLIDE_MANAGEMENT: 'Slayt Yönetimi',
      APP_CONNECTED_SUCCESSFULLY: 'Uygulama başarıyla bağlandı.',
      ENABLE_APP: 'Uygulamayı etkinleştir',
      CONFIRM_MSG_FOR_ENABLE_APP:
        "'{{appName}}' özelliğini etkinleştirmek istiyor musunuz?",
      DISABLE_APP: 'Uygulamayı devre dışı bırak',
      CONFIRM_MSG_FOR_DISABLE_APP:
        "{{appName}}'ı devre dışı bırakmak istiyor musunuz?",
      YOUR_WEBSITE: 'Senin internet siten',
      SELL_ON_WEBSITE_DESCRIPTION_IN_WEBSITE_PAGE:
        'Anında Site, bir web sitesinde online satış yapmaya başlamak için ihtiyacınız olan her şeyi birleştirir. Kullanımı kolay düzenleyici ile Hızlı Sitenizde hızlı değişiklikler yapın.',
      ONLINE_STORE: 'Online mağaza',
      ONLINE_STORE_DESCRIPTION:
        'Online vitrinli Hızlı Siteniz zaten kuruldu. İşletme bilgilerinizi doldurmanız ve hemen satışa başlamanız yeterli.',
      DESIGN_AND_CONTENT_DESCRIPTION:
        'Benzersiz markanızı yansıtmak için Hızlı Sitenizi ve vitrininizin görünümünü kişiselleştirin.',
      SITE_APPEARANCE: 'Site Görünümü',
      SITE_APPEARANCE_DESCRIPTION:
        'Mağazanızın temalarını yönetin. online mağazanızın görünümünü değiştirmek için temalar ekleyin ve yayınlayın.',
      YOUR_CURRENT_THEME: 'Mevcut Temanız',
      CHANGE_THEME: 'Temayı değiştir',
      CUSTOMIZE_DESCRIPTION:
        'Pratik İşlem, geliştiricilerin her türden e-ticaret deneyimi oluşturmak için güvendikleri alışveriş sepeti çözümüdür. Dakikalar içinde herhangi bir mobil uygulamaya özel e-ticaret eklemek için kullanabilirsiniz.',
      HEADER_META_TAGS_SITE_VERIFICATION:
        'Başlık Meta Etiketleri ve Site Doğrulaması',
      WEBSITE_URL: "Web Sitesi URL'si",
      USER_OUR_DOMAIN: 'Alt alanımızı kullanın',
      USER_OUR_DOMAIN_DESCRIPTION:
        'Henüz kendi alan adınız yoksa, web siteniz için alt alan adımızı kullanabilirsiniz. Örneğin, mystore.company.site veya cool-tshirts.company.site',
      USE_YOUR_OWN_DOMAIN_NAME: 'Kendi alan adınızı kullanın',
      USE_YOUR_OWN_DOMAIN_NAME_DESCRIPTION:
        'Web siteniz için kendi alan adınızı oluşturabilirsiniz. Örneğin store.example.com',
      WEBSITE_URL_TIP1:
        'Alan adı kayıt sitenizin kontrol panelinde oturum açın',
      WEBSITE_URL_TIP2:
        'example.com alan adınız için bir A kaydı oluşturun ve bunu IP adresimize yönlendirin: 94.130.182.174',
      WEBSITE_URL_TIP3:
        'Web sitenizi www.example.com adresinde de kullanılabilir hale getirmek için alan adınız için bir CNAME kaydı oluşturun ve bunun için ad/takma ad olarak bir www belirtin',
      SUCCESS_WE_DONE_IT: 'Başarı! Sen yaptın.',
      DOMAIN_NAME_AND_SITE_ADDRESS: 'Alan adı ve site adresi',
      DOMAIN_NAME_AND_SITE_ADDRESS_DESCRIPTION:
        'İşletmenizin adını web adresinize ekleyerek müşterilerin sizi bulmasına ve kalabalığın arasından sıyrılmasına yardımcı olun.',
      SITE_ADDRESS: 'Site adresi',
      SITE_ADDRESS_DESCRIPTION:
        'Web siteniz varsa satın aldığınız alan adı ile bağlantı kurun veya Pratik İşlem tarafından sağlanan ücretsiz bir adres kullanın.',
      CURRENT_ADDRESS: 'Mevcut adres',
      CHANGE_ADDRESS: 'Adres değiştir',
      SEO_SETTINGS: 'Seo Ayarları',
      SEO_SETTINGS_DESCRIPTION:
        'Sitenizin Google gibi arama motorlarıyla nasıl etkileşime girdiğini kontrol edin.',
      HEADER_META_TAGS_SITE_VERIFICATION_DESCRIPTION:
        'Google Merchant Center, Google Search Console ve Pinterest gibi bazı hizmetler, site sahipliğinin doğrulanmasını gerektirir. Doğrulama tamamlandıktan sonra, bu hizmetler tarafından sağlanan birden çok trafik optimizasyon aracına erişebilirsiniz. Sitenizi doğrulamak için siteye özel bir HTML etiketi ekleyin.',
      ALLOW_SEARCH_ENGIN:
        'Arama motorlarının Hızlı Siteyi endekslemesine izin ver',
      ALLOW_SEARCH_ENGIN_DESCRIPTION:
        'Bu, arama motorlarının Hızlı Sitenizi arama sonuçlarında görüntülemesini sağlar. Hızlı Sitenizin arama sonuçlarında görünmesini istemiyorsanız bu seçeneği kapatın.',
      CREATE_LINK: 'Bağlantı Oluştur',
      EDIT_LINK: 'Bağlantıyı Düzenle',
      LINK_NAME: 'Bağlantı Adı',
      LINK: 'Bağlantı',
      DELETE_LINKS: 'Bağlantıları Sil',
      DELETE_LINK_TITLE: 'Silinen Bağlantı',
      DELETE_LINK_MESSAGE:
        "'{{linkName}}' silinsin mi? Bu işlem geri alınamaz.",
      DELETE_LINK_TITLE_SOME: 'Silinen Bağlantılar',
      DELETE_LINK_MESSAGE_SOME:
        "'Bağlantılar' silinsin mi? Bu işlem geri alınamaz.",
      CREATE_SHOPILINK: 'Pratik Link oluştur',
      NEW_SERVICE: 'Yeni Hizmet',
      SERVICE_NAME: 'hizmet adı',
      ADD_SERVICE: 'Hizmet Ekle',
      DISPLAY_CATEGORY_NAME_BELOW_THE_IMAGE:
        'Resmin altında kategori adını görüntüle',
      DISPLAY_CATEGORY_NAME_BELOW_THE_IMAGE_DESCRIPTION:
        'Tüm kategori resimlerinizin stili farklıysa veya kategorileri temsil etmek için simgeler kullanıyorsanız seçiminiz.',
      HIDE_CATEGORY_IMAGE: 'Kategori resimlerini gizle',
      HIDE_CATEGORY_IMAGE_DESCRIPTION:
        'Kategorilerinizde resim yükleme yoksa kategori resimlerini gizleyin ve bunun yerine kategori adlarını görüntüleyin',
      HIDE_CATEGORY_NAME: 'Kategori adlarını gizle',
      HIDE_CATEGORY_NAME_DESCRIPTION:
        'Kategori resimlerine zaten gömülüyse kategori adlarını gizleyin',
      FEATURE_IS_NOT_READY: 'Bu özellik henüz hazır değil.',
      THIS_WILL_ONLY_VISIBLE_TO_YOU: 'Bu sadece size görünür olacak',
      REQUEST_BILLING: 'Alıcıdan fatura ve nakliye bilgilerini isteyin',
      CONTENTS: 'İçindekiler',
      ADD_SELECT_PRODUCT: 'Ürün Seç Ekle',
      ADD_NEW_SERVICE: 'Yeni Hizmet Ekle',
      LOGO_FAVICON: 'Logo ve Favicon',
      LOGO_FAVICON_DESCRIPTION:
        'Link ile ödeme alma sayfanızda görünmesini istediğiniz logonuzu ve site ikonunuzu girin. Böylece markanıza özel bir ödeme ekranı ile müşterilerinizi karşılayabilirsiniz.',
      SELECT_IMAGE_AS_LOGO: 'Logonuzu yükleyin',
      SELECT_IMAGE_AS_FAVICON: 'Favicon yükleyin',
      WE_ACTIVE_PASSIVE_LINK:
        'Link ile ödeme alma özelliğini istediğimiz zaman aktif/pasif yapabilirsiniz.',
      LINK_WORKS_ONLY:
        "Link ile ödeme alma özelliği yalnızca PARAM ödeme yöntemiyle çalışır. Lütfen <a href='/store-settings/payment' class='alert-link'>ödeme yöntemleri</a> ekranına gidin ve PARAM ödeme yöntemini etkinleştirin.",
      APP_NAME: 'Uygulama ismi',
      SUCCESSFUL_CONNECTION_OF_APP: 'Yeni Uygulama başarıyla bağlandı',
      SUCCESSFUL_EDIT_OF_APP: 'Uygulamanız başarıyla düzenlendi',
      FAILED_CONNECTION_OF_APP: 'Uygulama bağlanırken hata oluştu!',
      OUT_MOBILE_CHANNEL_LIMIT:
        "4'ten fazla uygulamaya bağlanmanıza izin verilmiyor",
      SUCCESSFUL_ENABLE_OF_APP: 'Uygulamanız başarıyla etkinleştirildi',
      FAILED_ENABLE_OF_APP:
        'Maalesef, uygulamanızı etkinleştirme başarısız oldu!',
      SUCCESSFUL_DISABLE_OF_APP: 'Uygulamanız başarıyla devre dışı bırakıldı',
      FAILED_DISABLE_OF_APP:
        'Ne yazık ki, uygulamanızı devre dışı bırakmak başarısız oldu!',
      ACTIVE_PASSIVE_DESCRIPTION_FOR_MOBILE_CHANNEL:
        'Mobil uygulama satış kanalını istediğimiz zaman aktif/pasif yapabilirsiniz.',
      ACTIVATION_OF_SALE_CHANNEL_FAILED:
        'Maalesef {{channelType}} satış kanalının etkinleştirilmesi başarısız oldu!',
      REACHED_TO_MAX_CONNECTED_APP:
        'Yeni uygulamayı bağlamanıza izin verilmiyor!',
      SALE_CHANNEL_ALREADY_EXIST: '{{saleChannel}} zaten var!',
      SELECT_PLATFORM: 'İstediğiniz platformu seçin',
      SALES_CHANNEL_ENABLED:
        '{{saleChannelType}} satış kanalı başarıyla etkinleştirildi',
      SALES_CHANNEL_DISABLED:
        '{{saleChannelType}} satış kanalı başarıyla devre dışı bırakıldı',
      CHANGING_SALE_CHANNEL_ACTIVATION_FAILED:
        '{{saleChannelType}}  satış kanalı etkinleştirilemedi!',
      YOUR_MOBILE_CHANNEL_IS_PASSIVE:
        'Mobil satış kanalınız şu an pasif durumda olduğu için satış kanalınıza eklemiş olduğunuz uygulamalar çalışmayabilir!',
      NO_SHOPILINK_MESSAGE:
        "Pratik Link'i ekleyin! Ürünlerinizi mağazanızda paketlemek ve tek seferde satmak için Pratik Link'i kullanın.",
      DELETE_SHOPILINK: {
        TITLE: 'Pratik Link silindi',
        MESSAGE: "'{{shopilinkName}}' silinsin mi? Bu işlem geri alınamaz.",
        TITLE_SOME: 'Silinen Shopilinkler',
        MESSAGE_SOME: "'Shopilinks' silinsin mi? Bu işlem geri alınamaz."
      },
      SUCCESSFUL_REMOVING_FAVICON_LOGO:
        'kanalınızın {{settingType}}u başarıyla kaldırıldı',
      FAILED_REMOVING_FAVICON_LOGO:
        'Kanalınızdan {{settingType}} kaldırılamadı!',
      SUCCESSFUL_UPDATING_FAVICON_LOGO:
        '{{settingType}} kanalınız başarıyla güncellendi',
      FAILED_UPDATING_FAVICON_LOGO:
        'Kanalınızın {{settingType}} güncellenmesi başarısız oldu!',
      DELETE_LINK: 'Bağlantıyı sil',
      NO_PRODUCT_SERVICE_SELECTED: 'Henüz ürün/hizmet seçilmedi.',
      MAX_STOCK_EXCEEDED: 'Sepetinizdeki ürünlerden biri stoktaki sayıdan az',
      NOT_ENOUGH_COUNT_IN_STOCK: "{{productName}} stok sayısı 1'den az",
      SHOPILINK_PASSIVE_ALERT_MSG:
        'Pratik Link satış kanalınız şu anda aktif değil, bu daha önce oluşturduğunuz bağlantıların çalışmamasına neden olabilir!',
      PRODUCT_NOT_FOUND:
        'İşleminiz başarıyla gerçekleştirildi ancak bazı ürünler bulunamadığından seçilen ürünlerden bazılarını ekleyemedik.',
      OUT_OF_STOCK_ERROR:
        'Linkteki ürünlerden bir veya birkaçı stokta kalmamıştır. Bu nedenle ödeme bağlantınız devre dışı bırakıldı. Lütfen stokları kontrol edin',
      PRODUCT_IS_OUT_OF_STOCK_ERROR:
        'Bu Ürün stoklarımızda kalmamıştır, shopilinkinize ekleyemiyoruz.',
      SHOPILINK_ADDED_PARTIALLY:
        'İşleminiz başarıyla gerçekleştirildi ancak stokta kalmadığı için seçilen ürünlerden bazılarını ekleyemedik.',
      PAYMENT_IS_NOT_ACTIVE_MESSAGE:
        'Link ile ödeme alma özelliği için bir adet online ödeme yönetemini aktifleştirmelisiniz. Lütfen ödeme yöntemleri ekranına gidin ve bir ödeme yöntemini etkinleştirin.',
      YOUR_WEB_CHANNEL_IS_PASSIVE:
        'Web satış kanalınız şu anda pasif, bu web sitenizde kimlik doğrulama sorunlarına yol açabilir!',
      DOMAIN_UPDATED_SUCCESSFULLY: 'Mağaza alan adınız başarıyla güncellendi',
      DOMAIN_UPDATED_FAILED:
        'Maalesef mağaza alanı güncellemeniz başarısız oldu!',
      META_TAGS_UPDATED_SUCCESSFULLY: "Meta tag'leriniz başarıyla güncellendi",
      META_TAGS_UPDATED_FAILED:
        'Maalesef meta tag güncellemeniz başarısız oldu!',
      INVALID_DOMAIN:
        'Alan adlarından biri geçersiz veya zaten başka bir kullanıcı tarafından alınmış',
      YOUR_WEB_SETTING_HAS_NO_INFO:
        'Maalesef şu anda web ayarınız için bilgi yok',
      SELL_ON_GOOGLE: "Google'da satış yapın",
      SELL_ON_GOOGLE_DESCRIPTION:
        "Ürünlerinizi Google'da sergilemenin hızlı ve kolay yolu",
      SELL_ON_GOOGLE_DESCRIPTION_IN_ACTIVATE_PAGE:
        'Mağazanızı, müşterilerin akıllı telefonlarına indirebilecekleri bir mobil uygulamaya dönüştürün.',
      LIST_YOUR_PRODUCTS: "Ürünlerinizi Google'da ücretsiz olarak listeleyin",
      LIST_YOUR_PRODUCTS_DESCRIPTION:
        "Ürünleriniz hakkında bilgi vererek başlayın. <br/> Ülkenizi ve ürünlerinizin kategorisini seçin, paketiniz ürünlerinizi Google'da satmaya hazır olsun.",
      CUSTOMERS_CLICK_TO_PURCHASE_YOUR_PRODUCTS:
        'Müşteriler ürünlerinizi satın almak için tıklayın',
      CUSTOMERS_CLICK_TO_PURCHASE_YOUR_PRODUCTS_DESCRIPTION:
        "Kullanıcılar ürünlerinizi aradığında ürünleriniz Google Arama'da, Haritalar'da ve daha fazlasında görünebilir. Bir müşteri tıkladığında, satın alma işlemini tamamlaması için onu çevrimiçi mağazanıza yönlendirebilirsiniz.",
      GORW_YOUR_BUSINESS_WITH_PERSONALIZED_INSIGHTS:
        'Kişiselleştirilmiş içgörülerle işinizi büyütün',
      GORW_YOUR_BUSINESS_WITH_PERSONALIZED_INSIGHTS_DESCRIPTION:
        'Sitenize veya yerel mağazanıza gelen trafiği artırmanıza yardımcı olabilmeniz için müşterilerin işletmeniz ve ürünlerinizle nasıl etkileşimde bulunduğu hakkında eyleme geçirilebilir içgörüler sağlayacağız.',
      HELP_BOOST_YOUR_SALES_WITH_PAID_REKLAM:
        'Ücretli reklamlarla satışlarınızı artırmaya yardımcı olun',
      HELP_BOOST_YOUR_SALES_WITH_PAID_ADVERTISING_DESCRIPTION:
        'Hazır olduğunuzda, satışları artıran reklamlarla daha fazla müşteriye ulaşmak için ürünlerinizin tanıtımını yapabilirsiniz.',
      ACTIVATING_GOOGLE_SHOPPING: 'Google Alışverişi Etkinleştirme',
      GOOGLE_STORE: 'Google Mağazası',
      GOOGLE_STORE_DESCRIPTION:
        "Google Store'da gönderilmesi için ürünlerinizden oluşan bir paket sağlayın",
      PROVIDE_A_PACKAGE: 'Bir paket sağlayın',
      DOWNLOAD_PRODUCTS_FILE: 'Ürünler Dosyasını İndirin',
      GOOGLE_PRODUCTS_DESCRIPTION:
        "Ürünlerinizin ülkesini ve kategorisini seçin, paketiniz bir dosyada veya bir URL aracılığıyla hazır olsun. <br/> Dosyanızı doğrudan içe aktarabilir veya paket URL'nizi Google ticaret merkezine yapıştırabilir ve Google'ın ürünlerinizi içe aktarmasına izin verebilirsiniz.",
      PACKAGE_URL: "Paket URL'si",
      MAGENTY: 'Magenty',
      MAGENTY_DESCRIPTION:
        '<p><strong>Ürün, Stok, Sipariş, Fatura, Kargo</strong> ve <strong>Mesaj Yönetiminizi</strong> tek ekrandan kolayca yönetin. Hemen entegrasyonu başlatın ve yüzlerce ürünü bir çok pazaryerinde hemen yayınlayın.</p>',
      SELL_ON_MAGENTY: "Magenty'de Satış",
      SELL_ON_MAGENTY_DESCRIPTION:
        '<br/><p><strong>Ürün, Stok, Sipariş, Fatura, Gönderi</strong><br/> ve <strong>Mesaj Yönetimini</strong> tek bir ekrandan kolayca yönetin. Entegrasyonu şimdi başlatın ve yüzlerce<br/>birçok pazarda yüzlerce ürün yayınlayın.</p>',
      ACTIVATING_MAGENTY: "Magenty'yi etkinleştir",
      BULK_PRODUCT_TRANSFER: 'Toplu Ürün Transferi',
      BULK_PRODUCT_TRANSFER_DESCRIPTION:
        'Ürünlerinizi toplu olarak pazaryerlerine saniyeler içinde aktarabilirsiniz. Birkaç kolay adımda tüm ürünlerinizi inceleyin ve akıllı raporlarla transfer süreçlerinizi hızlandırın.',
      ADVANCED_REPORTING: 'Gelişmiş Raporlama',
      ADVANCED_REPORTING_DESCRIPTION:
        'Esnek ve gelişmiş filtrelerle istediğiniz gibi raporlar oluşturun ve bunları Excel olarak indirin. Cironuzu, kârınızı, satış adetlerinizi hem grafikte hem de tablo üzerinde görebilirsiniz.',
      MAGENTY_CONFIGURATION_SUCCESSFULY_COMPLETED:
        "Tebrikler, Magenty'yi başarıyla yapılandırdınız",
      MAGENTY_CONFIGURATION_FAILED:
        'Maalesef yapılandırma işlemi başarısız oldu!',
      CONFIGURE_MAGENTY: "Magenty'yi Yapılandır",
      CONFIGURE_MAGENTY_WARNING:
        "Lütfen yapılandırmanıza dikkat edin, politikalar nedeniyle Magenty'yi yalnızca bir kez yapılandırmak mümkündür",
      REQUEST_FOR_MAGENTY_CONFIGURATION_CHANGE:
        'Yapılandırmanız <b class="h6">{{status}}</b>, Yapılandırmanızı değiştirmek istiyorsanız lütfen destek ekibiyle iletişime geçin',
      UNSPECIFIED_MAGENTY_CONFIGURATION_STATUS:
        'Yapılandırma durumunuz şu anda <b class="h6">Belirtilmemiş</b>. Yapılandırmanızı değiştirmek istiyorsanız lütfen destek ekibiyle iletişime geçin',
      MAGENTY_CONFIGURATION: 'Magenty Yapılandırması',
      MAGENTY_CONFIGURATION_CONFIRMATION_MESSAGE:
        'Magenty için geçerli yapılandırmayı ayarlamak istediğinizden emin misiniz?',
      MAGENTY_OWNER_NAME: ' Mağaza Sahinin Adı',
      MAGENTY_OWNER_SURNAME: 'Mağaza Sahibinin Soyadı',
      MAGENTY_PHONENUMBER: 'GSM (Telefon Numarası)'
    },
    WEB_HOOK: {
      WEB_HOOK: 'Web Hook',
      WEB_HOOK_DESCRIPTION:
        'WebHook, projede gerçekleşen her işlemden sonra, örneğin bir marka oluşturulduktan/güncellendikten veya silindikten sonra, hatta yeni bir sipariş oluşturulduğunda istek gönderir, WebHook devreye girer',
      WEB_HOOK_ACTIVE_MESSAGE:
        'Tebrikler. Web Hook özelliğini etkinleştirdiniz',
      WEB_HOOK_DE_ACTIVE_MESSAGE: 'Web Hook özelliği mağazanızdan kaldırıldı',
      MAX_RETRY_COUNT: 'Maksimum yeniden deneme sayısı',
      EMPTY_WEB_HOOK_SECTION_MESSAGE:
        'İlk web kancanızı oluşturun! Operasyonlardan sonra olayları tetikleyin ve bunun arkasındaki sihrin ne olacağını görün.',
      CREATE_NEW_WEBHOOK: 'Yeni Web Hook Oluştur',
      CREATE_WEBHOOK: 'Web Hook oluştur',
      REQUESTS: 'İstekler',
      DELETE_REQUEST: 'İsteği sil',
      HEADERS: 'Başlıklar',
      ADD_NEW_HEADER: 'Yeni Başlık Ekle',
      DELETE_HEADER: 'Başlığı Sil',
      MAXIMUM_REQUESTS_HEADER_MESSAGE:
        'İsteklerinize yalnızca 4 başlık eklemenize izin verilir',
      MAXIMUM_REQUESTS_MESSAGE:
        "Her Webhook'a yalnızca 3 istek eklemenize izin verilir",
      ADD_REQUEST: 'Yeni İstek Ekle',
      ADD_WEB_HOOK: 'Webhook ekle',
      EDIT_WEB_HOOK: 'Webhook Düzenlemek',
      DELETE_DIALOG_TITLE: 'Webhook Silmek',
      DELETE_DIALOG_MESSAGE:
        "'{{webhookName}}' silinsin mi? Bu işlem geri alınamaz.",
      SUCCESSFUL_DELETE: 'Webhook silme işlemi başarıyla tamamlandıy',
      FAILED_DELETE: 'Maalesef Webhook silme işlemi başarısız oldu!',
      SUCCESSFUL_CREATE: 'Webhook ekleme başarıyla tamamlandı',
      FAILED_CREATE: 'Maalesef Webhook eklenemedi!',
      SUCCESSFUL_EDIT: 'Webhook başarıyla tamamlandı',
      FAILED_EDIT: 'Maalesef Webhook düzenlemesi başarısız oldu!',
      MAXIMUM_WEB_HOOK_ERROR_MESSAGE:
        "Her Webhook'a yalnızca 10 webhook eklemenize izin verilir"
    },
    primeng: {
      startsWith: 'İle Başlar',
      contains: 'İçerir',
      notContains: 'İçermez',
      endsWith: 'İle Biter',
      equals: 'Eşittir',
      notEquals: 'Eşit Değildir',
      noFilter: 'Filtresiz',
      lt: 'Daha az',
      lte: 'Küçük veya eşit',
      gt: 'Daha fazla',
      gte: 'Büyük veya eşit',
      is: 'Is',
      isNot: 'Is not',
      before: 'Before',
      after: 'After',
      clear: 'Clear',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Evet',
      reject: 'Hayır',
      choose: 'Seç',
      upload: 'Yüke',
      cancel: 'İptal',
      dayNames: [
        'Pazar',
        'Pazartesi',
        'Salı',
        'Çarşamba',
        'Perşembe',
        'Cuma',
        'Cumartesi'
      ],
      dayNamesShort: ['Pzr', 'Pzrts', 'Sal', 'Çarş', 'Perş', 'Cum', 'Cmrt'],
      dayNamesMin: ['Pz', 'Pzt', 'Sa', 'Ça', 'Pe', 'Cm', 'Cmt'],
      monthNames: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık'
      ],
      monthNamesShort: [
        'Ock',
        'Şub',
        'Mar',
        'Nis',
        'May',
        'Haz',
        'Tem',
        'Ağu',
        'Eyl',
        'Eki',
        'Kas',
        'Ara'
      ],
      today: 'Bugün',
      weekHeader: 'Wk',
      weak: 'Zayıf',
      medium: 'Medium',
      strong: 'Güçlü',
      passwordPrompt: 'Bir şifre girin',
      emptyMessage: 'Sonuç bulunamadı.',
      emptyFilterMessage: 'Sonuç bulunamadı.'
    }
  }
};
