import { GenericState, GenericStoreStatus } from '@pratik-islem/core';
import { createReducer, on } from '@ngrx/store';
import {
  loadUserProfileInformation,
  loadUserProfileInformationFailure,
  loadUserProfileInformationSuccess,
  loadUserProfileInformationWithEmail,
  loadUserProfileInformationWithEmailSuccess,
  updateUserProfileInformation,
  updateUserProfileInformationFailure,
  updateUserProfileInformationSuccess
} from './user-profile.actions';

import { UserProfileFullInformationModel } from '../../models';

export const userProfileFeatureKey = 'feature-user-profile';

export class UserProfileFeatureState implements GenericState<any> {
  data: any;
  userFullInformation!: UserProfileFullInformationModel | null;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}

const initialState: UserProfileFeatureState = {
  data: null,
  userFullInformation: null,
  error: null,
  status: 'pending'
};

export const userProfileFeatureReducer = createReducer(
  initialState,
  on(loadUserProfileInformation, (state) => ({ ...state, status: 'loading' })),
  on(loadUserProfileInformationSuccess, (state, { response }) => ({
    ...state,
    data: response,
    status: 'success',
    error: null
  })),
  on(loadUserProfileInformationFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadUserProfileInformationWithEmail, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadUserProfileInformationWithEmailSuccess, (state, { response }) => ({
    ...state,
    userFullInformation: response,
    status: 'success',
    error: null
  })),
  on(loadUserProfileInformationFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(updateUserProfileInformation, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updateUserProfileInformationSuccess, (state, { response }) => {
    const updatedData = {
      ...state.data,
      fullName: response.fullname,
      language: response.language
    };
    return {
      ...state,
      data: updatedData,
      status: 'success',
      error: null
    };
  }),
  on(updateUserProfileInformationFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
