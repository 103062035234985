export class GeneralUtils {
  public static copyContentToClipboard(txt) {
    const fakeElement = document.createElement('textarea');
    fakeElement.style.position = 'fixed';
    fakeElement.style.left = '0';
    fakeElement.style.top = '0';
    fakeElement.style.opacity = '0';
    fakeElement.value = txt;
    document.body.appendChild(fakeElement);
    fakeElement.focus();
    fakeElement.select();
    document.execCommand('copy');
    document.body.removeChild(fakeElement);
  }

  public static generateRandomNumber(max = Number.MAX_SAFE_INTEGER): number {
    return Math.floor(Math.random() * max);
  }

  public static capitalize(str: string): string {
    return str && str[0].toUpperCase() + str.slice(1);
  }

  public static splitStringViaIndex(str: string, index: number) {
    return (
      str.substring(0, index) +
      ' ' +
      str.substring(index).replace(/ /g, '').trim()
    );
  }
}
