export interface CargoDimensionModel {
  readonly width: number;
  readonly height: number;
  readonly weight: number;
  readonly length: number;
}

export interface CargoOfferModel {
  readonly appId: string;
  readonly orderId: string;
  readonly offerId: string;
}
