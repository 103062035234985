import * as objectPath from 'object-path';

import { AppSettingService, SettingFields } from '@pratik-islem/core';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { DefaultLayoutConfig } from '../../configs/default-layout.config';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const LAYOUT_CONFIG_LOCAL_STORAGE_KEY = `${environment.app_version}-layoutConfig`;

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private layoutConfigSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );

  /**
   * this obs is used among different components in order to detect
   * that we should show the search box or the aside menu based on the value we emit to  this obs
   */
  asideMenuDisplayMode$: Observable<boolean>;
  asideMenuDisplayModeSubject: BehaviorSubject<boolean>;
  selectedApp$: Observable<any>;
  selectedAppSubject: BehaviorSubject<any>;
  // scope list of css classes
  private classes = {
    header: [],
    header_container: [],
    header_mobile: [],
    header_menu: [],
    aside_menu: [],
    subheader: [],
    subheader_container: [],
    content: [],
    content_container: [],
    footer_container: [],
  };

  // scope list of html attributes
  private attrs = {
    aside_menu: {},
  };

  constructor(private appSettingService: AppSettingService) {
    this.asideMenuDisplayModeSubject = new BehaviorSubject<boolean>(false);
    this.asideMenuDisplayMode$ =
      this.asideMenuDisplayModeSubject.asObservable();
    this.selectedAppSubject = new BehaviorSubject<any>(null);
    this.selectedApp$ = this.selectedAppSubject.asObservable();
  }

  initializeSettings(): any {
    const configFromLocalStorage = localStorage.getItem(
      LAYOUT_CONFIG_LOCAL_STORAGE_KEY
    );
    if (configFromLocalStorage) {
      try {
        this.layoutConfigSubject.next(JSON.parse(configFromLocalStorage));
        return;
      } catch (error) {
        this.removeConfig();
        console.error('config parse from local storage', error);
      }
    }
    this.layoutConfigSubject.next(DefaultLayoutConfig);
  }

  private removeConfig(): void {
    localStorage.removeItem(LAYOUT_CONFIG_LOCAL_STORAGE_KEY);
  }

  refreshConfigToDefault() {
    this.setConfigWithPageRefresh(undefined);
  }

  getSettingsValues(): any {
    const config = this.layoutConfigSubject.value;
    if (!config) {
      return DefaultLayoutConfig;
    }
    return config;
  }

  setConfig(config: any) {
    if (!config) {
      this.removeConfig();
    } else {
      localStorage.setItem(
        LAYOUT_CONFIG_LOCAL_STORAGE_KEY,
        JSON.stringify(config)
      );
    }
    this.layoutConfigSubject.next(config);
  }

  setConfigWithoutLocalStorageChanges(config: any) {
    this.layoutConfigSubject.next(config);
  }

  setConfigWithPageRefresh(config: any) {
    this.setConfig(config);
    document.location.reload();
  }

  getProp(path: string): any {
    return objectPath.get(this.layoutConfigSubject.value, path);
  }

  setProp(path: string, value: any): any {
    objectPath.set(this.layoutConfigSubject.value, path, value);
  }

  setCSSClass(path: string, classesInStr: string) {
    const cssClasses = this.classes[path];
    if (!cssClasses) {
      this.classes[path] = [];
    }
    classesInStr
      .split(' ')
      .forEach((cssClass: string) => this.classes[path].push(cssClass));
  }

  getCSSClasses(path: string): string[] {
    const cssClasses = this.classes[path];
    if (!cssClasses) {
      return [];
    }

    return cssClasses;
  }

  getStringCSSClasses(path: string) {
    return this.getCSSClasses(path).join(' ');
  }

  getHTMLAttributes(path: string): any {
    const attributesObj = this.attrs[path];
    if (!attributesObj) {
      return {};
    }
    return attributesObj;
  }

  setHTMLAttribute(path, attrKey: string, attrValue: any) {
    const attributesObj = this.attrs[path];
    if (!attributesObj) {
      this.attrs[path] = {};
    }
    this.attrs[path][attrKey] = attrValue;
  }

  /**
   *
   * @param displayMode : true value will display aside menu and false value will hide the aside menu
   */
  setAsideMenuDisplayMode(displayMode: boolean): void {
    this.appSettingService.setSettingsPartially(
      SettingFields.showAsideMenu,
      displayMode
    );
    this.asideMenuDisplayModeSubject.next(displayMode);
  }

  /**
   * this method used for when we are refreshing pages
   */
  resetAsideMenuDisplayMode(): void {
    const lastValueOfAsideMenuDisplayMode =
      this.appSettingService.getSettingsValues().showAsideMenu;
    this.setAsideMenuDisplayMode(lastValueOfAsideMenuDisplayMode);
  }

  setSelectedApp(app: any): void {
    this.selectedAppSubject.next(app);
  }
}
