import { Routes } from '@angular/router';

export const EASY_COLLECTION_ROUTES: Routes = [
  {
    path: '',
    loadComponent: async () =>
      (await import('../features/shell/shell.component')).ShellComponent
  },
  {
    path: 'settings',
    loadComponent: async () =>
      (await import('../features/settings/settings.component')).SettingsComponent
  },
  {
    path: '**',
    loadComponent: async () =>
      (await import('../features/shell/shell.component')).ShellComponent,
    pathMatch: 'full'
  }
];
