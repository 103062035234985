import { ChangeDetectorRef, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AppSettingService } from '@pratik-islem/core';
import { finalize } from 'rxjs/internal/operators/finalize';
import { DialogResultModel } from '@pratik-islem/shared/data';
import {
  ShippingAndDeliveryService,
  AcceptOffersModel
} from '@pratik-islem/store/store-settings';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { AlertModule, AnimatedLogoModule } from '@pratik-islem/shared/ui';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-cargo-offers',
  templateUrl: './cargo-offers.component.html',
  styleUrls: ['./cargo-offers.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    InputNumberModule,
    AnimatedLogoModule,
    AlertModule,
    RadioButtonModule,
    FormsModule
  ]
})
export class CargoOffersComponent {
  ingredient!: string;
  orderId;
  offerData;
  selectedOfferType = 'fastest';
  selectedOfferId = '';
  isLoading = false;
  faPlusCircle = faPlusCircle;

  get cheapeastId(): string {
    if (!this.offerData.hasOwnProperty('cheapest')) {
      return '';
    }
    return this.offerData.cheapest.id;
  }

  get fastestId(): string {
    if (!this.offerData.hasOwnProperty('fastest')) {
      return '';
    }
    return this.offerData.fastest.id;
  }

  constructor(
    private _ddr: DynamicDialogRef,
    private _cdr: ChangeDetectorRef,
    private _ddc: DynamicDialogConfig,
    private _messageService: MessageService,
    private _translateService: TranslateService,
    private _appSettingService: AppSettingService,
    private _shippingService: ShippingAndDeliveryService
  ) {
    this.orderId = this._ddc.data.orderId;
    this.offerData = this._ddc.data.offers;
  }

  changeSelectedOffer(offerId: string): void {
    this.selectedOfferId = offerId;
  }

  closeForm(): void {
    const getOffersResult: DialogResultModel = {
      resultType: 'CANCEL',
      result: null
    };
    this._ddr.close(getOffersResult);
  }

  acceptOffer(): void {
    const appId = this._appSettingService.getCurrentAppId();
    const payload: AcceptOffersModel = {
      offerId: this.selectedOfferId,
      orderId: this.orderId
    };
    this.isLoading = true;
    this._shippingService
      .acceptOffers(appId, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this._cdr.detectChanges();
        })
      )
      .subscribe(
        () => {
          const getOffersResult: DialogResultModel = {
            resultType: 'ACTION_HAPPENED',
            result: null
          };
          this._ddr.close(getOffersResult);
        },
        () =>
          this._messageService.add({
            severity: 'error',
            summary: this._translateService.instant('SHARED.MESSAGES.ERROR'),
            detail: this._translateService.instant('ORDERS.ACCEPT_OFFER_FAILED')
          })
      );
  }
}
