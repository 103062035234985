<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{ 'ORDERS.CLIENT' | translate }}</h3>
    </div>
  </div>
  <div class="card-body">
    <div class="d-flex flex-wrap align-items-center">
      <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
        <span
          class="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
        >
          {{ orderInfo?.buyer.name | titlecase }}
          {{ orderInfo?.buyer.surname | titlecase }}
        </span>
        <span class="text-muted font-weight-bold">{{
          orderInfo?.buyer.email
        }}</span>
        <span class="text-muted font-weight-bold">{{
          orderInfo?.buyer.phone
        }}</span>
      </div>
    </div>
  </div>
  <div class="separator separator-solid"></div>
  <div class="card-body">
    <div class="d-flex flex-wrap align-items-center">
      <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
        <a
          class="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
          >{{ 'ORDERS.DELIVERY_INFORMATION' | translate }}</a
        >
        <span class="text-muted">
          {{ orderInfo?.shippingAddress?.nameSurname | titlecase }}
          <br />
          {{ orderInfo?.shippingAddress?.phoneNumber | titlecase }}
          <br />
          {{ orderInfo?.shippingAddress.description }}
          <br />
          {{ orderInfo?.shippingAddress.zipCode }}
          {{ UserCity }}<br />
          {{ UserCountry }}
        </span>
      </div>
    </div>
  </div>
  <div class="separator separator-solid"></div>
  <div class="card-body">
    <div class="d-flex flex-wrap align-items-center">
      <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
        <a
          class="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
          >{{ 'ORDERS.BILLING_INFORMATION' | translate }}</a
        >
        <span class="text-muted">
          {{ nameSurname | titlecase }}
          <br />
          {{ orderInfo?.billingAddress.description }}<br />
          {{ orderInfo?.billingAddress.zipCode }}
          <ng-container *ngIf="UserCountry != ''">
            {{ UserCity }}/{{ UserCountry }}
          </ng-container>
          <br />
          {{ orderInfo?.billingAddress?.phoneNumber }}
          <br />
          <ng-container *ngIf="orderInfo?.billingAddress.VD != undefined">
            VD: {{ orderInfo?.billingAddress.VD }}
          </ng-container>

          <br />
          <ng-container *ngIf="orderInfo?.billingAddress.VNO != undefined">
            VNO: {{ orderInfo?.billingAddress.VNO }}
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</div>
