import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-content-container',
  templateUrl: './dialog-content-container.component.html',
  styleUrls: ['./dialog-content-container.component.scss']
})
export class DialogContentContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
