import { Routes } from '@angular/router';
import { FeatureGuard } from '@pratik-islem/core';
import { featuresList } from '@pratik-islem/shared/data';

export const SALES_CHANNEL_ROUTES: Routes = [
  {
    path: 'list',
    loadComponent: async () =>
      (
        await import(
          '../features/sales-channel-list/sales-channel-list.component'
        )
      ).SalesChannelListComponent
  },
  {
    path: 'pratikLink',
    loadChildren: (): any =>
      import('../features/shopilink/routes/shopilink.routes').then(
        (m) => m.SHOPILINK_SALE_CHANNEL_ROUTES
      )
  },
  {
    path: 'easy-collection',
    canActivate: [FeatureGuard],
    data: {
      feature: featuresList.easyCollection
    },
    loadChildren: (): any =>
      import('../features/easy-collection/routes/easy-collection.routes').then(
        (m) => m.EASY_COLLECTION_ROUTES
      )
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
