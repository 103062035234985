import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SetSupportLanguageClassDirective } from './set-support-language-class.directive';

@NgModule({
  declarations: [SetSupportLanguageClassDirective],
  imports: [CommonModule],
  exports: [SetSupportLanguageClassDirective],
})
export class SupportLanguageClassDirectiveModule {}
