import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DialogResultModel } from '@pratik-islem/shared/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AppSettingService } from '@pratik-islem/core';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ShippingAndDeliveryService } from '@pratik-islem/store/store-settings';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AnimatedLogoModule } from '@pratik-islem/shared/ui';
import { VALIDATION_VALUES } from '@pratik-islem/shared/data';

@Component({
  selector: 'order-cargo-dimensions',
  templateUrl: './cargo-dimensions.component.html',
  styleUrls: ['./cargo-dimensions.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InputNumberModule,
    ConfirmDialogModule,
    AnimatedLogoModule
  ]
})
export class CargoDimensionsComponent implements OnInit {
  faPlusCircle = faPlusCircle;
  faTrash = faTrash;
  isLoading = false;
  cargoDimensionsForm!: FormGroup;
  nameMinLength = VALIDATION_VALUES.SHORT_TEXT_MIN_LENGTH;
  nameMaxLength = VALIDATION_VALUES.SHORT_TEXT_MAX_LENGTH;
  urlMaxLength = VALIDATION_VALUES.URL_MAX_LENGTH;
  headerValueMaxLength = VALIDATION_VALUES.LONG_TEXT_MAX_LENGTH;
  webURLPattern = VALIDATION_VALUES.WEB_URL_PATTERN;
  requestIndex = 0;

  constructor(
    public ddc: DynamicDialogConfig,
    private _fb: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private _ddr: DynamicDialogRef,
    private _shippingService: ShippingAndDeliveryService,
    private _messageService: MessageService,
    private _translateService: TranslateService,
    private _appSettingService: AppSettingService
  ) {}

  ngOnInit(): void {
    this._buildForm();
  }

  closeForm(): void {
    this._ddr.close();
  }

  submit(): void {
    if (this.cargoDimensionsForm.invalid) {
      return;
    }

    const appId = this._appSettingService.getCurrentAppId();
    const payload = this.cargoDimensionsForm.value as any;
    payload.orderId = this.ddc.data?.id;
    this.isLoading = true;
    this._shippingService
      .getOffers(appId, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this._cdr.detectChanges();
        })
      )
      .subscribe(
        (response: any) => {
          const getOffersResult: DialogResultModel = {
            resultType: 'ACTION_HAPPENED',
            result: response.data
          };
          this._ddr.close(getOffersResult);
        },
        (error) =>
          this._messageService.add({
            severity: 'error',
            summary: this._translateService.instant('SHARED.MESSAGES.ERROR'),
            detail: this._translateService.instant(
              'SHARED.MESSAGES.REQUEST_FAILED'
            )
          })
      );
  }

  private _buildForm(): void {
    this.cargoDimensionsForm = this._fb.group({
      length: ['', Validators.required],
      height: ['', Validators.required],
      width: ['', Validators.required],
      weight: ['', Validators.required]
    });
  }
}
