import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AppConfigurationService } from '../configuration';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FeatureGuard implements CanActivate {
  constructor(
    private appConfigurationService: AppConfigurationService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const {
      data: { feature },
    } = route;
    try {
      const featureIsEnabled =
        this.appConfigurationService.isFeatureEnabled(feature);
      if (featureIsEnabled) {
        return true;
      }
      this.router.navigate(['/stores/list']);
      return false;
    } catch {
      this.router.navigate(['/stores/list']);
      return false;
    }
  }
}
