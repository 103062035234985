export interface OrdersDataModel {
  data: any[];
  meta: { totalCount: number; queryCount: number; itemsCount: number };
}

export interface allOrdersResponseModel {
  appId: string;
  OrdersData: OrdersDataModel;
}

export interface lastOrdersResponseModel {
  appId: string;
  OrdersData: OrdersDataModel;
}
