export class StoreInformationAddressModel {
  public city?: string;
  public zipCode?: string;
  public country?: string;
  public description?: string;
  public state?: string;
}

export class StoreInformationContactModel {
  public nameSurname?: string;
  public phoneNumber?: string;
  public email?: string;
}

export interface IStoreInformationModel {
  name: string;
  officialName: string;
  sectorId: string;
  address: StoreInformationAddressModel;
  contact: StoreInformationContactModel;
  currency: string;
}

export class StoreInformationModel implements IStoreInformationModel {
  name: string;
  officialName: string;
  sectorId: string;
  address: StoreInformationAddressModel;
  contact: StoreInformationContactModel;
  currency: string;
}

export interface ICountryPhoneCodeModel {
  name: string;
  isO2: string;
  code: string;
}
