import { IFeatureData } from 'libs/shared/data/src/lib/features';

export interface IAppConfiguration {
  name: string;
  production: boolean;
  app_version: string;
  api_key: string;
  pratik_islem_api_url: string;
  identity_api_url: string;
  storage_api_url: string;
  users_api_url: string;
  storage_container_name: string;
  file_cdn_base_url: string;
  pratik_islem_base_url: string;
  pratik_islem_panel_base_url: string;
  features: IFeatureData[];
  sentry_config: any;
}
