import  *  as userProfileActions from './user-profile.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';

import {
  selectUserProfileFullInformation,
  selectUserProfileInformation
} from './user-profile.selectors';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserProfileService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class UserProfileFeatureEffects {
  public loadUserProfileInformation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userProfileActions.loadUserProfileInformation),
      withLatestFrom(this._store.select(selectUserProfileInformation)),
      switchMap((actions) => {
        const previousData = actions[1];
        if (previousData != null) {
          return of(
            userProfileActions.loadUserProfileInformationSuccess({
              response: previousData
            })
          );
        }
        return this._userProfileService.getCurrentUserProfile().pipe(
          map(
            (response) =>
              userProfileActions.loadUserProfileInformationSuccess({ response: response }),
            catchError(() => EMPTY)
          )
        );
      })
    )
  );

  public loadUserProfileFullInformation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userProfileActions.loadUserProfileInformationWithEmail),
      withLatestFrom(this._store.select(selectUserProfileFullInformation)),
      switchMap((actions: any) => {
        const previousData = actions[1];
        if (previousData != null) {
          return of(
            userProfileActions.loadUserProfileInformationWithEmailSuccess({
              response: previousData
            })
          );
        }
        return this._userProfileService
          .getUserInformation(actions[0].payload)
          .pipe(
            map(
              (response) =>
                userProfileActions.loadUserProfileInformationWithEmailSuccess({
                  response: response
                }),
              catchError(() => EMPTY)
            )
          );
      })
    )
  );

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _userProfileService: UserProfileService
  ) {}
}
