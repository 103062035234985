import { GenericState, GenericStoreStatus } from '@pratik-islem/core';
import { createReducer, on } from '@ngrx/store';
import {
  changeActivityOfSaleChannel,
  changeActivityOfSaleChannelFailure,
  changeActivityOfSaleChannelSuccess,
  loadEasyCollectionSaleChannelData,
  loadEasyCollectionSaleChannelDataFailure,
  loadEasyCollectionSaleChannelDataSuccess,
  loadShopilinkSaleChannelData,
  loadShopilinkSaleChannelDataFailure,
  loadShopilinkSaleChannelDataSuccess,
  loadWebSettingData,
  loadWebSettingDataFailure,
  loadWebSettingDataSuccess,
  updateSaleChannel,
  updateSaleChannelSuccess
} from './sales-channel.actions';

export const salesChannelFeatureKey = 'feature-salesChannel';

export class salesChannelFeatureState implements GenericState<any> {
  appId!: string;
  shopilink: any;
  easyCollection: any;
  webSettings:any;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}

const initialState: salesChannelFeatureState = {
  appId: '',
  shopilink: null,
  easyCollection: null,
  webSettings: null,
  error: null,
  status: 'pending'
};

export const salesChannelFeatureReducer = createReducer(
  initialState,
  on(loadShopilinkSaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadShopilinkSaleChannelDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      shopilink: response.shopilinkSaleChannelData,
      status: 'success',
      error: null
    };
  }),
  on(loadShopilinkSaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(changeActivityOfSaleChannel, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(changeActivityOfSaleChannelSuccess, (state, { payload }) => {
    let shopilinkChannelInfo = Object.assign([], state.shopilink);
    let easyCollectionChannelInfo = Object.assign([], state.easyCollection);
    if (payload.channelType == 'Shopilink') {
      const shopilinkSalesChannelIndex = shopilinkChannelInfo.findIndex(
        (x: any) => x.platformType == 'None'
      );
      shopilinkChannelInfo[shopilinkSalesChannelIndex] =
        payload.updatedChannelInfo;
    }
    if (payload.channelType == 'Collection') {
      easyCollectionChannelInfo = payload.updatedChannelInfo;
    }
    return {
      ...state,
      shopilink: shopilinkChannelInfo,
      easyCollection: easyCollectionChannelInfo,
      status: 'success',
      error: null
    };
  }),
  on(changeActivityOfSaleChannelFailure, (state, { payload }) => {
    return {
      ...state,
      status: 'error',
      error: payload.result.message
    };
  }),
  on(updateSaleChannel, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updateSaleChannelSuccess, (state, { payload }) => {
    return {
      ...state,
      status: 'success',
      error: null
    };
  }),
  on(changeActivityOfSaleChannelFailure, (state, { payload }) => {
    return {
      ...state,
      status: 'error',
      error: payload.result.message
    };
  }),
  on(loadShopilinkSaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadShopilinkSaleChannelDataSuccess, (state, { response }) => {
    if (!response) state;
    return {
      ...state,
      appId: response.appId,
      shopilink: response.shopilinkSaleChannelData,
      status: 'success',
      error: null
    };
  }),
  on(loadShopilinkSaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadShopilinkSaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadShopilinkSaleChannelDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      shopilink: response.shopilinkSaleChannelData,
      status: 'success',
      error: null
    };
  }),
  on(loadShopilinkSaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadEasyCollectionSaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadEasyCollectionSaleChannelDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      easyCollection: response.channelData,
      status: 'success',
      error: null
    };
  }),
  on(loadEasyCollectionSaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadWebSettingData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadWebSettingDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      webSettings: response,
      status: 'success',
      error: null
    };
  }),
  on(loadWebSettingDataFailure, (state, { error }) => ({
    ...state,
    webSettings: null,
    status: 'error',
    error: error
  })),
);
