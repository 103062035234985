export interface GetOfferModel {
  orderId: string;
  length: number;
  height: number;
  width: number;
  weight: number;
  massUnit?: string;
  distanceUnit?: string;
}

export interface AcceptOffersModel {
  orderId: string;
  offerId: string;
}
