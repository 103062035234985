import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

 import { featuresNames } from '@pratik-islem/shared/data';
import { AppConfigurationService } from '@pratik-islem/core';
 
@Directive({
  selector: '[featureFlag]'
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureFlag!: featuresNames;

  /**
   * ReverseLogic used for rendering a block of code when a feature is not active
   * for instance in the left panel menu we want to render apps menu when appStore is not active
   */
  @Input() featureFlagIsReverseLogic = false;

  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private appConfigurationService: AppConfigurationService
  ) {}

  ngOnInit() {
    const featureIsEnabled = this.appConfigurationService.isFeatureEnabled(
      this.featureFlag
    );
    if (
      (featureIsEnabled && !this.featureFlagIsReverseLogic) ||
      (!featureIsEnabled && this.featureFlagIsReverseLogic)
    ) {
      this.vcr.createEmbeddedView(this.tpl);
    }
  }
}
