import { Component, Input, OnInit } from '@angular/core';
import { IHistory, IHistoryItem } from './timeline-history.model';

import { faGenderless } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  public faGenderless = faGenderless;

  @Input()
  public logs: any;

  public orderStatusHistory: IHistory[] = [];
  constructor() {}

  ngOnInit(): void {
   }
}
