import {
  CreateHookModel,
  DeleteHookModel,
  GetAllHooksModel,
  GetAllHooksResponseModel,
  GetHookModel,
  HookRequestsModel,
  HookResponseModel,
  UpdateHookModel
} from '../../data';

import { END_POINTS_URL } from '@pratik-islem/shared/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class WebhookService {
  constructor(private _http: HttpClient) {}

  public get(dto: GetHookModel): Observable<HookRequestsModel> {
    const URL = `${END_POINTS_URL.V2.HOOKS_ULR}/${dto.hookId}`;
    return this._http
      .get(URL)
      .pipe(map((result: any) => result.data as HookRequestsModel));
  }

  public getAll(dto: GetAllHooksModel): Observable<GetAllHooksResponseModel> {
    const URL = `${END_POINTS_URL.V2.HOOKS_ULR}/apps/${dto.appId}`;
    return this._http.get(URL).pipe(
      map((result: any) => {
        const response: GetAllHooksResponseModel = {
          appId: dto.appId,
          data: result.data
        };
        return response;
      })
    );
  }

  public getAllTypes(): Observable<string[]> {
    const URL = `${END_POINTS_URL.V2.HOOKS_ULR}/types`;
    return this._http.get(URL).pipe(map((result: any) => result.data));
  }

  public create(dto: CreateHookModel): Observable<HookResponseModel> {
    const URL = `${END_POINTS_URL.V2.HOOKS_ULR}/apps/${dto.appId}`;
    return this._http
      .post(URL, dto.payload)
      .pipe(map((result: any) => result.data as HookResponseModel));
  }

  public update(dto: UpdateHookModel): Observable<HookResponseModel> {
    const URL = `${END_POINTS_URL.V2.HOOKS_ULR}/${dto.hookId}/apps/${dto.appId}`;
    return this._http
      .put(URL, dto.payload)
      .pipe(map((result: any) => result.data as HookResponseModel));
  }

   public delete(dto: DeleteHookModel): Observable<any> {
    const URL = `${END_POINTS_URL.V2.HOOKS_ULR}/${dto.hookId}`;
    return this._http.delete(URL);
  }
}
