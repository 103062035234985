import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paidAmount',
  standalone: true
})
export class PaidAmountPipe implements PipeTransform {
  transform(order: any, ...args: any[]): string {
    if (order.productList.length == 0) {
      return '0';
    }

    let price = order.productList.reduce(
      (previousValue, currentValue) => previousValue + currentValue.paidPrice,
      0
    );
    price = price.toString();
    const dotIndex = price.lastIndexOf('.');

    return dotIndex == -1 ? price : price.substring(0, dotIndex + 3);
  }
}
