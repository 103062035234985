import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'order-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule]
})
export class PaymentInformationUIComponent {
  @Input()
  public orderInfo: any;

  @Input()
  public defaultCurrency = 'TRY';

  @Input()
  public defaultLocale = 'tr-TR';

  @Input() showRefund = true;

  @Output() cancelOrderCalled = new EventEmitter();
  @Output() refundOrderCalled = new EventEmitter();

  cancelOrder(): void {
    this.cancelOrderCalled.emit();
  }

  refundOrder(): void {
    this.refundOrderCalled.emit();
  }
}
