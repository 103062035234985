import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  dashboardFeatureKey,
  dashboardFeatureState
} from './dashboard.reducer';

export const dashboardState =
  createFeatureSelector<dashboardFeatureState>(dashboardFeatureKey);

export const selectDashboardPageData = createSelector(
  dashboardState,
  ({ appId, lastCustomers, lastOrders, todayOrdersData }) => {
    return {
      appId,
      lastCustomers,
      lastOrders,
      todayOrdersData
    };
  }
);

export const selectDashboardPageIsLoading = createSelector(
  dashboardState,
  ({ status }) => {
    return status == 'loading' || status == 'pending';
  }
);
