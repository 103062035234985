import { APP_EFFECTS, APP_REDUCERS } from './store';

import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { metaReducers } from './store/app.meta.reducers';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(APP_REDUCERS, { metaReducers }),
    EffectsModule.forRoot(APP_EFFECTS),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    })
  ]
})
export class CoreModule {}
