<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{ 'ORDERS.STATUS_TRACKING' | translate }}
      </h3>
    </div>
  </div>
  <form
    class="form"
    novalidate="novalidate"
    [formGroup]="manageStatusTrackingForm"
    (ngSubmit)="submit()"
  >
    <div class="card-body">
      <div class="form-group">
        <label
          >{{ 'ORDERS.ORDER_STATUS' | translate }}
          <fa-icon
            [icon]="faInfo"
            class="text-primary"
            [title]="'ORDERS.CANT_CHANGE_ORDER_STATUS_WARNING' | translate"
          ></fa-icon>
        </label>
        <p-dropdown
          class="flex-flow-column"
          [options]="orderStatusList"
          formControlName="orderStatus"
          [placeholder]="'SHARED.CHOOSE' | translate"
          (onChange)="handleOrderStatusChange($event)"
        >
          <ng-template let-selectedStatus pTemplate="selectedItem">
            {{ 'ORDERS.' + selectedStatus.translationLabel | translate }}
          </ng-template>
          <ng-template let-status pTemplate="item">
            {{ 'ORDERS.' + status.translationLabel | translate }}
          </ng-template>
        </p-dropdown>
      </div>
      <div class="form-group">
        <label> {{ 'ORDERS.NOTIFICATION' | translate }}</label>
        <div class="checkbox-list">
          <label class="checkbox">
            <input type="checkbox" formControlName="sendEmail" />
            <span></span>
            {{ 'ORDERS.NOTIFY_USER_OF_CHANGES' | translate }}
          </label>
        </div>
      </div>
      <div class="form-group">
        <label>
          {{ 'ORDERS.SHIPPING_TRACKING_CODE' | translate }}
        </label>
        <input
          type="text"
          class="form-control"
          formControlName="shippingTrackingCode"
        />

        <ng-template
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            control: manageStatusTrackingForm.controls['shippingTrackingCode'],
            formIsValid: formIsValid
          }"
        ></ng-template>
      </div>
      <div class="form-group mb-0">
        <label>
          {{ 'ORDERS.SHIPPING_COMPANY' | translate }}
        </label>
        <input
          type="text"
          class="form-control"
          formControlName="shippingTrackingCompany"
        />
        <ng-template
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            control:
              manageStatusTrackingForm.controls['shippingTrackingCompany'],
            formIsValid: formIsValid
          }"
        ></ng-template>
      </div>
    </div>
    <div class="card-footer text-right">
      <button
        type="button"
        (click)="submit(true)"
        *ngIf="shippingInfoAdded == true"
        class="btn btn-primary font-weight-bold mr-3 mb-5"
      >
        {{ 'ORDERS.COMPLETE_ORDER' | translate }}
      </button>
      <button
        type="submit"
        class="btn btn-success float-right font-weight-bold w-120px mb-5"
        [disabled]="!formIsValid"
      >
        {{ 'SHARED.SAVE' | translate }}
      </button>
    </div>
  </form>
</div>

<ng-template
  #formError
  let-control="control"
  let-formIsValid="formIsValid"
  let-validation="validation"
>
  <ng-container
    *ngIf="
      !formIsValid &&
      (control?.touched || control?.dirty || control?.pristine) &&
      control?.value == ''
    "
  >
    <p class="text-danger">
      {{ 'SHARED.VALIDATION.FIELD_REQUIRED' | translate }}
    </p>
  </ng-container>
</ng-template>
