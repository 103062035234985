import { APP_INITIALIZER } from '@angular/core';
import { AppSettingService } from './app-setting.service';

export const APP_SETTING_APP_INITIALIZER_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [AppSettingService],
    useFactory: (appSettingService: AppSettingService) => {
      return () => {
        return appSettingService.initializeSettings();
      };
    },
  }
];
