export const VALID_FILE_TYPES = {
  IMAGE: '.jpg,.jpeg,.png',
  CSV: 'application/vnd.ms-excel',
  EXCEL_CSV: '.csv',
  XML: '.xml',
  TEXT_CSV: 'text/csv',
  EXCEL_2003_FILE_FORMAT: 'application/vnd.ms-excel',
  EXCEL_2007_FILE_FORMAT:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};
