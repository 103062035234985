<div
  class="app-splash-screen"
  [ngStyle]="{
    left: windowWidth,
    opacity: opacityChange,
    transition: splashTransition
  }"
  *ngIf="showSplash"
>
  <div class="app-splash-inner">
    <ng-content></ng-content>
  </div>
</div>
