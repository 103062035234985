interface ILegalPageLanguage {
  [key: string]: string;
}

export class LegalPageLanguage implements ILegalPageLanguage {
  [key: string]: string;
  // constructor(
  //     public tr: string,
  //     public en: string
  // ) { }
}

export class LegalPageModel {
  deliveryConditionsTitle: any;
  deliveryConditions: any;
  cancellationProcedureTitle: any;
  cancellationProcedure: any;
  distanceSalesContract: any;
}
