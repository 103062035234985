import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LazyLoadStyleService {
  loadStyle(styleUrl: string) {
    const head = document.getElementsByTagName('head')[0];

    let themeLink = document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleUrl;
    } else {
      const style = document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleUrl}`;

      head.appendChild(style);
    }
  }
}
