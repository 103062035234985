<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{ 'ORDERS.PAYMENT_INFORMATION' | translate }}
      </h3>
    </div>
  </div>
  <div class="card-body">
    <div class="d-flex flex-wrap align-items-center">
      <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
        <span class="text-dark font-weight-bold mb-1 font-size-lg">
          {{ 'ORDERS.PAYMENT_METHOD' | translate }}
        </span>
        <span class="text-muted font-weight-bold">{{
          orderInfo?.paymentType
        }}</span>
        <span class="text-muted font-weight-bold">{{
          orderInfo?.cardNumber
        }}</span>
        <div
          *ngIf="orderInfo?.providerType == 'Shopiroller'"
          class="d-flex flex-column flex-grow-1 mt-5"
        >
          <span class="text-dark font-weight-bold mb-1 font-size-lg">
            {{ 'ORDERS.PAYMENT_TYPE' | translate }}
          </span>

          <span class="text-muted font-weight-bold">
            {{ 'SHARED.SHOPIPAY' | translate }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="separator separator-solid"></div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-borderless mb-0">
        <tbody>
          <tr>
            <td class="align-middle">
              <div class="font-size-lg font-weight-bolder text-dark-75">
                {{ 'ORDERS.SUB_TOTAL' | translate }}
              </div>
            </td>
            <td class="text-muted align-middle text-right">
              <span class="font-size-lg text-dark-75"
                >{{
                  orderInfo?.subTotalPrice
                    | currency : defaultCurrency : '' : '.2-2' : defaultLocale
                }}
                {{ orderInfo?.currency }}</span
              >
              <h1></h1>
            </td>
          </tr>
          <tr>
            <td class="align-middle">
              <div class="font-size-lg font-weight-bolder text-dark-75">
                {{ 'ORDERS.SHIPMENT' | translate }}
              </div>
            </td>
            <td class="text-muted align-middle text-right">
              <span class="font-size-lg text-dark-75"
                >{{
                  orderInfo?.shippingPrice
                    | currency
                      : orderInfo?.currency
                      : ''
                      : '.2-2'
                      : defaultLocale
                }}
                {{ orderInfo?.currency }}</span
              >
            </td>
          </tr>
          <tr>
            <td class="align-middle">
              <div class="font-size-lg font-weight-bolder text-dark-75">
                {{ 'ORDERS.TOTAL' | translate }}
              </div>
            </td>
            <td class="text-muted align-middle text-right">
              <span class="font-size-lg text-dark-75"
                >{{
                  orderInfo?.totalPrice
                    | currency
                      : orderInfo?.currency
                      : ''
                      : '.2-2'
                      : defaultLocale
                }}
                {{ orderInfo?.currency }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div
    class="card-footer text-right"
    *ngIf="
      orderInfo?.totalPrice !== 0 &&
      orderInfo.currentStatus !== 'CancelRequested' &&
      orderInfo.currentStatus !== 'Canceled'
    "
  >
    <button
      (click)="cancelOrder()"
      class="btn btn-outline-warning font-weight-bold float-right"
    >
      {{ 'ORDERS.CANCEL_ORDER' | translate }}
    </button>

    <button
      *ngIf="showRefund === true"
      (click)="refundOrder()"
      class="btn btn-outline-info font-weight-bold float-right"
      style="margin-right: 1rem"
    >
      {{ 'ORDERS.MAKE_A_REFUND' | translate }}
    </button>
  </div>
</div>
