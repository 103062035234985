import { AnimatedLogoModule } from '../animated-logo/animated-logo.module';
import { CommonModule } from '@angular/common';
import { CustomEditorComponent } from './custom-editor.component';
import { EditorModule } from 'primeng/editor';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [CustomEditorComponent],
  imports: [
    CommonModule,
    AnimatedLogoModule,
    EditorModule,
    FormsModule,
    HttpClientModule,
  ],
  exports: [CustomEditorComponent],
})
export class CustomEditorModule {}
