import * as countriesData from 'country-telephone-data';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { AutoUnsubscribe } from '@pratik-islem/shared/decorators';
import { PhoneNumberValidator } from '@pratik-islem/shared/utils';
import { SubSink } from 'subsink';
import phone from 'phone';
import { timer } from 'rxjs';
import { COUNTRIES_PHONE_CODE } from '@pratik-islem/shared/data';

@AutoUnsubscribe()
@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit, OnChanges {
  @Input()
  showValidationMessage = true;

  @Input()
  public phoneNumber: string = '';

  @Input()
  isDisplayMode = false;

  @Output()
  public phoneNumberChange = new EventEmitter<string>();

  @Output()
  public phoneNumberStatusChange = new EventEmitter<boolean>();

  public phoneNumberForm: FormGroup;
  public isLoading = false;
  public sectorsList: any[] = [];
  public filterSectorList: any[] = [];
  public countriesList: any[] = [];
  public currenciesList: any[] = [];
  public paymentInfo: any;
  public selectedCountry: any;
  public selectedCurrency: any;
  public selectedSector: any;
  public countriesPhoneCodeData: any[] = [];
  public selectedCountryPhoneCodeData: any = {
    label: 'Turkey',
    value: {
      code: '90',
      isO2: 'tr'
    }
  };
  public phoneCodeDisabled = true;
  public phoneCodeMask = '90 9999999999';
  public phoneCodePlaceHolder = '90 9999999999';
  @ViewChild('phoneNumberFormRef')
  phoneNumberFormRef: ElementRef;
  @ViewChild('countryFlagImage')
  countryFlagImage: ElementRef;
  public get phoneNumberIsValid(): boolean {
    return (
      (this.phoneNumberForm.controls['phoneNumber'].valid &&
        this.phoneNumberForm.controls['phoneNumber'].touched) ||
      this._phoneNumberIsValid == true
    );
  }

  private _phoneNumberIsValid = false;
  private _selectedCountryCode = '90';
  private _countriesPhoneCode: any[] = COUNTRIES_PHONE_CODE;
  private _subs = new SubSink();

  constructor(private _fb: FormBuilder, private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._buildForm();
    this._setInitialMask();
    this._initializePhoneCodeData();
    this._listenToFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const phoneNumber = changes?.phoneNumber;
    if (
      phoneNumber &&
      phoneNumber.currentValue != '' &&
      phoneNumber.currentValue?.indexOf('undefined') == -1
    ) {
      this._subs.sink = timer(500).subscribe((_) => {
        this._initializeComponent();
        if (this.isDisplayMode) {
          this._disableFormElements();
        }
      });
    }
  }

  public hideMenu(): void {
    this.phoneCodeDisabled = true;
  }

  public showPhoneCode(event: any): void {
    this.phoneCodeDisabled = !this.phoneCodeDisabled;
  }

  public phoneCodeChange(event) {
    this.phoneNumberForm.controls['phoneNumberCode'].setValue(event.value);
  }

  public phoneCodeClicked(event: any): void {
    this.phoneCodeDisabled = !this.phoneCodeDisabled;
    this._selectedCountryCode = event.value.value.code;
    const selectedCountryIsO2 = event.value.value.isO2;
    this.selectedCountryPhoneCodeData = this.countriesPhoneCodeData.find(
      (x) => x.value.isO2 === selectedCountryIsO2
    );

    const selectedCountryData = countriesData.allCountries.find(
      (x) => x.dialCode == this._selectedCountryCode
    );

    if (selectedCountryData?.hasOwnProperty('format')) {
      const mask = selectedCountryData.format.replaceAll('.', '9');
      this.phoneCodeMask = mask;
      this.phoneCodePlaceHolder = mask.replace(
        mask.substring(1, selectedCountryData.dialCode.length + 1),
        selectedCountryData.dialCode
      );
    }
    this._validatePhoneNumber(selectedCountryIsO2);
  }

  private _setInitialMask(): void {
    const turkeyPhoneCodeData = {
      value: {
        label: 'Turkey',
        value: { code: '90', isO2: 'tr' }
      }
    };
    this.phoneCodeClicked(turkeyPhoneCodeData);
    this.selectedCountryPhoneCodeData = this.countriesPhoneCodeData.find(
      (x) => x.value.code === turkeyPhoneCodeData.value.value.code
    );
    this.hideMenu();
  }

  private _initializePhoneCodeData(): void {
    this.countriesPhoneCodeData = this._countriesPhoneCode.map((x) => {
      return {
        label: x.name,
        value: { code: x.code, isO2: x.isO2.toLowerCase() }
      };
    });
  }

  private _listenToFormChanges(): void {
    this._subs.sink = this.phoneNumberForm.valueChanges.subscribe((change) => {
      let phoneNumber = this.phoneNumberForm.controls['phoneNumber'].value;
      phoneNumber = phoneNumber.replace('-', ' ');
      const phoneNumberInfo = phone(phoneNumber);
      this._phoneNumberIsValid = phoneNumberInfo.isValid;
      this.phoneNumberStatusChange.emit(phoneNumberInfo.isValid);

      if (
        phoneNumber &&
        phoneNumberInfo.isValid &&
        phoneNumber.indexOf('undefined') == -1
      ) {
        let countryCode = phoneNumberInfo.countryCode || '90';
        const phoneNumberWithoutCountryCode =
          phoneNumberInfo.phoneNumber?.replace(countryCode, '');
        countryCode = countryCode.replace('+', '');
        const newPhoneNumber =
          countryCode + ' ' + phoneNumberWithoutCountryCode;
        this.phoneNumberChange.emit(newPhoneNumber);
      }
    });
  }

  private _initializeComponent(): void {
    this._selectedCountryCode = this.phoneNumber?.substring(
      0,
      this.phoneNumber?.indexOf(' ')
    );

    this.selectedCountryPhoneCodeData = this.countriesPhoneCodeData.find(
      (x) => x.value.code === this._selectedCountryCode
    );

    const selectedCountry = countriesData.allCountries.find(
      (x) => x.dialCode == this._selectedCountryCode
    );

    if (selectedCountry?.hasOwnProperty('format')) {
      const mask = selectedCountry.format.replaceAll('.', '9');
      this.phoneCodeMask = mask;
      this.phoneCodePlaceHolder = mask.replace(
        mask.substring(1, selectedCountry.dialCode.length + 1),
        selectedCountry.dialCode
      );
      const selectedCountryIsO2 = selectedCountry.iso2.toUpperCase();
      this._validatePhoneNumber(selectedCountryIsO2);
    }
    this._cdr.detectChanges();
    this.phoneNumberForm.controls['phoneNumber'].patchValue(this.phoneNumber);
    this.phoneNumberForm.controls['phoneNumberCode'].setValue(
      this.selectedCountryPhoneCodeData
    );

    document.getElementById('phone-number-input')?.focus();
    document.getElementById('phone-number-input')?.blur();
  }

  private _buildForm(): void {
    this.phoneNumberForm = this._fb.group({
      phoneNumber: ['', Validators.compose([this._validatePhoneNumber()])],
      phoneNumberCode: [null]
    });
  }

  private _validatePhoneNumber(regionIsO2: string = 'TR'): ValidatorFn {
    return PhoneNumberValidator(regionIsO2);
  }

  private _disableFormElements(): void {
    var elements = this.phoneNumberFormRef.nativeElement.elements;
    for (var i = 0, len = elements.length; i < len; ++i) {
      elements[i].disabled = true;
      elements[i].classList.add('disabled-element');
    }
    const countryFlagImage = this.countryFlagImage.nativeElement;
    countryFlagImage.classList.add('disabled-element');
    countryFlagImage.style.pointerEvents = 'none';
  }
}
