import { APP_IMPORTS } from './app.imports';
import { APP_PROVIDERS } from './app.provider';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [AppComponent],
  imports: [APP_IMPORTS],
  providers: APP_PROVIDERS,
  bootstrap: [AppComponent]
})
export class AppModule {}
