import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

import { orderPaymentStatusList } from '../../data';

@Directive({
  selector: '[appOrderPaymentStatusBgColor]',
  standalone: true
})
export class OrderPaymentStatusBgColorDirective {
  private orderPaymentStatusList = orderPaymentStatusList;
  @Input('id') id: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    const statusColor =
      this.orderPaymentStatusList.find((x) => x.type == this.id)?.color ?? '';
    this.renderer.addClass(this.el.nativeElement, statusColor);
  }
}
