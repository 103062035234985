import { Component, Input } from '@angular/core';
import {
  faExclamationTriangle,
  faInfo
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  faInfo = faInfo;
  faWarning = faExclamationTriangle;
  @Input('message')
  message = '';
  @Input('alertType')
  alertType: 'danger' | 'primary' | 'warning' | 'success' = 'warning';
}
