export interface IRefund {
  des: string;
  pid: string;
  reason: string;
  quantity: number;
}

export interface RefundProductModel {
  productId: string;
  quantity: number;
}

export interface UpdateRefundModel {
  des: string;
  pid?: string;
  reason: string;
  price?: number;
  commissionRate?: number;
  orderId?: string;
  products: RefundProductModel[];
  code?: string;
  allProducts: boolean;
}
