import { createFeatureSelector, createSelector } from '@ngrx/store';
import { orderFeatureKey, orderFeatureState } from './order.reducer';

export const orderState =
  createFeatureSelector<orderFeatureState>(orderFeatureKey);

export const selectAllOrdersData = createSelector(
  orderState,
  ({ allOrders, appId }) => {
    return {
      allOrders,
      appId
    };
  }
);

export const selectLastOrdersData = createSelector(
  orderState,
  ({ appId, lastOrders }) => {
    return {
      appId,
      lastOrders
    };
  }
);

export const selectOrdersLoadingStatus = createSelector(
  orderState,
  ({ status }) => {
    return status == 'loading' || status == 'pending';
  }
);
