<span
  class="spinner p-10"
  [ngClass]="'spinner-' + type"
  *ngIf="!isFullScreenSpinner; else fullScreenSpinner"
></span>

<ng-template #fullScreenSpinner>
  <p-blockUI [blocked]="blockUI" [autoZIndex]="true">
    <span
      class="spinner p-10 full-screen__spinner"
      [ngClass]="'spinner-' + type"
    ></span>
  </p-blockUI>
</ng-template>
