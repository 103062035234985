<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{ 'ORDERS.NOTES' | translate }}</h3>
    </div>
  </div>
  <div class="card-body">
    <p>{{ orderInfo?.userNote | orderNote }}</p>
  </div>
</div>
