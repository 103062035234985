import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_KEYS } from '@pratik-islem/shared/data';

export interface Locale {
  lang: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  readonly #supportedLangs = ['en', 'tr'];
  #langIds: any = [];

  constructor(private translate: TranslateService) {
    this.translate.addLangs(this.#supportedLangs);
    this.translate.setDefaultLang('en');
  }

  loadTranslations(...args: Locale[]): void {
    const locales = [...args];
    locales.forEach((locale) => {
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.#langIds.push(locale.lang);
    });
    this.translate.addLangs(this.#langIds);
  }

  setLanguage(lang: any): void {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
    }
  }

  getSelectedLanguage(): any {
    const userLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE);
    const browserLanguage = localStorage.getItem(
      LOCAL_STORAGE_KEYS.BROWSER_LANGUAGE
    );
    const selectedLang =
      (userLanguage != null ? userLanguage : browserLanguage) ||
      this.translate.getDefaultLang();

    return selectedLang;
  }

  getLangList(): any {
    return this.translate.langs;
  }
}
