import { Pipe, PipeTransform } from '@angular/core';

import { Payment_Types } from '../../data';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translatePaymentType',
  standalone: true
})
export class TranslatePaymentTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    const paymentType = Payment_Types.find((x) => x.type == value);
    return paymentType != undefined
      ? this.translateService.instant(`SHARED.${paymentType.label}`)
      : '';
  }
}
