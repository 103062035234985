import { ShopilinkItemType } from './i-shopilink-item-types';

export interface IShopilinkItemModel {
  id?: string;
  shopiLinkId?: string;
  itemType: ShopilinkItemType;
  itemId: string;
  quantity: number;
  imageUrl: string;
}

export interface IShopilinkModel {
  slug: string;
  name: string;
  requestBilling: boolean;
  isActive: boolean;
  shopiLinkItems: IShopilinkItemModel[];
}
