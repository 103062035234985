import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'orderStatusTranslator',
  standalone: true
})
export class OrderStatusTranslatorPipe implements PipeTransform {
  private readonly orderStatusList = [
    { label: 'WaitingPayment', translationLabel: 'WAITING_PAYMENT' },
    { label: 'WaitingApproval', translationLabel: 'WAITING_APPROVAL' },
    { label: 'Approved', translationLabel: 'APPROVED' },
    { label: 'WaitingForSupplying', translationLabel: 'WAITING_FOR_SUPPLYING' },
    { label: 'Preparing', translationLabel: 'PREPARING' },
    { label: 'Shipped', translationLabel: 'SHIPPED' },
    { label: 'Delivered', translationLabel: 'DELIVERED' },
    { label: 'CancelRequested', translationLabel: 'CANCEL_REQUESTED' },
    { label: 'Canceled', translationLabel: 'CANCELED' },
    { label: 'Refunded', translationLabel: 'REFUNDED' },
    { label: 'PaymentFailed', translationLabel: 'PAYMENT_FAILED' },
    { label: 'Completed', translationLabel: 'COMPLETED' },
  ];
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    const orderStatusTranslationLabel = this.orderStatusList.find(
      (x) => x.label == value
    )?.translationLabel;
    if (!!orderStatusTranslationLabel) {
      return this.translateService.instant(
        `ORDERS.${orderStatusTranslationLabel}`
      );
    }
    return '';
  }
}
