export class CustomValidators {
  static validateCreditCard(number: string): boolean {
    var regex = new RegExp('^[0-9]{16}$');
    if (!regex.test(number)) return false;

    return this.checkByLuhnAlgorithem(number);
  }

  static validatePhoneNumber = (phoneNumber: string): boolean => {
    var found = phoneNumber.search(
      /^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/
    );
    if (found > -1) {
      return true;
    } else {
      return false;
    }
  };

  static validateEmail = (email: string): boolean => {
    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
    return regex.test(email);
  };

  private static checkByLuhnAlgorithem(val: string) {
    var sum = 0;
    for (var i = 0; i < val.length; i++) {
      var intVal = parseInt(val.substr(i, 1));
      if (i % 2 == 0) {
        intVal *= 2;
        if (intVal > 9) {
          intVal = 1 + (intVal % 10);
        }
      }
      sum += intVal;
    }
    return sum % 10 == 0;
  }
}
