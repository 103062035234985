export interface IAddBankModel {
  merchantId: string;
  merchantUser: string;
  merchantPassword: string;
  merchantStoreKey: string;
  bankName: string;
  bankCode: string;
}

export type IUpdateBankModel = IAddBankModel;
