import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[setTimelineRecordColor]',
})
export class SetTimelineRecordColorDirective {
  private colors = [
    'text-info',
    'text-warning',
    'text-primary',
    'text-success',
    'text-danger',
    'text-dark',
  ];

  @Input('id') id: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {}
  ngOnInit(): void {
    const idx = Math.floor(Math.random() * this.colors.length);
    this.renderer.addClass(this.el.nativeElement, this.colors[idx]);
  }
}
