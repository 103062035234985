import { CommonModule } from '@angular/common';
import { EmptyContentComponent } from './empty-content.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [EmptyContentComponent],
  imports: [CommonModule],
  exports: [EmptyContentComponent],
})
export class EmptyContentModule {}
