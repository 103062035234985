export class IdUtil {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  static isGUID(uuid): boolean {
    const result = uuid.match(
      /^[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$/i
    );
    if (result === null) {
      return false;
    }
    return true;
  }

  static isHexId(str) {
     var regex = /[^0-9a-f]/gi;
     return str.replace(regex, '') === str;
  }


  static isIdValid = (id): boolean => {
    try {
      if (id?.length !== 24) {
        return false;
      }
      const regex = new RegExp(/^[A-Za-z0-9]+$/);
      if (!regex.test(id)) {
        return false;
      }

      return true;
    } catch {
      return false;
    }
  };
}
