/*These two below sections should have same values */

export type featuresNames =
  | 'loggingSystem'
  | 'shopilink'
  | 'appStore'
  | 'allowPermissionForProject'
  | 'easyCollection';

export enum featuresList {
  'loggingSystem' = 'loggingSystem',
  'shopilink' = 'shopilink',
  'easyCollection' = 'easyCollection'
}
