import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { GetPaymentDetailsOfOrderModel } from '../../data/models/get-payment-details-of-order.model';
import { OrderService } from '../../data-access';
import { SubSink } from 'subsink';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { finalize } from 'rxjs/internal/operators/finalize';
import { timer } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateOrderPaymentStatusPipe } from '../../utils/pipes/translate-order-payment-status.pipe';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'order-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  standalone:true,
  imports:[
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    TranslateOrderPaymentStatusPipe, 
    TableModule
  ]
})
export class PaymentDetailsComponent implements OnInit {
  @Input()
  public isLoading = false;

  @Input()
  public appId = '';

  @Input()
  public orderInfo;

  @Output()
  public loadingStatusChange = new EventEmitter();

  public paymentDetail: any;

  public faInfo = faInfoCircle;

  public transactionList: any[] = [];
  public paginatedTransactionList: any[] = [];
  public showLoadMoreButton = false;

  private currentPage = 0;
  private pageSize = 2;
  private pageCounts = 0;
  private subs = new SubSink();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _orderService: OrderService
  ) {}

  ngOnInit(): void {
    this._getPaymentDetailsOfOrder();
  }

  public paginateTransactions(): void {
    const transactions = this.paymentDetail.transaction;
    if (transactions.length > 0) {
      this.loadingStatusChange.emit(true);
      this.subs.sink = timer(200).subscribe((_) => {
        this.pageCounts = transactions.length / this.pageSize;
        if (this.pageCounts === 0) {
          this.showLoadMoreButton = false;
        } else {
          const startIndex = this.currentPage * this.pageSize;
          const slicedProducts = transactions.slice(
            startIndex,
            startIndex + this.pageSize
          );
          if (slicedProducts.length > 0) {
            if (this.currentPage === 0) {
              this.paginatedTransactionList = slicedProducts;
            } else {
              this.paginatedTransactionList.push(...slicedProducts);
            }
            this.currentPage++;
          }
          if (this.paginatedTransactionList.length < transactions.length) {
            this.showLoadMoreButton = true;
          } else {
            this.showLoadMoreButton = false;
          }
        }
        this.loadingStatusChange.emit(false);
        this._cdr.detectChanges();
      });
    }
  }

  private _getPaymentDetailsOfOrder(): void {
    const payload: GetPaymentDetailsOfOrderModel = {
      appId: this.appId,
      orderId: this.orderInfo.id ?? ''
    };
    this.loadingStatusChange.emit(true);
    this.subs.sink = this._orderService
      .getPaymentDetailsOfOrder(payload)
      .pipe(finalize(() => this.loadingStatusChange.emit(false)))
      .subscribe((result) => {
        this.paymentDetail = result.data;
        this.paginateTransactions();
      });
  }
}
