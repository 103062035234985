import {
  UserProfileFeatureState,
  userProfileFeatureKey
} from './user-profile.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const userProfileState = createFeatureSelector<UserProfileFeatureState>(
  userProfileFeatureKey
);
export const selectUserProfileInformation = createSelector(
  userProfileState,
  ({ data }) => data
);
export const selectUserProfileInformationStatus = createSelector(
  userProfileState,
  ({ status }) => status
);
export const selectUserName = createSelector(userProfileState, ({ data }) =>
  data?.hasOwnProperty('fullName')
    ? data?.fullName.trim() == ''
      ? data?.email
      : data?.fullName
    : data?.email
);

export const selectUserProfileFullInformation = createSelector(
  userProfileState,
  ({ userFullInformation }) => userFullInformation
);
export const selectUserProfileFullInformationStatus = createSelector(
  userProfileState,
  ({ status }) => status
);
