export interface IErrorResponseDataModel {
  succeeded: boolean;
  errors: any[];
}
export interface IErrorResponse {
  data: IErrorResponseDataModel;
  key: string;
  message: string;
  success: boolean;
}

export interface IHttpErrorResponseModel {
  error: IErrorResponse;
  header: any;
  message: string;
  name: string;
  ok: boolean;
  status: number;
  statusText: string;
  url: string;
}

export class HttpErrorResponseModel implements IHttpErrorResponseModel {
   error!: IErrorResponse;
   header!: any;
   message!: string;
   name!: string;
   ok!: boolean;
   status!: number;
   statusText!: string;
   url!: string;
   setHttpErrorResponseModel(model: any): void {
    this.error = model.error;
    this.header = model.header;
    this.message = model.message;
    this.name = model.name;
    this.ok = model.ok;
    this.status = model.status;
    this.statusText = model.statusText;
    this.url = model.url;
  }
}
