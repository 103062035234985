export interface IApplicationUserModel {
  id: string;
  email: string;
  userName: string;
}

export class ApplicationUserModel implements IApplicationUserModel {
  id: string;
  email: string;
  userName: string;
}
export interface IRolePermissionsModel {
  id: number;
  claimType: string;
}

export class RolePermissionsModel implements IRolePermissionsModel {
  id: number;
  claimType: string;
}

export interface IRoleModel {
  id: string;
  name: string;
  roleType: string;
  permissions: RolePermissionsModel[];
}
export class RoleModel implements IRoleModel {
  id: string;
  name: string;
  roleType: string;
  permissions: RolePermissionsModel[];
}

export interface IApplicationModel {
  id: string;
  name: string;
  appKey: string;
  logoUrl: string;
  type: string;
  locales: string[];
  category: string;
  editedAt: string;
  createdAt: string;
  relativeEditedAt: string;
  user: ApplicationUserModel[];
  role: RoleModel;
}

export class ApplicationModel implements IApplicationModel {
  id: string;
  name: string;
  appKey: string;
  logoUrl: string;
  type: string;
  locales: string[];
  category: string;
  editedAt: string;
  createdAt: string;
  relativeEditedAt: string;
  user: ApplicationUserModel[];
  role: RoleModel;
}

export interface IApplicationsListResult {
  data: IApplicationModel[];
  meta: { count: number };
  success: boolean;
}

export class ApplicationsListResult implements IApplicationsListResult {
  data: IApplicationModel[];
  meta: { count: number };
  success: boolean;
}
