export const COUNTRIES_PHONE_CODE = [
  {
    name: 'Afghanistan',
    isO2: 'AF',
    code: '93'
  },
  {
    name: 'Aland Islands',
    isO2: 'AX',
    code: '358'
  },
  {
    name: 'Albania',
    isO2: 'AL',
    code: '355'
  },
  {
    name: 'Algeria',
    isO2: 'DZ',
    code: '213'
  },
  {
    name: 'American Samoa',
    isO2: 'AS',
    code: '1-684'
  },
  {
    name: 'Andorra',
    isO2: 'AD',
    code: '376'
  },
  {
    name: 'Angola',
    isO2: 'AO',
    code: '244'
  },
  {
    name: 'Anguilla',
    isO2: 'AI',
    code: '1-264'
  },
  {
    name: 'Antarctica',
    isO2: 'AQ',
    code: '672'
  },
  {
    name: 'Antigua and Barbuda',
    isO2: 'AG',
    code: '1-268'
  },
  {
    name: 'Argentina',
    isO2: 'AR',
    code: '54'
  },
  {
    name: 'Armenia',
    isO2: 'AM',
    code: '374'
  },
  {
    name: 'Aruba',
    isO2: 'AW',
    code: '297'
  },
  {
    name: 'Australia',
    isO2: 'AU',
    code: '61'
  },
  {
    name: 'Austria',
    isO2: 'AT',
    code: '43'
  },
  {
    name: 'Azerbaijan',
    isO2: 'AZ',
    code: '994'
  },
  {
    name: 'Bahamas',
    isO2: 'BS',
    code: '1-242'
  },
  {
    name: 'Bahrain',
    isO2: 'BH',
    code: '973'
  },
  {
    name: 'Bangladesh',
    isO2: 'BD',
    code: '880'
  },
  {
    name: 'Barbados',
    isO2: 'BB',
    code: '1-246'
  },
  {
    name: 'Belarus',
    isO2: 'BY',
    code: '375'
  },
  {
    name: 'Belgium',
    isO2: 'BE',
    code: '32'
  },
  {
    name: 'Belize',
    isO2: 'BZ',
    code: '501'
  },
  {
    name: 'Benin',
    isO2: 'BJ',
    code: '229'
  },
  {
    name: 'Bermuda',
    isO2: 'BM',
    code: '1-441'
  },
  {
    name: 'Bhutan',
    isO2: 'BT',
    code: '975'
  },
  {
    name: 'Bolivia',
    isO2: 'BO',
    code: '591'
  },
  {
    name: 'Bosnia and Herzegovina',
    isO2: 'BA',
    code: '387'
  },
  {
    name: 'Botswana',
    isO2: 'BW',
    code: '267'
  },
  {
    name: 'Bouvet Island',
    isO2: 'BV',
    code: '47'
  },
  {
    name: 'Brazil',
    isO2: 'BR',
    code: '55'
  },
  {
    name: 'British Virgin Islands',
    isO2: 'VG',
    code: '1-284'
  },
  {
    name: 'British Indian Ocean Territory',
    isO2: 'IO',
    code: '246'
  },
  {
    name: 'Brunei Darussalam',
    isO2: 'BN',
    code: '673'
  },
  {
    name: 'Bulgaria',
    isO2: 'BG',
    code: '359'
  },
  {
    name: 'Burkina Faso',
    isO2: 'BF',
    code: '226'
  },
  {
    name: 'Burundi',
    isO2: 'BI',
    code: '257'
  },
  {
    name: 'Cambodia',
    isO2: 'KH',
    code: '855'
  },
  {
    name: 'Cameroon',
    isO2: 'CM',
    code: '237'
  },
  {
    name: 'Canada',
    isO2: 'CA',
    code: '1'
  },
  {
    name: 'Cape Verde',
    isO2: 'CV',
    code: '238'
  },
  {
    name: 'Cayman Islands',
    isO2: 'KY',
    code: '1-345'
  },
  {
    name: 'Central African Republic',
    isO2: 'CF',
    code: '236'
  },
  {
    name: 'Chad',
    isO2: 'TD',
    code: '235'
  },
  {
    name: 'Chile',
    isO2: 'CL',
    code: '56'
  },
  {
    name: 'China',
    isO2: 'CN',
    code: '86'
  },
  {
    name: 'Hong Kong, SAR China',
    isO2: 'HK',
    code: '852'
  },
  {
    name: 'Macao, SAR China',
    isO2: 'MO',
    code: '853'
  },
  {
    name: 'Christmas Island',
    isO2: 'CX',
    code: '61'
  },
  {
    name: 'Cocos (Keeling) Islands',
    isO2: 'CC',
    code: '61'
  },
  {
    name: 'Colombia',
    isO2: 'CO',
    code: '57'
  },
  {
    name: 'Comoros',
    isO2: 'KM',
    code: '269'
  },
  {
    name: 'Congo (Brazzaville)',
    isO2: 'CG',
    code: '242'
  },
  {
    name: 'Congo, (Kinshasa)',
    isO2: 'CD',
    code: '243'
  },
  {
    name: 'Cook Islands',
    isO2: 'CK',
    code: '682'
  },
  {
    name: 'Costa Rica',
    isO2: 'CR',
    code: '506'
  },
  {
    name: "Côte d'Ivoire",
    isO2: 'CI',
    code: '225'
  },
  {
    name: 'Croatia',
    isO2: 'HR',
    code: '385'
  },
  {
    name: 'Cuba',
    isO2: 'CU',
    code: '53'
  },
  {
    name: 'Cyprus',
    isO2: 'CY',
    code: '357'
  },
  {
    name: 'Czech Republic',
    isO2: 'CZ',
    code: '420'
  },
  {
    name: 'Denmark',
    isO2: 'DK',
    code: '45'
  },
  {
    name: 'Djibouti',
    isO2: 'DJ',
    code: '253'
  },
  {
    name: 'Dominica',
    isO2: 'DM',
    code: '1-767'
  },
  {
    name: 'Dominican Republic',
    isO2: 'DO',
    code: '1-809'
  },
  {
    name: 'Ecuador',
    isO2: 'EC',
    code: '593'
  },
  {
    name: 'Egypt',
    isO2: 'EG',
    code: '20'
  },
  {
    name: 'El Salvador',
    isO2: 'SV',
    code: '503'
  },
  {
    name: 'Equatorial Guinea',
    isO2: 'GQ',
    code: '240'
  },
  {
    name: 'Eritrea',
    isO2: 'ER',
    code: '291'
  },
  {
    name: 'Estonia',
    isO2: 'EE',
    code: '372'
  },
  {
    name: 'Ethiopia',
    isO2: 'ET',
    code: '251'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    isO2: 'FK',
    code: '500'
  },
  {
    name: 'Faroe Islands',
    isO2: 'FO',
    code: '298'
  },
  {
    name: 'Fiji',
    isO2: 'FJ',
    code: '679'
  },
  {
    name: 'Finland',
    isO2: 'FI',
    code: '358'
  },
  {
    name: 'France',
    isO2: 'FR',
    code: '33'
  },
  {
    name: 'French Guiana',
    isO2: 'GF',
    code: '594'
  },
  {
    name: 'French Polynesia',
    isO2: 'PF',
    code: '689'
  },
  {
    name: 'French Southern Territories',
    isO2: 'TF',
    code: '262'
  },
  {
    name: 'Gabon',
    isO2: 'GA',
    code: '241'
  },
  {
    name: 'Gambia',
    isO2: 'GM',
    code: '220'
  },
  {
    name: 'Georgia',
    isO2: 'GE',
    code: '995'
  },
  {
    name: 'Germany',
    isO2: 'DE',
    code: '49'
  },
  {
    name: 'Ghana',
    isO2: 'GH',
    code: '233'
  },
  {
    name: 'Gibraltar',
    isO2: 'GI',
    code: '350'
  },
  {
    name: 'Greece',
    isO2: 'GR',
    code: '30'
  },
  {
    name: 'Greenland',
    isO2: 'GL',
    code: '299'
  },
  {
    name: 'Grenada',
    isO2: 'GD',
    code: '1-473'
  },
  {
    name: 'Guadeloupe',
    isO2: 'GP',
    code: '590'
  },
  {
    name: 'Guam',
    isO2: 'GU',
    code: '1-671'
  },
  {
    name: 'Guatemala',
    isO2: 'GT',
    code: '502'
  },
  {
    name: 'Guernsey',
    isO2: 'GG',
    code: '44'
  },
  {
    name: 'Guinea',
    isO2: 'GN',
    code: '224'
  },
  {
    name: 'Guinea-Bissau',
    isO2: 'GW',
    code: '245'
  },
  {
    name: 'Guyana',
    isO2: 'GY',
    code: '592'
  },
  {
    name: 'Haiti',
    isO2: 'HT',
    code: '509'
  },
  {
    name: 'Heard and Mcdonald Islands',
    isO2: 'HM',
    code: '672'
  },
  {
    name: 'Holy See (Vatican City State)',
    isO2: 'VA',
    code: '379'
  },
  {
    name: 'Honduras',
    isO2: 'HN',
    code: '504'
  },
  {
    name: 'Hungary',
    isO2: 'HU',
    code: '36'
  },
  {
    name: 'Iceland',
    isO2: 'IS',
    code: '354'
  },
  {
    name: 'India',
    isO2: 'IN',
    code: '91'
  },
  {
    name: 'Indonesia',
    isO2: 'ID',
    code: '62'
  },
  {
    name: 'Iran, Islamic Republic of',
    isO2: 'IR',
    code: '98'
  },
  {
    name: 'Iraq',
    isO2: 'IQ',
    code: '964'
  },
  {
    name: 'Ireland',
    isO2: 'IE',
    code: '353'
  },
  {
    name: 'Isle of Man',
    isO2: 'IM',
    code: '44'
  },
  {
    name: 'Israel',
    isO2: 'IL',
    code: '972'
  },
  {
    name: 'Italy',
    isO2: 'IT',
    code: '39'
  },
  {
    name: 'Jamaica',
    isO2: 'JM',
    code: '1-876'
  },
  {
    name: 'Japan',
    isO2: 'JP',
    code: '81'
  },
  {
    name: 'Jersey',
    isO2: 'JE',
    code: '44'
  },
  {
    name: 'Jordan',
    isO2: 'JO',
    code: '962'
  },
  {
    name: 'Kazakhstan',
    isO2: 'KZ',
    code: '7'
  },
  {
    name: 'Kenya',
    isO2: 'KE',
    code: '254'
  },
  {
    name: 'Kiribati',
    isO2: 'KI',
    code: '686'
  },
  {
    name: 'Korea (North)',
    isO2: 'KP',
    code: '850'
  },
  {
    name: 'Korea (South)',
    isO2: 'KR',
    code: '82'
  },
  {
    name: 'Kuwait',
    isO2: 'KW',
    code: '965'
  },
  {
    name: 'Kyrgyzstan',
    isO2: 'KG',
    code: '996'
  },
  {
    name: 'Lao PDR',
    isO2: 'LA',
    code: '856'
  },
  {
    name: 'Latvia',
    isO2: 'LV',
    code: '371'
  },
  {
    name: 'Lebanon',
    isO2: 'LB',
    code: '961'
  },
  {
    name: 'Lesotho',
    isO2: 'LS',
    code: '266'
  },
  {
    name: 'Liberia',
    isO2: 'LR',
    code: '231'
  },
  {
    name: 'Libya',
    isO2: 'LY',
    code: '218'
  },
  {
    name: 'Liechtenstein',
    isO2: 'LI',
    code: '423'
  },
  {
    name: 'Lithuania',
    isO2: 'LT',
    code: '370'
  },
  {
    name: 'Luxembourg',
    isO2: 'LU',
    code: '352'
  },
  {
    name: 'Macedonia, Republic of',
    isO2: 'MK',
    code: '389'
  },
  {
    name: 'Madagascar',
    isO2: 'MG',
    code: '261'
  },
  {
    name: 'Malawi',
    isO2: 'MW',
    code: '265'
  },
  {
    name: 'Malaysia',
    isO2: 'MY',
    code: '60'
  },
  {
    name: 'Maldives',
    isO2: 'MV',
    code: '960'
  },
  {
    name: 'Mali',
    isO2: 'ML',
    code: '223'
  },
  {
    name: 'Malta',
    isO2: 'MT',
    code: '356'
  },
  {
    name: 'Marshall Islands',
    isO2: 'MH',
    code: '692'
  },
  {
    name: 'Martinique',
    isO2: 'MQ',
    code: '596'
  },
  {
    name: 'Mauritania',
    isO2: 'MR',
    code: '222'
  },
  {
    name: 'Mauritius',
    isO2: 'MU',
    code: '230'
  },
  {
    name: 'Mayotte',
    isO2: 'YT',
    code: '262'
  },
  {
    name: 'Mexico',
    isO2: 'MX',
    code: '52'
  },
  {
    name: 'Micronesia, Federated States of',
    isO2: 'FM',
    code: '691'
  },
  {
    name: 'Moldova, Republic of',
    isO2: 'MD',
    code: '373'
  },
  {
    name: 'Monaco',
    isO2: 'MC',
    code: '377'
  },
  {
    name: 'Mongolia',
    isO2: 'MN',
    code: '976'
  },
  {
    name: 'Montenegro',
    isO2: 'ME',
    code: '382'
  },
  {
    name: 'Montserrat',
    isO2: 'MS',
    code: '1-664'
  },
  {
    name: 'Morocco',
    isO2: 'MA',
    code: '212'
  },
  {
    name: 'Mozambique',
    isO2: 'MZ',
    code: '258'
  },
  {
    name: 'Myanmar',
    isO2: 'MM',
    code: '95'
  },
  {
    name: 'Namibia',
    isO2: 'NA',
    code: '264'
  },
  {
    name: 'Nauru',
    isO2: 'NR',
    code: '674'
  },
  {
    name: 'Nepal',
    isO2: 'NP',
    code: '977'
  },
  {
    name: 'Netherlands',
    isO2: 'NL',
    code: '31'
  },
  {
    name: 'Netherlands Antilles',
    isO2: 'AN',
    code: '599'
  },
  {
    name: 'New Caledonia',
    isO2: 'NC',
    code: '687'
  },
  {
    name: 'New Zealand',
    isO2: 'NZ',
    code: '64'
  },
  {
    name: 'Nicaragua',
    isO2: 'NI',
    code: '505'
  },
  {
    name: 'Niger',
    isO2: 'NE',
    code: '227'
  },
  {
    name: 'Nigeria',
    isO2: 'NG',
    code: '234'
  },
  {
    name: 'Niue',
    isO2: 'NU',
    code: '683'
  },
  {
    name: 'Norfolk Island',
    isO2: 'NF',
    code: '672'
  },
  {
    name: 'Northern Mariana Islands',
    isO2: 'MP',
    code: '1-670'
  },
  {
    name: 'Norway',
    isO2: 'NO',
    code: '47'
  },
  {
    name: 'Oman',
    isO2: 'OM',
    code: '968'
  },
  {
    name: 'Pakistan',
    isO2: 'PK',
    code: '92'
  },
  {
    name: 'Palau',
    isO2: 'PW',
    code: '680'
  },
  {
    name: 'Palestinian Territory',
    isO2: 'PS',
    code: '970'
  },
  {
    name: 'Panama',
    isO2: 'PA',
    code: '507'
  },
  {
    name: 'Papua New Guinea',
    isO2: 'PG',
    code: '675'
  },
  {
    name: 'Paraguay',
    isO2: 'PY',
    code: '595'
  },
  {
    name: 'Peru',
    isO2: 'PE',
    code: '51'
  },
  {
    name: 'Philippines',
    isO2: 'PH',
    code: '63'
  },
  {
    name: 'Pitcairn',
    isO2: 'PN',
    code: '870'
  },
  {
    name: 'Poland',
    isO2: 'PL',
    code: '48'
  },
  {
    name: 'Portugal',
    isO2: 'PT',
    code: '351'
  },
  {
    name: 'Puerto Rico',
    isO2: 'PR',
    code: '1'
  },
  {
    name: 'Qatar',
    isO2: 'QA',
    code: '974'
  },
  {
    name: 'Réunion',
    isO2: 'RE',
    code: '262'
  },
  {
    name: 'Romania',
    isO2: 'RO',
    code: '40'
  },
  {
    name: 'Russian Federation',
    isO2: 'RU',
    code: '7'
  },
  {
    name: 'Rwanda',
    isO2: 'RW',
    code: '250'
  },
  {
    name: 'Saint-Barthélemy',
    isO2: 'BL',
    code: '590'
  },
  {
    name: 'Saint Helena',
    isO2: 'SH',
    code: '290'
  },
  {
    name: 'Saint Kitts and Nevis',
    isO2: 'KN',
    code: '1-869'
  },
  {
    name: 'Saint Lucia',
    isO2: 'LC',
    code: '1-758'
  },
  {
    name: 'Saint-Martin (French)',
    isO2: 'MF',
    code: '590'
  },
  {
    name: 'Saint Pierre and Miquelon',
    isO2: 'PM',
    code: '508'
  },
  {
    name: 'Saint Vincent and Grenadines',
    isO2: 'VC',
    code: '1-784'
  },
  {
    name: 'Samoa',
    isO2: 'WS',
    code: '685'
  },
  {
    name: 'San Marino',
    isO2: 'SM',
    code: '378'
  },
  {
    name: 'Sao Tome and Principe',
    isO2: 'ST',
    code: '239'
  },
  {
    name: 'Saudi Arabia',
    isO2: 'SA',
    code: '966'
  },
  {
    name: 'Senegal',
    isO2: 'SN',
    code: '221'
  },
  {
    name: 'Serbia',
    isO2: 'RS',
    code: '381'
  },
  {
    name: 'Seychelles',
    isO2: 'SC',
    code: '248'
  },
  {
    name: 'Sierra Leone',
    isO2: 'SL',
    code: '232'
  },
  {
    name: 'Singapore',
    isO2: 'SG',
    code: '65'
  },
  {
    name: 'Slovakia',
    isO2: 'SK',
    code: '421'
  },
  {
    name: 'Slovenia',
    isO2: 'SI',
    code: '386'
  },
  {
    name: 'Solomon Islands',
    isO2: 'SB',
    code: '677'
  },
  {
    name: 'Somalia',
    isO2: 'SO',
    code: '252'
  },
  {
    name: 'South Africa',
    isO2: 'ZA',
    code: '27'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    isO2: 'GS',
    code: '500'
  },
  {
    name: 'South Sudan',
    isO2: 'SS',
    code: '211'
  },
  {
    name: 'Spain',
    isO2: 'ES',
    code: '34'
  },
  {
    name: 'Sri Lanka',
    isO2: 'LK',
    code: '94'
  },
  {
    name: 'Sudan',
    isO2: 'SD',
    code: '249'
  },
  {
    name: 'Suriname',
    isO2: 'SR',
    code: '597'
  },
  {
    name: 'Svalbard and Jan Mayen Islands',
    isO2: 'SJ',
    code: '47'
  },
  {
    name: 'Swaziland',
    isO2: 'SZ',
    code: '268'
  },
  {
    name: 'Sweden',
    isO2: 'SE',
    code: '46'
  },
  {
    name: 'Switzerland',
    isO2: 'CH',
    code: '41'
  },
  {
    name: 'Syrian Arab Republic (Syria)',
    isO2: 'SY',
    code: '963'
  },
  {
    name: 'Taiwan, Republic of China',
    isO2: 'TW',
    code: '886'
  },
  {
    name: 'Tajikistan',
    isO2: 'TJ',
    code: '992'
  },
  {
    name: 'Tanzania, United Republic of',
    isO2: 'TZ',
    code: '255'
  },
  {
    name: 'Thailand',
    isO2: 'TH',
    code: '66'
  },
  {
    name: 'Timor-Leste',
    isO2: 'TL',
    code: '670'
  },
  {
    name: 'Togo',
    isO2: 'TG',
    code: '228'
  },
  {
    name: 'Tokelau',
    isO2: 'TK',
    code: '690'
  },
  {
    name: 'Tonga',
    isO2: 'TO',
    code: '676'
  },
  {
    name: 'Trinidad and Tobago',
    isO2: 'TT',
    code: '1-868'
  },
  {
    name: 'Tunisia',
    isO2: 'TN',
    code: '216'
  },
  {
    name: 'Turkey',
    isO2: 'TR',
    code: '90'
  },
  {
    name: 'Turkmenistan',
    isO2: 'TM',
    code: '993'
  },
  {
    name: 'Turks and Caicos Islands',
    isO2: 'TC',
    code: '1-649'
  },
  {
    name: 'Tuvalu',
    isO2: 'TV',
    code: '688'
  },
  {
    name: 'Uganda',
    isO2: 'UG',
    code: '256'
  },
  {
    name: 'Ukraine',
    isO2: 'UA',
    code: '380'
  },
  {
    name: 'United Arab Emirates',
    isO2: 'AE',
    code: '971'
  },
  {
    name: 'United Kingdom',
    isO2: 'GB',
    code: '44'
  },
  {
    name: 'United States of America',
    isO2: 'US',
    code: '1'
  },
  {
    name: 'US Minor Outlying Islands',
    isO2: 'UM',
    code: '1'
  },
  {
    name: 'Uruguay',
    isO2: 'UY',
    code: '598'
  },
  {
    name: 'Uzbekistan',
    isO2: 'UZ',
    code: '998'
  },
  {
    name: 'Vanuatu',
    isO2: 'VU',
    code: '678'
  },
  {
    name: 'Venezuela (Bolivarian Republic)',
    isO2: 'VE',
    code: '58'
  },
  {
    name: 'Viet Nam',
    isO2: 'VN',
    code: '84'
  },
  {
    name: 'Virgin Islands, US',
    isO2: 'VI',
    code: '1-340'
  },
  {
    name: 'Wallis and Futuna Islands',
    isO2: 'WF',
    code: '681'
  },
  {
    name: 'Western Sahara',
    isO2: 'EH',
    code: '212'
  },
  {
    name: 'Yemen',
    isO2: 'YE',
    code: '967'
  },
  {
    name: 'Zambia',
    isO2: 'ZM',
    code: '260'
  },
  {
    name: 'Zimbabwe',
    isO2: 'ZW',
    code: '263'
  }
];
