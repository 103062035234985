import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSetRefundProductsImage]',
  standalone: true
})
export class SetRefundProductsImageDirective {
  @Input('id') id: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {}
  ngOnInit(): void {
    if (this.id == undefined) {
      return;
    }
    this.renderer.setStyle(
      this.el.nativeElement,
      'background',
      `url('${this.id}')`
    );
    this.renderer.setStyle(this.el.nativeElement, 'background-size', 'cover');
  }
}
