import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss']
})
export class ProductImageComponent {
  @Input()
  productImage: any;

  @Input()
  imageContainerCssClasses = '';

  @Input()
  imageCssClasses = '';

  constructor() {}

  public imageIsEmpty(img): boolean {
    if (img == null) return true;
    if (Object.keys(img).length === 0) return true;
    const thumbnailImage = img?.t;
    const thumbnailImageFormat = thumbnailImage.substring(
      img?.t.lastIndexOf('.')
    );
    const lastCharacterBeforeFormat =
      thumbnailImage.lastIndexOf(thumbnailImageFormat) - 1;
    if (thumbnailImage[lastCharacterBeforeFormat] == '/') {
      return true;
    }
    return false;
  }
}
