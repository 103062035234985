export type ShoppayPlatformTypes =
  | 'Social Media'
  | 'My Web Site'
  | 'Marketplace'
  | 'Other';

export type ShoppayConfigurationTypes =
  | 'PERSONAL'
  | 'PERSONAL_COMPANY'
  | 'COMPANY';

export interface ShoppayConfigurationModel {
  name: string;
  email: string;
  gsm: string;
  bankIban: string;
  bankName: string;
  companyName: string;
  ownerName: string;
  ownerSurname: string;
  ownerIdentityNumber: string;
  type: ShoppayConfigurationTypes;
  products: string;
  birthDate: string;
  address: string;
  platforms: string;
  otherPlatforms?: string;
  taxOffice?: string;
  taxNumber?: string;
}

export interface ShoppayConfigurationPayloadModel {
  configuration: ShoppayConfigurationModel;
  paymentType: string;
  name: string;
}
