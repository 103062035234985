export interface IWebSettingModel {
  theme: string;
  metaTags: string[];
  allowIndexing: boolean;
  customDomain: string;
  subdomain: string;
  metaTagTitle: string;
  metaTagDescription: string;
}

export interface WebSettingPartialUpdateModel {
  theme?: string;
  customDomain?: string;
  subdomain?: string;
  metaTagTitle?: string;
  metaTagDescription?: string;
  allowIndexing?: boolean;
  gtmId?: string;
  gaId?: string;
  guestShopping?: boolean;
  webHook?:boolean
}
