import { GenericState, GenericStoreStatus } from '@pratik-islem/core';
import {
  LoadAllOrders,
  LoadAllOrdersFailure,
  LoadAllOrdersSuccess,
  LoadDealerBalanceData,
  LoadDealerBalanceDataFailure,
  LoadDealerBalanceDataSuccess,
  LoadLastOrders,
  LoadLastOrdersFailure,
  LoadLastOrdersSuccess
} from './order.actions';
import { createReducer, on } from '@ngrx/store';

import { OrdersDataModel } from '../../data/models';

export const orderFeatureKey = 'feature-order';

export class orderFeatureState implements GenericState<any> {
  appId!: string;
  lastOrders!: OrdersDataModel | null;
  allOrders!: OrdersDataModel | null;
  dealerBalance: any;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}

const initialState: orderFeatureState = {
  appId: '',
  lastOrders: null,
  allOrders: null,
  dealerBalance: null,
  error: null,
  status: 'pending'
};

export const orderFeatureReducer = createReducer(
  initialState,
  on(LoadLastOrders, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(LoadLastOrdersSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      lastOrders: response.OrdersData,
      status: 'success',
      error: null
    };
  }),
  on(LoadLastOrdersFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(LoadAllOrders, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(LoadAllOrdersSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      allOrders: response.OrdersData,
      status: 'success',
      error: null
    };
  }),
  on(LoadAllOrdersFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(LoadDealerBalanceData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(LoadDealerBalanceDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      dealerBalance: response.dealerBalance,
      status: 'success',
      error: null
    };
  }),
  on(LoadDealerBalanceDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
