export interface HookRequestsHeaderModel {
  name: string;
  value: string;
}

export interface HookRequestsModel {
  id?: string;
  url: string;
  maxRetryCount: number;
  method: string;
  headers: HookRequestsHeaderModel[];
}

export interface CreateHookPayloadModel {
  type: string;
  name: string;
  requests: HookRequestsModel[];
}

export interface CreateHookModel {
  appId: string;
  payload: CreateHookPayloadModel;
}

export interface HookResponseModel {
  id: string;
  key: string;
  name: string;
  tenantId: string;
  requests: HookRequestsModel[];
}

export interface UpdateHookModel {
  appId: string;
  hookId: string;
  payload: CreateHookPayloadModel;
}

export interface GetAllHooksModel {
  appId: string;
}

export interface GetAllHooksResponseModel {
  appId: string;
  data: {
    id: string;
    key: string;
    name: string;
    tenantId: string;
  };
}

export interface GetHookModel {
  hookId: string;
}

export interface DeleteHookModel {
  hookId: string;
}
