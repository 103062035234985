import { AnimatedLogoModule } from '../animated-logo/animated-logo.module';
import { BlockUIModule } from 'primeng/blockui';
import { CommonModule } from '@angular/common';
import { ContentContainerComponent } from './content-container.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ContentContainerComponent],
  imports: [
    CommonModule,
    BlockUIModule,
    TranslateModule,
    RouterModule,
    AnimatedLogoModule
  ],
  exports: [ContentContainerComponent]
})
export class ContentContainerModule {}
