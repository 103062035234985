import { Routes } from '@angular/router';
import { StoresShellComponent } from '../features/shell/stores-shell.component';

export const STORES_ROUTES: Routes = [
  {
    path: '',
    component: StoresShellComponent,
    children: [
      {
        path: 'list',
        loadComponent: async () =>
          (await import('../features/store-list/store-list.component'))
            .StoreListComponent
      },
      { path: '', redirectTo: 'list', pathMatch: 'full' }
    ]
  }
];

export const STORES_ROUTES_PATH = {
  ROOT: '/stores',
  LIST: '/stores/list'
};
