import { StoresFeatureEffects } from '@pratik-islem/stores';
import { DashboardFeatureEffects } from '@pratik-islem/store/dashboard';
import { OrderFeatureEffects } from '@pratik-islem/store/orders';
import { SalesChannelFeatureEffects } from '@pratik-islem/store/sales-channel';
import { UserProfileFeatureEffects } from '@pratik-islem/user-profile';
import { StoreActionsResultMessageEffect } from './store-actions-result-message.effects';
import { WebHookFeatureEffects } from '@pratik-islem/store/webhook';

export const APP_EFFECTS = [
  UserProfileFeatureEffects,
  StoresFeatureEffects,
  DashboardFeatureEffects,
  SalesChannelFeatureEffects,
  OrderFeatureEffects,
  StoreActionsResultMessageEffect,
  WebHookFeatureEffects
];
