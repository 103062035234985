import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { AddressService, IdUtil } from '@pratik-islem/shared/utils';
import { SubSink } from 'subsink';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'order-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, TitleCasePipe]
})
export class ClientInformationComponent implements OnInit {
  @Input()
  public orderInfo: any;
  public UserCity = '';
  public UserCountry = '';
  public nameSurname = '';
  private subs = new SubSink();

  constructor(
    private _addressService: AddressService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.orderInfo?.billingAddress) {
      this.nameSurname = this.orderInfo?.billingAddress?.nameSurname;
      this.UserCountry = this.orderInfo?.billingAddress.country;
      this.UserCity = this.orderInfo?.billingAddress.state;
    }
    
    if (!this.nameSurname && this.orderInfo.buyer) {
      this.nameSurname = `${this.orderInfo?.buyer.name} ${this.orderInfo?.buyer.surname}`;
    }

    if (
      this.UserCountry != 'Digital Product' &&
      IdUtil.isHexId(this.UserCountry) &&
      IdUtil.isHexId(this.UserCity)
    ) {
      if (this.UserCountry == this.UserCity) {
        this._getUserCountry(this.UserCountry);
      } else {
        this._getUserCountry(this.UserCountry);
        this._getUserCity(this.UserCountry, this.UserCity);
      }
    }
  }

  private _getUserCity(countryId: string, cityId: string): void {
    this.subs.sink = this._addressService
      .getCityListOfCountry(countryId)
      .subscribe((cities) => {
        const matchedCity = cities.find((x) => x.id == cityId);
        this.UserCity = matchedCity == undefined ? '' : matchedCity.name;
        this._cdr.detectChanges();
      });
  }

  private _getUserCountry(countryId: string): void {
    this.subs.sink = this._addressService
      .getCountriesList()
      .subscribe((countries) => {
        const matchedCountry = countries.find((x) => x.id == countryId);
        this.UserCountry =
          matchedCountry == undefined ? '' : matchedCountry.name;
        this._cdr.detectChanges();
      });
  }
}
