export interface AppModel {
  appKey: string;
  createdAt: string;
  editedAt: string;
  id: string;
  isAppsumo: boolean;
  name: string;
  relativeEditedAt: string;
}

export interface AppListResultModel {
  data: AppModel[];
  meta: {
    count: number;
  };
}
