
// Keep all data relate to the selected app
// Other data of app which are list will hold in sperated feature store
export interface SelectedAppDataModel {
  appDetails: any;
  paymentInfo: any;
  languageList: any;
  supportedSalesChannelList: string[];
  webSettings:any
}
