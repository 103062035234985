import { END_POINTS_URL } from '@pratik-islem/shared/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import {
  IAddBankModel,
  IAddLevelModel,
  IUpdateBankModel,
  IUpdateLevelModel
} from '../../models';
import { IAddCostModel, IUpdateCostModel } from '../../models/add-cost.model';

@Injectable({
  providedIn: 'root'
})
export class AdminUserProfileService {
  constructor(private http: HttpClient) {}

  getBanksList(): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}`;
    return this.http.get(URL).pipe(map((response: any) => response.data));
  }

  deleteBank(bankId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/${bankId}`;
    return this.http.delete(URL);
  }

  getBankTypes(): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/types`;
    return this.http.get(URL).pipe(map((response: any) => response.data));
  }

  addBank(dto: IAddBankModel): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}`;
    return this.http.post(URL, dto);
  }

  updateBank(bankId: string, dto: IUpdateBankModel): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/${bankId}`;
    return this.http.put(URL, dto);
  }

  getCostsList(): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/costs`;
    return this.http.get(URL).pipe(map((response: any) => response.data));
  }

  addCost(bankId: string, dto: IAddCostModel): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/${bankId}/costs`;
    return this.http.post(URL, dto);
  }

  updateCost(
    bankId: string,
    costId: string,
    dto: IUpdateCostModel
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/${bankId}/costs/${costId}`;
    return this.http.put(URL, dto);
  }

  deleteCost(bankId: string, costId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.BANKS_URL}/${bankId}/costs/${costId}`;
    return this.http.delete(URL);
  }

  filterLevels(
    searchedName: string = '',
    currentPage: number,
    perPage: number,
    sortByType: 'Ascending' | 'Descending' = 'Descending',
    sortByOption: 'Name' | 'Day' | 'CreationDate' = 'CreationDate'
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.LEVELS_URL}/?name=${searchedName}&page=${currentPage}&perPage=${perPage}&sortBy=${sortByType}&sort=${sortByOption}`;
    return this.http.get<any>(URL);
  }

  addLevel(dto: IAddLevelModel): Observable<any> {
    const URL = `${END_POINTS_URL.V2.LEVELS_URL}`;
    return this.http.post(URL, dto);
  }

  updateLevel(levelId: string, dto: IUpdateLevelModel): Observable<any> {
    const URL = `${END_POINTS_URL.V2.LEVELS_URL}/${levelId}`;
    return this.http.put(URL, dto);
  }

  filterApps(
    searchedApp: string = '',
    currentPage: number,
    perPage: number,
    sortByType: 'Ascending' | 'Descending' = 'Descending',
    sortByOption: 'Name' | 'CreatedAt' = 'CreatedAt',
    minCreatedDate: string = '',
    maxCreatedDate: string = ''
  ): Observable<any> {
    let URL = `${END_POINTS_URL.V2_1.SYSTEM_URL}/apps/filter?name=${searchedApp}&page=${currentPage}&perPage=${perPage}&sortBy=${sortByType}&sort=${sortByOption}`;
    if (minCreatedDate != '') {
      URL += `&CreatedAt.Min=${minCreatedDate}`;
    }
    if (maxCreatedDate != '') {
      URL += `&CreatedAt.Max=${maxCreatedDate}`;
    }
    return this.http.get<any>(URL);
  }

  getAppsLevel(appsId: string[]) {
    const URL = `${END_POINTS_URL.V2.LEVELS_URL}/apps?${appsId
      .map((id) => `appIds=${id}`)
      .join('&')}`;
    return this.http.get<any>(URL).pipe(map((response: any) => response.data));
  }

  assignLevelToApp(
    payload:any
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.LEVELS_URL}/${payload.levelId}/apps/${payload.appId}?isCustom=${payload.isCustom}`;
    return this.http.post(URL, {});
  }
}
