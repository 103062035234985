import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'orderStatusWithDescriptionTranslator',
  standalone: true
})
export class OrderStatusWithDescriptionTranslatorPipe implements PipeTransform {
  private readonly orderStatusList = [
    { label: 'WaitingPayment', translationLabel: 'WAITING_PAYMENT_ORDER_STATUS_DESCRIPTION' },
    { label: 'WaitingApproval', translationLabel: 'WAITING_APPROVAL_ORDER_STATUS_DESCRIPTION' },
    { label: 'Approved', translationLabel: 'APPROVED_ORDER_STATUS_DESCRIPTION' },
    { label: 'WaitingForSupplying', translationLabel: 'WAITING_FOR_SUPPLYING_ORDER_STATUS_DESCRIPTION' },
    { label: 'Preparing', translationLabel: 'PREPARING_ORDER_STATUS_DESCRIPTION' },
    { label: 'Shipped', translationLabel: 'SHIPPED_ORDER_STATUS_DESCRIPTION' },
    { label: 'Delivered', translationLabel: 'DELIVERED_ORDER_STATUS_DESCRIPTION' },
    { label: 'CancelRequested', translationLabel: 'CANCEL_REQUESTED_ORDER_STATUS_DESCRIPTION' },
    { label: 'Canceled', translationLabel: 'CANCELED_ORDER_STATUS_DESCRIPTION' },
    { label: 'Refunded', translationLabel: 'REFUNDED_ORDER_STATUS_DESCRIPTION' },
    { label: 'PaymentFailed', translationLabel: 'PAYMENT_FAILED_ORDER_STATUS_DESCRIPTION' },
    { label: 'Completed', translationLabel: 'COMPLETED_ORDER_STATUS_DESCRIPTION' },
  ];

  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    const orderStatusTranslationLabel = this.orderStatusList.find(
      (x) => x.label == value
    )?.translationLabel;
    if (!!orderStatusTranslationLabel) {
      return this.translateService.instant(
        `ORDERS.${orderStatusTranslationLabel}`
      );
    }
    return '';
  }
}
