import { END_POINTS_URL,REST_REQUEST_RESULT } from '@pratik-islem/shared/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { StoreInformationModel,GetShopipayCommissionRateDto } from '../../models';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  public getPaymentInfo(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/settings/payment`;
    return this.http.get(URL).pipe(
      map((result: any) => {
        return result.data as StoreInformationModel;
      })
    );
  }

  public updatePaymentInfo(appId: string, data: any): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/settings/payment`;
    return this.http.put(URL, data, { observe: 'response' }).pipe(
      map((result: any) => {
        if (
          result?.success === true ||
          result.status === 200 ||
          result.status === 201 ||
          result.status === 204
        ) {
          return REST_REQUEST_RESULT.SUCCESSFUL;
        }
        return REST_REQUEST_RESULT.FAILED;
      })
    );
  }

  getShopipayCommissionRate(
    dto: GetShopipayCommissionRateDto
  ): Observable<string> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${dto.appId}/settings/payment/rate`;

    return this.http.get(URL).pipe(map((response: any) => response.data));
  }
}
