import { AnimatedLogoModule } from '../animated-logo/animated-logo.module';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageUploaderComponent } from './image-uploader.component';
import { NgModule } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ImageUploaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    FileUploadModule,
    ConfirmDialogModule,
    AnimatedLogoModule,
    ToastModule,
  ],
  exports: [ImageUploaderComponent],
})
export class ImageUploaderModule {}
