export interface ConfirmCancelOrderModel {
  appId: string;
  orderId: string;
  payload: {
    cancellationReason: string;
    description: string;
    code: string;
  };
}

export interface OrderProductModel {
  productId: string;
  quantity: number;
}

export interface ConfirmRefundOrderModel {
  appId: string;
  orderId: string;
  payload: {
    des: string;
    pid: string;
    reason: string;
    price: number;
    commissionRate: number;
    orderId: string;
    marketPlaceId: string;
    allProducts: true;
    products: OrderProductModel[];
    code: string;
  };
}

export interface DirectCancelOrderModel {
  appId: string;
  orderId: string;
  payload: {
    cancellationReason: string;
    description: string;
    code: string;
  };
}
