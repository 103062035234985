<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{ 'ORDERS.CARGO_INFORMATION' | translate }}
      </h3>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-6 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-1 font-size-lg">
          {{ 'ORDERS.CURRENT_AMOUNT_OF_CARGO' | translate }}
        </span>
        <span class="text-muted font-weight-bold">
          {{
            cargoPrice
              | currency : currency : 'symbol-narrow' : '.2-2' : defaultLocale
          }}
        </span>
      </div>
      <div class="col-6 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-1 font-size-lg">
          {{ 'ORDERS.CARGO_BARCODE' | translate }}
        </span>
        <span class="text-muted font-weight-bold">
          {{ barcode }}
        </span>
      </div>
      <div class="col-12 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-1 font-size-lg">
          {{ 'ORDERS.CARGO_TRACKING_LINK' | translate }}
        </span>
        <span class="text-muted font-weight-bold">{{
          shippingTrackingUrl
        }}</span>
      </div>
    </div>
    <div class="row mt-10">
      <app-alert
        [alertType]="'primary'"
        [message]="'ORDERS.DECLARE_ORDER_CODE_TOCARGO_PERSONEL' | translate"
      >
        ></app-alert
      >
    </div>
  </div>
</div>
