export const CommonEnvironment = {
  name: 'development',
  production: false,
  app_version: 'v1.0.0.32-pre-dev',
  api_key: 'MbUoobH8kbSU1liJ9+PHq8aStx3/DmBxYGF74a8S/mI=',
  pratik_islem_api_url: 'https://dev.ecommerce.shopiroller.com',
  identity_api_url: 'https://dev.identity.shopiroller.com',
  storage_api_url: 'https://dev.storage.applyze.com',
  users_api_url: 'https://dev.app-user.applyze.com',
  storage_container_name: 'dev-shopiroller-ecommerce',
  file_cdn_base_url: 'https://dev.content.cdn.shopiroller.com',
  pratik_islem_base_url: 'https://pratiklink.tenant.shopiroller.com',
  pratik_islem_panel_base_url: 'dev.myshopiroller.com',
  sentry_config: {
    dsn: '',
    tracingOrigins: ['localhost']
  }
};
