import { createAction, props } from '@ngrx/store';
import { StoreActionsMessageTypesList } from '@pratik-islem/core';
import { SalesChannelTypes } from '../../models';

/******************* Load Shopilink Sale Channel Data  ***********************/
export const loadShopilinkSaleChannelData = createAction(
  '[SalesChannel] Load Shopilink Sale Channel Data',
  props<{ appId: string; expireCache: boolean }>()
);

export const loadShopilinkSaleChannelDataSuccess = createAction(
  '[SalesChannel] Load Shopilink Sale Channel Data Success',
  props<{ response: any }>()
);

export const loadShopilinkSaleChannelDataFailure = createAction(
  '[SalesChannel] Load Shopilink Sale Channel Data Failure',
  props<{ error: any }>()
);

/******************* change Activity Of Sale Channel  ***********************/
export const changeActivityOfSaleChannel = createAction(
  '[SalesChannel]  Change Activity Of Sale Channel',
  props<{
    appId: string;
    channelType: SalesChannelTypes;
    channelInfo: any;
  }>()
);

export const changeActivityOfSaleChannelSuccess = createAction(
  '[SalesChannel] Change Activity Of Sale Channel Success',
  props<{
    payload: {
      channelType: SalesChannelTypes;
      updatedChannelInfo: any;
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Success;
      };
    };
  }>()
);

export const changeActivityOfSaleChannelFailure = createAction(
  '[SalesChannel] Change Activity Of Sale Channel Failure',
  props<{
    payload: {
      channelType: SalesChannelTypes;
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Error;
      };
    };
  }>()
);

/******************* update Sale Channel  ***********************/
export const updateSaleChannel = createAction(
  '[SalesChannel] update Sale Channel',
  props<{
    appId: string;
    channelInfo: any;
    payload: any;
    channelType: SalesChannelTypes;
    updateType: 'UPDATE_LOGO_FAVICON' | 'REMOVING_FAVICON_LOGO';
  }>()
);

export const updateSaleChannelSuccess = createAction(
  '[SalesChannel] update Sale Channel Success',
  props<{
    payload: {
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Success;
      };
    };
  }>()
);

export const updateSaleChannelFailure = createAction(
  '[SalesChannel] update Sale Channel Failure',
  props<{
    payload: {
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Error;
      };
    };
  }>()
);
/******************* Load Easy Collection Channel Data  ***********************/
export const loadEasyCollectionSaleChannelData = createAction(
  '[SalesChannel] Load EasyCollection Channel Data',
  props<{ appId: string; expireCache: boolean }>()
);

export const loadEasyCollectionSaleChannelDataSuccess = createAction(
  '[SalesChannel] Load EasyCollection Channel Data Success',
  props<{ response: any }>()
);

export const loadEasyCollectionSaleChannelDataFailure = createAction(
  '[SalesChannel] Load EasyCollection Channel Data Failure',
  props<{ error: any }>()
);

/******************* Supported Sales Channel  ***********************/
export const updateSupportedSalesChannelListSuccess = createAction(
  '[SalesChannel] Update Supported Sales Channel List Success',
  props<{
    payload: {
      channelList: string[];
    };
  }>()
);

/******************* Load Web Setting Data  ***********************/
export const loadWebSettingData = createAction(
  '[SalesChannel] Load Web Setting Data ',
  props<{ appId: string }>()
);

export const loadWebSettingDataSuccess = createAction(
  '[SalesChannel] Load Web Setting Data Success',
  props<{ response: any }>()
);

export const loadWebSettingDataFailure = createAction(
  '[SalesChannel] Load Web Setting Data Failure',
  props<{ error: any }>()
);