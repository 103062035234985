import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private paypalConfigureClosedSource = new Subject<string>();
  public paypalConfigureClosedEvent$ =
    this.paypalConfigureClosedSource.asObservable();
  emitPaypalConfigureClosedEvent(): any {
    this.paypalConfigureClosedSource.next('');
  }
}
