import { createAction, props } from '@ngrx/store';

import { DashboardPageModel } from '../../data/models';

/******************* Load Dashboard Page Data  ***********************/
export const LoadDashboardPageData = createAction(
  '[Dashboard] Load Dashboard Page Data',
  props<{
    payload: {
      appId: string;
      appKey: string;
    };
  }>()
);

export const LoadDashboardPageDataSuccess = createAction(
  '[Dashboard] Load Dashboard Page Data Success',
  props<{ response: DashboardPageModel }>()
);

export const LoadDashboardPageDataFailure = createAction(
  '[Dashboard] Load Dashboard Page Data Failure',
  props<{ error: any }>()
);
