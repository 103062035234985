import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { END_POINTS_URL } from '@pratik-islem/shared/data';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import {
  IInsertSaleChannel,
  IUpdateSaleChannel,
  SaleChannelTypes
} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class SalesChannelService {
  public supportedSalesChannel$: Observable<string[]>;
  public supportedSalesChannelSubject: BehaviorSubject<string[]>;
  private readonly SALES_CHANNELS_BASE_URL = `${END_POINTS_URL.V2.SALES_CHANNELS_URL}`;

  constructor(private httpClient: HttpClient) {
    this.supportedSalesChannelSubject = new BehaviorSubject<string[]>([]);
    this.supportedSalesChannel$ =
      this.supportedSalesChannelSubject.asObservable();
  }

  public updateSupportedSalesChannel(salesChannels: string[]) {
    this.supportedSalesChannelSubject.next(salesChannels);
  }

  public getAllChannels(appId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/getall`;
    return this.httpClient.get(URL);
  }

  public getSingleChannel(appId: string, channelId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/getsingle`;
    const Options = {
      headers: new HttpHeaders({
        aliasKey: channelId
      })
    };
    return this.httpClient.get(URL, Options);
  }

  public getSupportedChannel(appId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/supportedChannels`;
    return this.httpClient.get(URL).pipe(
      map((result: any) => {
        const supportedSalesChannels = result.data as string[];
        this.updateSupportedSalesChannel(supportedSalesChannels);
        return supportedSalesChannels;
      })
    );
  }

  public getMobileChannels(appId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/getMobileChannels`;
    return this.httpClient.get(URL).pipe(map((result: any) => result.data));
  }

  public getChannelListByChannelType(
    appId: string,
    channelType: SaleChannelTypes
  ) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/getByChannelType?channelType=${channelType}`;
    return this.httpClient.get(URL).pipe(map((result: any) => result.data));
  }

  public changeActivityOfSaleChannel(appId: string, channelId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/changeActivity`;
    const headers = { aliasKey: channelId };
    return this.httpClient.put(URL, null, { headers });
  }

  public insertNewSaleChannel(appId: string, payload: IInsertSaleChannel) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/insert`;
    return this.httpClient.post(URL, payload);
  }

  public updateSaleChannel(
    appId: string,
    channelId: string,
    payload: IUpdateSaleChannel
  ) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/update`;
    const Options = {
      headers: new HttpHeaders({
        aliasKey: channelId
      })
    };
    return this.httpClient.patch(URL, payload, Options);
  }

  public deleteSaleChannel(appId: string, channelId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/delete`;
    const Options = {
      headers: new HttpHeaders({
        aliasKey: channelId
      })
    };
    return this.httpClient.delete(URL, Options);
  }
}
