<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{ 'ORDERS.PRODUCTS' | translate }}({{ orderInfo?.productList.length }})
      </h3>
    </div>
  </div>
  <div class="card-body" *ngIf="paginatedProductList.length > 0">
    <div class="table-responsive">
      <table class="table table-borderless mb-0">
        <tbody>
          <tr *ngFor="let product of paginatedProductList">
            <td class="align-middle w-50px pl-0 p-0">
              <div class="symbol symbol-50 symbol-light-success">
                <ng-container
                  *ngIf="
                    product.featuredImage.hasOwnProperty('t');
                    else orderSymbol
                  "
                >
                  <img [src]="product.featuredImage.t" />
                </ng-container>
                <ng-template #orderSymbol>
                  <div class="symbol-label"></div>
                </ng-template>
              </div>
            </td>
            <td class="align-middle">
              <div class="font-size-lg font-weight-bolder text-dark-75">
                {{ product.title }}
              </div>
              <div class="font-size-sm text-dark-50">
                {{ 'ORDERS.STOCK_CODE' | translate }}: #{{ product.stockCode }}
              </div>
            </td>
            <td class="text-muted align-middle text-right">
              <span class="text-dark-75"
                >{{
                  product.price
                    | currency : product.currency : '' : '.2-2' : defaultLocale
                }}
                {{ orderInfo?.currency }} x {{ product.quantity }}</span
              >
            </td>
            <td class="text-muted align-middle text-right">
              <span class="text-dark-75"
                >{{
                  product.price * product.quantity
                    | currency : product.currency : '' : '.2-2' : defaultLocale
                }}
                {{ orderInfo?.currency }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 text-center mt-4" *ngIf="showLoadMoreButton == true">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="paginateProducts()"
      >
        {{ 'SHARED.LOAD_MORE' | translate }}
      </button>
    </div>
  </div>
</div>
