export const Payment_Types = [
  {
    type: 'Online3DS',
    label: 'ONLINE_3DS',
  },
  {
    type: 'PayPal',
    label: 'PAYPAL',
  },
  {
    type: 'PayAtDoor',
    label: 'PAY_AT_DOOR',
  },
  {
    type: 'Transfer',
    label: 'TRANSFER',
  },
  {
    type: 'Stripe',
    label: 'STRIPE',
  },
  {
    type: 'Online',
    label: 'ONLINE',
  },
];
