export enum DIALOG_RESULT {
  'ACTION_HAPPENED' = 'ACTION_HAPPENED',
  'CANCEL' = 'CANCEL'
}

export type DIALOG_RESULT_TYPE = 'ACTION_HAPPENED' | 'CANCEL';

export interface DialogResultModel {
  result: any;
  resultType: DIALOG_RESULT_TYPE;
}
