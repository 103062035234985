<div class="timeline timeline-6" *ngFor="let log of logs">
  <p class="timeline-header">
    {{ log.date }}
  </p>
  <div
    class="timeline-item align-items-start"
    *ngFor="let record of log.records"
  >
    <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg">
      {{ record.time }}
    </div>
    <div class="timeline-badge">
      <fa-icon
        [icon]="faGenderless"
        setTimelineRecordColor
        [id]="record.action"
        size="2x"
      ></fa-icon>
    </div>
    <div
      class="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
    >
      {{ record.action }}
    </div>
  </div>
</div>
